import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import arLang from './entries/ar_SA';
import esLang from './entries/es_ES';
import ptLang from './entries/pt_PT';

const AppLocale = {
  en: enLang,
  ar: arLang,
  es: esLang,
  pt: ptLang,
};
addLocaleData( AppLocale.en.data );
addLocaleData( AppLocale.es.data );
addLocaleData( AppLocale.ar.data );
addLocaleData( AppLocale.pt.data );
export default AppLocale;
