import { combineReducers } from 'redux';
import lastRoundServicesReducer from './LastRoundServicesReducer';
import allRoundsServicesReducer from './AllRoundsServicesReducer';


const servicesRootReducer = combineReducers( {
  lastRoundServices: lastRoundServicesReducer,
  allRoundsServices: allRoundsServicesReducer,
  // Agrega aquí más reducers específicos del módulo si los tienes
} );

export default servicesRootReducer;
