import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-products.jpeg';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
// import CategoryService from 'services/Category';
import DashboardStudentService from 'services/DashboardStudent';
// import SessionInfoService from 'services/SessionInfo'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartModal from 'routes/App/components/User/Chart';
import Util from 'utils/Util';
import { Grid, Typography } from '@material-ui/core';

import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import FeatherIcon from 'components/custom/FeatherIcon';
import KotlerTabs from 'components/custom/KotlerTabs';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerTableCardGraphic from 'components/custom/KotlerTableCardGraphic';

class ProductCharts extends React.Component {
  state = {
    dataWorkGroup: [],
    activeTab: '',
    activeTabIndex: 0,
    chartModal: false,
    seriesTitleBundle1: [],
    seriesTitle1: [],
    seriesTitle2: [],
    seriesTitle3: [],
  };
  showLaptops = true;
  showTablets = true;
  showCellphones = true;

  async componentDidMount() {
    const { user } = this.props;
    const { activeTab } = this.state;
    let swActive = activeTab;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    if ( user.activeSession.distributionType === 'studio' ) {
      this.showLaptops = false;
      this.showTablets = false;
      this.showCellphones = true;
    }

    await DashboardStudentService.getStockValueAllWorkgroup(
      user.activeSession.id,
      { limit: -1 },
    ).then( ( response ) => {
      if ( response.ok ) {
        this.workgroups = response.data;

        if ( activeTab === '' ) {
          swActive = this.workgroups[0].id;
        }

        this.setState( { dataWorkGroup: response.data, activeTab: swActive } );
      }
    } );

    await this.loadHistoricalBundle( user.activeSession.id, swActive );
    await this.loadHistoricalProducts( user.activeSession.id, swActive );
  }

  async componentDidUpdate( nextProps ) {
    const { locale } = this.props;
    if ( nextProps.locale !== locale ) {
      const { dataWorkGroup } = this.state;
      const { user } = this.props;
      const { activeTabIndex } = this.state;
      am4core.disposeAllCharts();
      await this.loadHistoricalBundle(
        user.activeSession.id,
        dataWorkGroup[activeTabIndex].id,
      );
      await this.loadHistoricalProducts(
        user.activeSession.id,
        dataWorkGroup[activeTabIndex].id,
      );
    }
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  getDataSerie = ( categories, roundsCat = [], seriesTitle, param ) => {
    _.map( categories, ( item ) => {
      _.map( item.historical, ( round ) => {
        const exist = _.findIndex( roundsCat, { id: round.round.id } );
        if ( exist === -1 ) {
          const dataToPush = {
            attribute: round.round.shortName,
            id: round.round.id,
          };
          dataToPush[item.id] = round[param];
          roundsCat.push( dataToPush );
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][item.id] = round[param];
        }
      } );
      seriesTitle.push( item.name );
    } );
  };

  createCharSerie = (
    key,
    roundsCat,
    categories,
    param,
    param1,
    xAxesTitle,
    yAxesTitle,
  ) => {
    const { intl } = this.props;
    const roundsCatTraduce = roundsCat.map( item => ( {
      ...item,
      attribute: intl.formatMessage(
        { id: item.attribute.split( ' ' )[0] },
        { value: item.attribute.split( ' ' )[1] },
      ),
    } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          title: { text: intl.formatMessage( { id: xAxesTitle } ) || '' },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          title: { text: intl.formatMessage( { id: yAxesTitle } ) || '' },
        },
      ],
      series: _.map( categories, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  // const [activeTab, setActiveTab] = useState( '1' );

  toggleTab = async ( tab ) => {
    const { user } = this.props;
    const { activeTab } = this.state;
    am4core.disposeAllCharts();
    if ( activeTab !== tab ) {
      this.setState( { activeTab: tab } );
    }
    await this.loadHistoricalBundle( user.activeSession.id, tab );
    await this.loadHistoricalProducts( user.activeSession.id, tab );
  };

  toggleTab2 = async ( event, tab ) => {
    const { dataWorkGroup } = this.state;
    const { user } = this.props;
    const { activeTab } = this.state;
    am4core.disposeAllCharts();
    if ( activeTab !== tab ) {
      this.setState( { activeTabIndex: tab } );
    }
    await this.loadHistoricalBundle(
      user.activeSession.id,
      dataWorkGroup[tab].id,
    );

    await this.loadHistoricalProducts(
      user.activeSession.id,
      dataWorkGroup[tab].id,
    );
  };

  loadHistoricalBundle( userSessionID, activeWorkGroupID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardStudentService.getHistoricalBundle( userSessionID, {
      workgroup: activeWorkGroupID,
      limit: -1,
      'sortby[createdAt]': 'ASC',
    } ).then( ( response ) => {
      if ( response.ok ) {
        const allData = _.cloneDeep( response.data.data );
        const roundsBundle1 = [];
        const seriesTitleBundle1 = [];
        let tableBundle1 = [];
        let tableBundle2 = [];

        if ( this.showTablets ) {
          this.getDataSerie(
            allData,
            roundsBundle1,
            seriesTitleBundle1,
            'unitSales',
          );
          const result1 = this.createCharSerie(
            'bundleunitsale',
            roundsBundle1,
            allData,
            'unitSales',
            'lastsUnitSales',
            'Rounds',
            'Units',
          );
          this.configBundle1 = _.cloneDeep( result1.config );
          this.chartBundle1 = result1.chart;
          tableBundle1 = result1.table;

          this.getDataSerie(
            allData,
            roundsBundle1,
            seriesTitleBundle1,
            'revenues',
          );
          const result2 = this.createCharSerie(
            'bundlerevenuesale',
            roundsBundle1,
            allData,
            'revenues',
            'lastsRevenues',
            'Rounds',
            'Units',
          );
          this.configBundle2 = _.cloneDeep( result2.config );
          this.chartBundle2 = result2.chart;
          tableBundle2 = result2.table;
        }

        this.setState( {
          tableBundle1,
          seriesTitleBundle1,
          tableBundle2,
        } );
        toggleLoading( false );
      }
    } );
  }

  loadHistoricalProducts( userSessionID, activeWorkGroupID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardStudentService.getHistoricalProducts(
      userSessionID,
      activeWorkGroupID,
      { limit: -1, 'sortby[createdAt]': 'ASC' },
    ).then( ( response ) => {
      if ( response.ok ) {
        const allData = _.cloneDeep( response.data.data );
        const categories1 = _.filter( allData, { category: { label: 'CATEGORY_1' } } );
        const categories2 = _.filter( allData, { category: { label: 'CATEGORY_2' } } );
        const categories3 = _.filter( allData, { category: { label: 'CATEGORY_3' } } );
        const roundsCat1 = [];
        const roundsCat2 = [];
        const roundsCat3 = [];
        const seriesTitle1 = [];
        const seriesTitle2 = [];
        const seriesTitle3 = [];
        let table11 = [];
        let table1 = [];
        let table12 = [];
        let table13 = [];
        let table2 = [];
        let table21 = [];
        let table22 = [];
        let table23 = [];
        let table3 = [];
        let table31 = [];
        let table32 = [];
        let table33 = [];

        if ( this.showLaptops ) {
          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'unitSales' );
          const result1 = this.createCharSerie(
            'laptopsalesbybrandu',
            roundsCat1,
            categories1,
            'unitSales',
            'lastsUnitSales',
            'Rounds',
            'SalesU',
          );
          this.config1 = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          table1 = result1.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'revenues' );
          const result11 = this.createCharSerie(
            'laptopsalesbybrandrevenuew',
            roundsCat1,
            categories1,
            'revenues',
            'lastsRevenues',
            'Rounds',
            'SalesS',
          );
          this.config11 = _.cloneDeep( result11.config );
          this.chart11 = result11.chart;
          table11 = result11.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'profits' );
          const result12 = this.createCharSerie(
            'laptopsalesbybrandprofits',
            roundsCat1,
            categories1,
            'profits',
            'lastsProfits',
            'Rounds',
            'ProfitS',
          );
          this.config12 = _.cloneDeep( result12.config );
          this.chart12 = result12.chart;
          table12 = result12.table;

          this.getDataSerie(
            categories1,
            roundsCat1,
            seriesTitle1,
            'percentDirectSales',
          );
          const result13 = this.createCharSerie(
            'laptopsalesbybrandsale',
            roundsCat1,
            categories1,
            'percentDirectSales',
            'lastPercentDirectSales',
            'Rounds',
            'DirectSalesPercent',
          );
          this.config13 = _.cloneDeep( result13.config );
          this.chart13 = result13.chart;
          table13 = result13.table;
        }

        if ( this.showTablets ) {
          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'unitSales' );
          let result2 = this.createCharSerie(
            'tabletsalesbybrandu',
            roundsCat2,
            categories2,
            'unitSales',
            'lastsUnitSales',
            'Rounds',
            'SalesU',
          );
          this.config2 = _.cloneDeep( result2.config );
          this.chart2 = result2.chart;
          table2 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'revenues' );
          result2 = this.createCharSerie(
            'tabletsalesbybrandrevenue',
            roundsCat2,
            categories2,
            'revenues',
            'lastsRevenues',
            'Rounds',
            'SalesS',
          );
          this.config21 = _.cloneDeep( result2.config );
          this.chart21 = result2.chart;
          table21 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'profits' );
          result2 = this.createCharSerie(
            'tabletsalesbybrandprofits',
            roundsCat2,
            categories2,
            'profits',
            'lastsProfits',
            'Rounds',
            'ProfitS',
          );
          this.config22 = _.cloneDeep( result2.config );
          this.chart22 = result2.chart;
          table22 = result2.table;

          this.getDataSerie(
            categories2,
            roundsCat2,
            seriesTitle2,
            'percentDirectSales',
          );
          result2 = this.createCharSerie(
            'tabletsalesbybrandsale',
            roundsCat2,
            categories2,
            'percentDirectSales',
            'lastPercentDirectSales',
            'Rounds',
            'DirectSalesPercent',
          );
          this.config23 = _.cloneDeep( result2.config );
          this.chart23 = result2.chart;
          table23 = result2.table;
        }

        if ( this.showCellphones ) {
          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'unitSales' );
          let result3 = this.createCharSerie(
            'cellsalesbybrandu',
            roundsCat3,
            categories3,
            'unitSales',
            'lastsUnitSales',
            'Rounds',
            'SalesU',
          );
          this.config3 = _.cloneDeep( result3.config );
          this.chart3 = result3.chart;
          table3 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'revenues' );
          result3 = this.createCharSerie(
            'cellsalesbybrandrevenue',
            roundsCat3,
            categories3,
            'revenues',
            'lastsRevenues',
            'Rounds',
            'SalesS',
          );
          this.config31 = _.cloneDeep( result3.config );
          this.chart31 = result3.chart;
          table31 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'profits' );
          result3 = this.createCharSerie(
            'cellsalesbybrandprofits',
            roundsCat3,
            categories3,
            'profits',
            'lastsProfits',
            'Rounds',
            'ProfitS',
          );
          this.config32 = _.cloneDeep( result3.config );
          this.chart32 = result3.chart;
          table32 = result3.table;

          this.getDataSerie(
            categories3,
            roundsCat3,
            seriesTitle3,
            'percentDirectSales',
          );
          result3 = this.createCharSerie(
            'cellsalesbybrandsale',
            roundsCat3,
            categories3,
            'percentDirectSales',
            'lastPercentDirectSales',
            'Rounds',
            'DirectSalesPercent',
          );
          this.config33 = _.cloneDeep( result3.config );
          this.chart33 = result3.chart;
          table33 = result3.table;
        }

        this.setState( {
          table1,
          table2,
          table3,
          table11,
          table12,
          table13,
          table21,
          table22,
          table23,
          table33,
          table32,
          table31,
          seriesTitle1,
          seriesTitle2,
          seriesTitle3,
        } );
        toggleLoading( false );
      }
    } );
  }

  render() {
    const { activeTabIndex, dataWorkGroup } = this.state;

    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      table1,
      table2,
      table3,
      table11,
      table12,
      table13,
      table21,
      table22,
      table23,
      table33,
      table32,
      table31,
      seriesTitle1,
      seriesTitle2,
      seriesTitle3,
      tableBundle1,
      tableBundle2,
      seriesTitleBundle1,
    } = this.state;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-products' },
          ]}
        />
        <KotlerSectionHeader title="products" image={HeaderImage} />
        {/* <SectionInfoText title="info.product.HelpTextCharts" /> */}

        <Tabs
          value={activeTabIndex !== -1 ? activeTabIndex : 0}
          onChange={this.toggleTab2}
          className="kotler-tab-pills my-3"
          variant="scrollable"
        >
          {_.map( dataWorkGroup, item => (
            <Tab label={item.name} className="tab" key={item.id} />
          ) )}
        </Tabs>

        <KotlerTabs
          titles={[
            ...( this.showLaptops
              ? [
                {
                  title: 'laptops',
                  icon: <FeatherIcon size="20px" icon="monitor" />,
                },
              ]
              : [] ),
            ...( this.showTablets
              ? [
                {
                  title: 'tablets',
                  icon: <FeatherIcon size="20px" icon="tablet" />,
                },
              ]
              : [] ),
            ...( this.showCellphones
              ? [
                {
                  title: 'cellPhones',
                  icon: (
                    <FeatherIcon
                      size="20px"
                      color="primary.main"
                      icon="smartphone"
                    />
                  ),
                },
              ]
              : [] ),

            ...( this.showTablets
              ? [
                {
                  title: 'services and bundles',
                  icon: <FeatherIcon size="20px" icon="layers" />,
                },
              ]
              : [] ),
          ]}
          pages={[
            ...( this.showLaptops
              ? [
                <Grid container style={{ rowGap: '20px' }}>
                  <Grid item xs={12}>
                    <CardBox
                      heading="LaptopSalesU"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config1,
                        'LaptopSalesU',
                        seriesTitle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="laptopsalesbybrandu"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table1, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="LaptopSalesRevenue"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config11,
                        'LaptopSalesRevenue',
                        seriesTitle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="laptopsalesbybrandrevenuew"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table11, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="LaptopSalesProfits"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config12,
                        'LaptopProfits',
                        seriesTitle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="laptopsalesbybrandprofits"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.profits" />,
                          <IntlMessages id="market.profits.previous.month" />,
                        ]}
                        cells={_.map( table12, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12}>
                    <CardBox
                      heading="LaptopsDirectSales"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config13,
                        'LaptopsDirectSales',
                        seriesTitle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="laptopsalesbybrandsale"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table13, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                </Grid>,
              ]
              : [] ),

            ...( this.showTablets
              ? [
                <Grid container style={{ rowGap: '20px' }}>
                  <Grid item xs={12}>
                    <CardBox
                      heading="TabletSalesU"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal(
                        this.config2,
                        'TabletSalesU',
                        seriesTitle2,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="tabletsalesbybrandu"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table2, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="TabletSalesRevenue"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config21,
                        'TabletSalesRevenue',
                        seriesTitle2,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="tabletsalesbybrandrevenue"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table21, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="TabletSalesProfits"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config22,
                        'TabletSalesProfits',
                        seriesTitle2,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="tabletsalesbybrandprofits"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.profits" />,
                          <IntlMessages id="market.profits.previous.month" />,
                        ]}
                        cells={_.map( table22, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12}>
                    <CardBox
                      heading="TabletDirectSales"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.config22,
                        'TabletDirectSales',
                        seriesTitle2,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="tabletsalesbybrandsale"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table23, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                </Grid>,
              ]
              : [] ),

            ...( this.showCellphones
              ? [
                <Grid container style={{ rowGap: '20px' }}>
                  <Grid item xs={12}>
                    <CardBox
                      heading="CellPhoneSalesU"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal(
                        this.config3,
                        'CellPhoneSalesU',
                        seriesTitle3,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="cellsalesbybrandu"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table3, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="CellPhoneSalesRevenue"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal(
                        this.config31,
                        'CellPhoneSalesRevenue',
                        seriesTitle3,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="cellsalesbybrandrevenue"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table31, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardBox
                      heading="CellphoneSalesProfits"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal(
                        this.config32,
                        'CellphoneSalesProfits',
                        seriesTitle3,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="cellsalesbybrandprofits"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.profits" />,
                          <IntlMessages id="market.profits.previous.month" />,
                        ]}
                        cells={_.map( table32, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12}>
                    <CardBox
                      heading="CellPhoneDirectSales"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal(
                        this.config33,
                        'CellPhoneDirectSales',
                        seriesTitle3,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="cellsalesbybrandsale"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( table33, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                      <FooterInfoCardGraphic />
                    </CardBox>
                  </Grid>
                </Grid>,
              ]
              : [] ),
            ...( this.showTablets
              ? [
                <Grid container style={{ rowGap: '20px' }}>
                  <Grid item xs={12}>
                    <CardBox
                      heading="BundlesU"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.configBundle1,
                        'BundlesU',
                        seriesTitleBundle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="bundleunitsale"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( tableBundle1, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                    </CardBox>
                  </Grid>
                  <Grid item xs={12}>
                    <CardBox
                      heading="BundlesRevenue"
                      styleName="px-2  h-100"
                      onClick={() => this.toggleChartModal(
                        this.configBundle2,
                        'BundlesRevenue',
                        seriesTitleBundle1,
                      )
                      }
                    >
                      <div
                        className="jr-link mt-3"
                        id="bundlerevenuesale"
                        style={{ width: '100%', height: '250px' }}
                      />
                      <KotlerTableCardGraphic
                        originalPadding
                        titles={[
                          <IntlMessages id="market.last.month.sales" />,
                          <IntlMessages id="market.sales.previous.month" />,
                        ]}
                        cells={_.map( tableBundle2, item => [
                          item.name,
                          Util.formatNumber( item.unitSales ),
                          item.name,
                          Util.formatNumber( item.lastsUnitSales ),
                        ] )}
                      />
                    </CardBox>
                  </Grid>
                </Grid>,
              ]
              : [] ),
          ]}
        />

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />
      </>
    );
  }
}

const FooterInfoCardGraphic = ( { title } ) => (
  <Typography
    style={{
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#999FA3',
      marginTop: '16px',
    }}
  >
    {title ? (
      <IntlMessages id={title} />
    ) : (
      <IntlMessages id="info.product.HelpText" />
    )}
  </Typography>
);
const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( ProductCharts ) );
