import bundlesRootSaga from 'modules/Bundles/redux/sagas/rootSaga';
import { changelogsRootSaga } from 'modules/changelog';
import customerRootSaga from 'modules/Customers/redux/sagas/rootSaga';
import { liveAdsRootSaga } from 'modules/LiveAds';
import { ManualAiSaga } from 'modules/ManualAi';
import marketRootSaga from 'modules/Market/redux/sagas/rootSaga';
import productsRootSaga from 'modules/Products/redux/sagas/rootSaga';
import servicesRootSaga from 'modules/Services/redux/sagas/rootSaga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all( [
    customerRootSaga(),
    productsRootSaga(),
    servicesRootSaga(),
    bundlesRootSaga(),
    marketRootSaga(),
    marketRootSaga(),
    liveAdsRootSaga(),
    ManualAiSaga(),
    changelogsRootSaga(),
  ] );
}
