import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import async from 'async';
import CardBox from 'components/custom_v2/CardBox';

import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-research.jpeg';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import TableCustom from 'components/custom_v2/Table/Custom/';
import SettingsActions from 'store/reducers/Settings';
import MarketReportService from 'services/MarketReport';
import Util from 'utils/Util';
import HoverTitle from 'components/custom_v2/HoverTitle';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import { Link } from 'react-router-dom';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import { STATUS } from 'modules/sessions';

class MarketReport extends React.Component {
  state = {
    purchasedReports: [],
    availableReports: [],
    confirmPurchaseModal: false,
    report: {},
  };
  mkmode = {};

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields };
    }
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;

    this.currentRound = user.activeSession && user.activeSession.currentRound
      ? user.activeSession.currentRound.id
      : null;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel(
      {
        availableReports: this.getAvailableReports,
        purchasedReports: this.getPurchasedReports,
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );
        this.setState( results );
      },
    );
  };

  getAvailableReports = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id };
    MarketReportService.getAvailableReports( this.activeSession.id, params ).then(
      ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        if ( !this.mkmode.information.research.mk004 ) {
          cb( null, _.filter( response.data.data, o => o.report !== 'MK004' ) );
        } else {
          cb( null, response.data.data );
        }
      },
    );
  };

  getPurchasedReports = ( cb ) => {
    const params = {
      workgroup: this.license.workGroup.id,
      limit: -1,
      'sortby[createdAt]': 'DESC',
    };
    MarketReportService.getPurchasedReports( this.activeSession.id, params ).then(
      ( response ) => {
        if ( !response.ok ) return cb( true );
        if ( !this.mkmode.information.research.mk004 ) {
          cb(
            null,
            _.filter(
              response.data.data,
              o => o.availableReport.report !== 'MK004',
            ),
          );
        } else {
          cb( null, response.data.data );
        }
      },
    );
  };

  handleSetReport = ( report ) => {
    this.setState( { report } );
    const { toggleAlert } = this.props;
    const { purchasedReports } = this.state;

    const indexPending = _.findIndex( purchasedReports, {
      availableReport: { id: report.id },
      status: 'PENDING',
    } );

    const indexPurchased = _.findIndex( purchasedReports, {
      availableReport: { id: report.id },
      boughtRound: { id: this.currentRound },
      status: 'PURCHASED',
    } );

    if ( indexPending !== -1 || indexPurchased !== -1 ) {
      toggleAlert( 'selectAvailableReport', 'danger', true );
    } else {
      this.setState( { confirmPurchaseModal: true } );
    }
  };

  moveToAvailable = ( report ) => {
    const { purchasedReports } = this.state;
    const newReports = [...purchasedReports];
    const index = _.findIndex( purchasedReports, {
      availableReport: { id: report.availableReport.id },
      status: 'PENDING',
    } );
    if ( index !== -1 ) {
      newReports.splice( index, 1 );
      this.setState( { purchasedReports: newReports } );
    }
  };

  toggleConfirmPurchaseModal = () => {
    const { confirmPurchaseModal } = this.state;

    this.setState( { report: {} } );
    this.setState( { confirmPurchaseModal: !confirmPurchaseModal } );
  };

  submitPurchase = () => {
    const { toggleAlert, toggleLoading, intl } = this.props;
    const { purchasedReports } = this.state;
    const { report } = this.state;

    const newReports = [...purchasedReports];
    const purchased = {
      boughtRound: {
        name: _.get( this.activeSession, 'currentRound.name' ),
        startDateInMonth: _.get(
          this.activeSession,
          'currentRound.startDateInMonth',
        ),
      },
      availableReport: report,
      status: 'PENDING',
    };
    const indexPending = _.findIndex( purchasedReports, {
      availableReport: { id: report.id },
      status: 'PENDING',
    } );

    const indexPurchased = _.findIndex( purchasedReports, {
      availableReport: { id: report.id },
      boughtRound: { id: this.currentRound },
      status: 'PURCHASED',
    } );

    if ( indexPending === -1 && indexPurchased === -1 ) {
      newReports.push( purchased );
      this.setState( { purchasedReports: newReports } );
    }

    const reports = _.chain( newReports )
      .filter( { status: 'PENDING' } )
      .map( 'availableReport.id' )
      .value();
    if ( !reports.length ) {
      return toggleAlert( 'selectAvailableReport', 'danger', true );
    }

    const data = {
      workgroup: this.license.workGroup.id,
      availableReports: reports,
    };
    toggleLoading( true );
    MarketReportService.sendPurchaseReports( this.activeSession.id, data ).then(
      ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          const messageErr = response.errors
            === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
            ? intl.formatMessage( {
              id:
                    'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
            } )
            : response.errors;
          return toggleAlert( messageErr );
        }
        toggleAlert( 'dataSaved', 'info' );
        this.setState( { confirmPurchaseModal: false } );
        this.getData();
      },
    );
  };

  getStatus = ( status ) => {
    switch ( status ) {
    case 'PURCHASED':
      return <i className="fa fa-money-bill font-size-20 text-info" />;
    case 'READY':
      return <i className="fa fa-check font-size-20 text-primary" />;
    case 'PENDING':
      return <i className="fa fa-clock font-size-20 text-danger" />;
    default:
      return '';
    }
  };

  render() {
    const {
      availableReports,
      purchasedReports,
      confirmPurchaseModal,
    } = this.state;
    const { user } = this.props;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-research' },
          ]}
        />
        <KotlerSectionHeader title="research" image={HeaderImage} />
        <SectionInfoText title="help.info.research.snackbar" />

        <Grid container style={{ rowGap: '20px' }}>
          <Grid item xs={12} lg={5}>
            <CardBox
              styleName="px-2 h-100"
              header={
                <KotlerCardHeader title="availableReports" minHeight="0px" />
              }
            >
              <Table className="kotler-table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IntlMessages id="reports" />
                    </TableCell>
                    <TableCell>
                      <IntlMessages id="name" />
                    </TableCell>
                    <TableCell>
                      <IntlMessages id="cost" />
                    </TableCell>
                    {/* <TableCell >
                      <HoverTitle id="deliveryTime.hover">
                        <span>
                          <IntlMessages id="deliveryTime" />
                        </span>
                      </HoverTitle>
                    </TableCell> */}
                    {user
                      && user.activeSession
                      && user.activeSession === STATUS.READY && (
                      <TableCell align="center" colSpan={2}>
                        <HoverTitle
                          id="Press to hire report."
                          placement="left"
                        >
                          <span>
                            <IntlMessages id="pressArrowText" />
                          </span>
                        </HoverTitle>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map( availableReports, item => (
                    <TableRow key={item.id}>
                      <TableCell>{item.report}</TableCell>
                      <TableCell>
                        <IntlMessages id={item.report} />
                      </TableCell>
                      <TableCell>
                        {Util.formatCurrencyNoDecimals( item.cost || 0 )}
                      </TableCell>
                      {/* <TableCell>
                        <HoverTitle id="deliveryTime.hover">
                          <span>{`${item.deliveryTime || ''}`}</span>
                        </HoverTitle>
                      </TableCell> */}
                      {user
                        && user.activeSession
                        && user.activeSession.status === STATUS.READY && (
                        <TableCell colSpan={2} align="center">
                          <HoverTitle
                            id="Press to hire report."
                            placement="left"
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              className="jr-btn"
                              onClick={() => this.handleSetReport( item )}
                            >
                              <i className="fa fa-arrow-right" />
                            </Button>
                          </HoverTitle>
                        </TableCell>
                      )}
                    </TableRow>
                  ) )}
                </TableBody>
              </Table>
            </CardBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <CardBox
              styleName="px-2 h-100"
              header={
                <KotlerCardHeader title="purchasedReports" minHeight="0px" />
              }
            >
              <TableCustom
                className="w-100"
                data={purchasedReports}
                showPaginationTop={false}
                defaultSorted={[
                  {
                    id: 'boughtRound.name',
                    desc: true,
                  },
                ]}
                columns={[
                  {
                    Header: <IntlMessages id="month" />,
                    accessor: 'boughtRound.name',
                    Cell: ( { value, original } ) => `${value} ${_.get(
                      original,
                      'boughtRound.startDateInMonth',
                      '',
                    )}`,
                  },
                  {
                    Header: <IntlMessages id="reports" />,
                    accessor: 'availableReport.report',
                  },
                  {
                    Header: <IntlMessages id="name" />,
                    accessor: 'availableReport.name',
                    minWidth: 250,
                    width: 400,
                    maxWidth: 800,
                    Cell: ( { original } ) => ( original.status === 'READY' ? (
                      original.availableReport.report === 'MK003' ? (
                        <Link
                          rel="noopener noreferrer"
                          className="text-blue"
                          to={`positioning/${original.id}`}
                        >
                          <IntlMessages
                            id={original.availableReport.report}
                          />
                        </Link>
                      ) : (
                        <a
                          rel="noopener noreferrer"
                          className="text-blue"
                          target={
                            original.availableReport.report === 'MK003'
                              ? ''
                              : '_blank'
                          }
                          href={
                            original.availableReport.report === 'MK003'
                              ? `positioning/${original.id}`
                              : original.link
                          }
                        >
                          <IntlMessages
                            id={original.availableReport.report}
                          />
                        </a>
                      )
                    ) : (
                      <IntlMessages id={original.availableReport.report} />
                    ) ),
                  },
                  {
                    maxWidth: 70,
                    Header: <IntlMessages id="status" />,
                    accessor: 'status',
                    Cell: ( { value } ) => this.getStatus( value ),
                  },
                  /*
                {
                  maxWidth: 70,
                  sortable: false,
                  accessor: 'id',
                  Cell: props => (
                    props.original.status === 'PENDING'
                      ? (
                        <Button
                          variant="contained"
                          className="jr-btn bg-danger text-white"
                          onClick={() => this.moveToAvailable( props.original )}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      ) : null
                  ),
                },
                */
                ]}
              />
            </CardBox>
          </Grid>
        </Grid>

        <ConfirmationDialog
          open={confirmPurchaseModal}
          title="confirmReportPurchase"
          confirmBtnText="ok"
          closeBtnText="cancelBtn"
          showIcon
          type="save"
          onSubmit={() => {
            this.submitPurchase();
          }}
          onClose={this.toggleConfirmPurchaseModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( MarketReport ) );
