import { Card, CardHeader } from '@material-ui/core';
// import Avatar from "@material-ui/core/es/Avatar/Avatar";
import { InfoOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import IntlMessages from 'utils/IntlMessages';
import './styles.css';

export default ( props ) => {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,

    centerPadding: '0',
    ...props,
  };
  const slideRef = useRef( null );

  return (
    <Slider
      className="principal-carrousel"
      ref={slideRef}
      {...settings}
      arrows={false}
    >
      {[...props.data].map( ( item, index ) => (
        <ItemCarrousel
          item={item}
          key={index}
          onNext={slideRef.current ? slideRef.current.slickNext : () => {}}
        />
      ) )}
    </Slider>
  );
};

const ItemCarrousel = ( { item, onNext } ) => (
  <div className="px-1  " style={{ overflowY: 'auto' }}>
    <Card
      elevation={3}
      style={{
        alignItems: 'baseline',
        background: 'rgba(255, 255, 255, 0.87)',
        display: 'flex',
      }}
      onClick={() => onNext()}
      className="border card-phone-notification  container-fluid p-2"
    >
      <CardHeader
        style={{ padding: 0, margin: 0 }}
        subheaderTypographyProps={{
          color: 'textPrimary',
          padding: 0,
          margin: 0,
        }}
        /* avatar={
          <Avatar className="bg-primary">
            <Accessibility />
          </Avatar>
        } */
        /*       action={
          <IconButton aria-label="settings">
            hola
          </IconButton>
        } */
        title={(
          <span
            style={{
              cursor: 'pointer',
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 600,
              textAlign: 'left',
              fontSize: 12,
              marginTop: 4,
              marginBottom: '6px',
              lineHeight: '18px',
              display: 'flex',
              wordBreak: 'break-word',
            }}
          >
            <div />
            <div
              style={{
                marginTop: 3,
                marginLeft: '3px',
              }}
            >
              <InfoOutlined />
              {' '}
              {item.translate ? <IntlMessages id={item.title} /> : item.title}
              {' '}
            </div>
          </span>
        )}
        subheader={(
          <p
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 400,
              margin: 0,
              textAlign: 'justify',
              fontSize: 10,
              lineHeight: '15px',
            }}
          >
            {item.translate ? (
              <IntlMessages id={item.content} />
            ) : (
              item.content
            )}
          </p>
        )}
      />
    </Card>
  </div>
);
