import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';

const formatDataToTable = ( data ) => {
  if ( !data ) return {};

  const dataFormated = [];
  const total = { price: 0, totalSales: 0, unitSales: 0 };
  // eslint-disable-next-line
  data.map(({ workgroupData }) => workgroupData.map( ( { name: group, services } ) => services.map( ( { name, id: key, price, totalSales, unitSales ,active} ) => {
    if ( !active ) return name;
    total.price += price;
    total.unitSales += unitSales;
    total.totalSales += totalSales;

    return dataFormated.push( {
      key,
      name,
      group,
      price: Util.formatCurrency( price ),
      totalSales: Util.formatCurrency( totalSales ),
      unitSales: Util.formatNumber( unitSales ),
    } );
  } ) ) );
  return {
    data: dataFormated,
    total: {
      price: Util.formatCurrency( total.price ),
      totalSales: Util.formatCurrency( total.totalSales ),
      unitSales: Util.formatNumber( total.unitSales ),
    },
  };
};

const TablesLastRoundService = ( {
  data,
  //   loading,
  //   error,
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  const [total, setTotal] = useState( [] );
  useEffect( () => {
    const formatData = formatDataToTable( data );
    setDataToTable( formatData.data );
    setTotal( formatData.total );
  }, [data] );

  return (
    <CardBox
      styleName="px-2 h-100 mt-3"
      header={
        <KotlerCardHeader minHeight="0px" title="market.services_information" />
      }
    >
      <Table className="kotler-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                <IntlMessages id="group" />
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                <IntlMessages id="service" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="salesU" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="salesP" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="price" />
              </strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            dataToTable,
            ( { key, name, price, totalSales, unitSales, group } ) => (
              <TableRow key={key}>
                <TableCell>{group}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell align="right">{unitSales}</TableCell>
                <TableCell align="right">{totalSales}</TableCell>
                <TableCell align="right">{price}</TableCell>
              </TableRow>
            ),
          )}
          {!!dataToTable && !!dataToTable.length && !!total && (
            <TableRow>
              <TableCell>
                <strong>
                  <IntlMessages id="total" />
                </strong>
              </TableCell>
              <TableCell />
              <TableCell align="right">{total.unitSales}</TableCell>
              <TableCell align="right">{total.totalSales}</TableCell>
              <TableCell />
            </TableRow>
          )}
          {( !dataToTable || !dataToTable.length ) && (
            <TableRow>
              <TableCell colSpan={5}>
                <IntlMessages id="NoDataFound" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CardBox>
  );
};

const mapStateToProps = ( { services: { lastRoundServices: { data, loading, error } } } ) => ( {
  data,
  loading,
  error,
} );

export default connect( mapStateToProps )( TablesLastRoundService );
