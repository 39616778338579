import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';
import { connect } from 'react-redux';
import _ from 'lodash';
import UserService from 'services/User';
import async from 'async';

import UserActions from 'store/reducers/User';
import {
  Hidden,
  Typography,
} from '@material-ui/core'; /*
import LanguageSelectionCollapse from 'components/layouts/LanguageSelectionCollapse'; */
import FeatherIcon from 'components/custom/FeatherIcon';
import { AchievementActions } from 'modules/Notifications/Redux/achievement';

class UserInfoPopup extends React.Component {
  setReturnToken = ( data, cb ) => {
    const { user } = this.props;
    UserService.setReturnTokenCloseSpy(
      user.activeSession.id,
      { returnToken: '' },
      user.activeSession.returnToken,
    ).then( ( response ) => {
      if ( response.errors ) return cb( response.errors );
      return cb( null, { ...data } );
    } );
  };

  closeSpySession = () => {
    const { toggleAlert, setSpyMode, user } = this.props;
    // return;
    const dataToSend = {};
    dataToSend.access_token = user.activeSession.returnToken;

    async.waterfall(
      [this.setReturnToken.bind( this, dataToSend )],
      ( error, results ) => {
        if ( error ) {
          if ( error === 'invalid_grant' ) {
            return toggleAlert( 'wrongUserPass', 'danger', true );
          }
          return toggleAlert( error );
        }
        setSpyMode( false );
      },
    );
  };
  hasPermissions( role, license ) {
    const { user } = this.props;
    return (
      user.roles
      && user.roles.indexOf( role ) !== -1
      && user.licenses
      && _.find( user.licenses, { owner: license } )
    );
  }

  render() {
    const { user, toggle } = this.props;

    return (
      <div>
        <Hidden smUp>
          <div className="kotler-drop-down-menu-header">
            <div className="user-detail ml-2 p-2">
              <Typography className="text-capitalize" variant="body1">
                {user.name}
                {' '}
                {user.lastName}
              </Typography>
              {this.hasPermissions( 'ROLE_ADMIN', 'adm' ) ? (
                <Typography className="ml-2" variant="body2">
                  <IntlMessages id="adminRole" />
                </Typography>
              ) : null}
              {this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' ) ? (
                <Typography className="ml-2" variant="body2">
                  <IntlMessages id="supervisorRole" />
                </Typography>
              ) : null}
              {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ? (
                <Typography className="ml-2" variant="body2">
                  <IntlMessages id="userRole" />
                </Typography>
              ) : null}
            </div>
          </div>
        </Hidden>
        {/* <LanguageSelectionCollapse /> */}
        <Link
          to="/profile-settings"
          className="text-decoration-none"
          onClick={toggle}
        >
          <span className="jr-link dropdown-item text-muted">
            <FeatherIcon icon="user" className="kotler-icon" />
            <IntlMessages id="profile" />
          </span>
        </Link>
        {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ? (
          <Link
            to="/group-settings"
            className="text-decoration-none"
            onClick={toggle}
          >
            <span className="jr-link dropdown-item text-muted">
              <FeatherIcon icon="users" className="kotler-icon" />
              <IntlMessages id="profile-group" />
            </span>
          </Link>
        ) : null}

        <span
          className="jr-link dropdown-item dropdown-item-danger text-muted"
          onClick={async () => {
            window.location.replace( '/login' );
            await this.closeSpySession();
            this.props.resetAchievements();
            this.props.logoutUser();
          }}
        >
          <FeatherIcon icon="logOut" className="kotler-icon" />
          <IntlMessages id="popup.logout" />
        </span>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  logoutUser: UserActions.logoutUser,
  resetAchievements: AchievementActions.reset,
  setSpyMode: UserActions.setSpyMode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( UserInfoPopup );
