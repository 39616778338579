import { takeEvery, put, call } from 'redux-saga/effects';

import { getAllRoundServices } from 'modules/Services/ServicesService';
import {
  FETCH_ALL_ROUNDS_SERVICES_REQUEST,
  fetchAllRoundsServicesFailure,
  fetchAllRoundsServicesSuccess,
} from '../actions/AllRoundsServicesActions';

function* fetchAllRoundsServicesSaga( action ) {
  const { sessionId, params = { limit: -1 } } = action.payload;
  try {
    const allRoundsServicesData = yield call(
      getAllRoundServices,
      sessionId,
      params,
    );
    yield put( fetchAllRoundsServicesSuccess( allRoundsServicesData ) );
  } catch ( error ) {
    yield put( fetchAllRoundsServicesFailure( error ) );
  }
}

export default function* allRoundsServicesRootSaga() {
  yield takeEvery(
    FETCH_ALL_ROUNDS_SERVICES_REQUEST,
    fetchAllRoundsServicesSaga,
  );
}
