import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _, { find } from 'lodash';
import moment from 'moment-timezone';
// import momentz from 'moment-timezone';
import {
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';

import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';

import CardBox from 'components/custom_v2/CardBox';
import HeaderImage from 'assets/images/kotler/sectionHeaders/new-session.jpeg';
import {
  TextField,
  RadioButtons,
  Select,
  DatePicker,
  Checkbox,
  TimePicker,
  Switch,
} from 'components/custom/FormElements';
import {
  composeValidators,
  equalTo,
  greaterThan,
  lessThanValue,
  required,
  greaterOrEqualValue,
  passwordZulipValidation,
  lessThan,
} from 'config/InputErrors';

import UserService from 'services/User';
import UserLicenseService from 'services/UserLicense';
import SessionService from 'services/Session';
import GlobalVariableService from 'services/GlobalVariable';
import utilTimeZone from 'utils/TimeZone';

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Row,
  Col,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeadConfig from 'components/custom/HeadConfig';
import { Link, Prompt } from 'react-router-dom';
import WorkingDaysCalendarV2 from '../../../../components/Professor/Session/WorkingDaysCalendarV2';

const closingTimeDate = new Date( Date.UTC( 2000, 0, 1, 7 ) );

class newSession extends React.Component {
  activeClosingTime = false;
  workingDays = [];
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = true;
  maxRoundsExceed = false;
  totalRounds = 0;
  activeCertif = true;
  activeForum = false;
  activeStrategy = false;
  messageStatusSession = '';
  marketingBudget = 0;
  password = '';
  forumAvailability = false;
  userTestRoundInformated = false;
  state = {
    workingDays: [],
    maxGroups: 0,
    maxStudents: 0,
    studentsPerGroup: 0,
    totalRound: 0,
    workingDaysModal: false,
    open: false,
    sunday: this.sunday,
    data: { sunday: this.sunday },
    anchorEl: null,
    registrationCode: null,
    popoverItems: [],
    listOfSession: [],
    sessionsData: [],
    previewVisible: false,
    cloneSessionId: null,
    activeCertif: 1,
    activeStrategy: 0,
    marketingBudget: 0,
    closingType: 'CLOSING_TIME',
    activeStep: 0,
    marketMaturity: 'growing',
    simulationSpeed: 'normal',
    roundsClosePerDay: '1',
    forumAvailability: false,
    password: '',
    passwordConfirmation: '',
    activeForum: 0,
    activeTestRound: 0,
    numberOfTestRound: null,
    openConfirmationTestRoundModal: false,
    steps: [
      {
        id: 1,
        label: 'NewSessionStep1',
      },
      {
        id: 2,
        label: 'NewSessionStep2',
      },
    ],
  };
  componentDidMount() {
    const { user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean( anchorEl );
    this.setState( { open } );

    try {
      this.entityId = user.activeSession.entity.id;
    } catch ( err ) {
      this.entityId = user.entity[0].id;
    }

    this.getData();
    this.setData();
  }

  setData = () => {
    const popoverItems = [];
    popoverItems.push( {
      id: 'cloneBtnID',
      header: 'Clone Session',
      content: 'Choose a Session and Clone it',
    } );
    popoverItems.push( {
      id: 'nameFieldID',
      header: 'What is Name?',
      content:
        'The name session is the identifier of the one session in the MK21. It must be unique',
    } );

    popoverItems.push( {
      id: 'studentsFieldID',
      header: 'Total Students...',
      content: 'The total students by Session',
    } );
    popoverItems.push( {
      id: 'timezoneFieldID',
      header: 'Time Zone...',
      content: 'Select the Time Zone for the Session',
    } );

    popoverItems.push( {
      id: 'studentsPerGroupFieldID',
      header: 'What is stutendes per group?',
      content: 'This is a calculate fields  maxStudents / maxGroup per session',
    } );
    popoverItems.push( {
      id: 'endDateFieldID',
      header: 'Date Last Round',
      content: 'The Date Last Round of the Session',
    } );

    popoverItems.push( {
      id: 'startDateFieldID',
      header: 'Date of the First Round',
      content: 'Date of the First Round...',
    } );

    popoverItems.push( {
      id: 'sundayFieldID',
      header: 'Sunday',
      content: 'Exclude Sunday',
    } );

    popoverItems.push( {
      id: 'saturdayFieldID',
      header: 'Saturday...',
      content: 'Exclude Saturday',
    } );

    popoverItems.push( {
      id: 'workingDaysFieldID',
      header: 'Working days',
      content: 'Excludes Holidays or another days...',
    } );
    this.setState( { popoverItems: [] } );
  };
  async getData() {
    const { toggleLoading, user } = this.props;

    toggleLoading( true );
    await GlobalVariableService.getMaxGroups().then( ( response ) => {
      if ( response.ok ) {
        const maxGroups = response.data.value;
        this.setState( { maxGroups } );
      }
    } );

    await GlobalVariableService.getMaxStudents().then( ( response ) => {
      if ( response.ok ) {
        const maxStudents = response.data.value;
        this.setState( { maxStudents } );
      }
    } );

    await GlobalVariableService.getMarketingBudget().then( ( response ) => {
      if ( response.ok ) {
        const marketingBudget = response.data.value;
        this.marketingBudget = marketingBudget;
        this.setState( { marketingBudget } );
      }
    } );
    const filters = { limit: -1 };
    /* filters['filters[criteria]'] = "sergio"; if we want to filter  */
    await UserLicenseService.getProfLicensesByEntity(
      this.entityId,
      filters,
    ).then( ( response ) => {
      if ( response.ok ) {
        // eslint-disable-next-line max-len
        const professors = _.orderBy(
          _.map(
            _.filter( response.data.data, { user: { enabled: true } } ),
            item => ( {
              id: item.id,
              userID: item.user.id,
              name: item.user && `${item.user.name} ${item.user.lastName}`,
            } ),
          ),
          ['name'],
          ['asc'],
        );

        const findCurrentUser = find( professors, { userID: user.id } );
        this.setState( prev => ( {
          professors,
          supervisor:
            !prev.supervisorDirty && findCurrentUser ? findCurrentUser.id : null,
        } ) );
      }
    } );
    await SessionService.checkForumAvailability().then( ( response ) => {
      if ( response.ok ) {
        const forumAvailability = _.get( response.data, 'exist' );
        this.forumAvailability = forumAvailability;
        this.setState( { forumAvailability } );
      }
    } );
    const timeZones = _.map( utilTimeZone.getTimeZones(), item => ( {
      id: item,
      name: item,
    } ) );
    this.setState( { timeZones } );
    toggleLoading( false );
  }

  getWorkingDays = ( callbackFunc ) => {
    const { user } = this.props;
    const { workingDays } = this.state;

    if ( user.activeSession ) {
      this.setState( { workingDays }, callbackFunc );
    }
  };

  toConfirm = ( data ) => {
    const { workingDays } = this.state;

    let newData = [];

    if ( data.event === 'remove' ) {
      _.forEach( workingDays, ( obj ) => {
        if ( obj.start !== data.data.start ) {
          newData.push( obj );
        }
      } );
      this.setState( { workingDays: newData } );
    }

    if ( data.event === 'add' ) {
      newData = _.concat( workingDays, data.data );
      this.setState( { workingDays: newData } );
    }

    this.workingDays = [];
    _.forEach( newData, ( obj ) => {
      this.workingDays.push( obj.start );
    } );

    this.calculateTotalRound();
  };

  toggleWorkingDaysModal = () => {
    this.setState( previousState => ( { workingDaysModal: !previousState.workingDaysModal } ) );
  };

  handleNext = ( data ) => {
    const { activeStep } = this.state;

    this.setState( {
      activeStep: activeStep + 1,
      // eslint-disable-next-line react/no-unused-state
      data: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      // eslint-disable-next-line react/no-unused-state
      data: this.loadDataDetail( data ),
    } );
  };
  loadDataDetail = ( dataDetail ) => {
    const data = _.cloneDeep( dataDetail );
    return data;
  };

  submitForm = async ( formData ) => {
    const { toggleLoading, toggleAlert, addSessionCreating } = this.props;
    /*     const { totalRound, roundsClosePerDay } = this.state; */
    const dataToSend = {};

    const exclude = [];

    if ( this.monday ) {
      exclude.push( 'MONDAY' );
    }

    if ( this.tuesday ) {
      exclude.push( 'TUESDAY' );
    }
    if ( this.wednesday ) {
      exclude.push( 'WEDNESDAY' );
    }

    if ( this.thursday ) {
      exclude.push( 'THURSDAY' );
    }
    if ( this.friday ) {
      exclude.push( 'FRIDAY' );
    }
    if ( this.saturday ) {
      exclude.push( 'SATURDAY' );
    }

    if ( this.sunday ) {
      exclude.push( 'SUNDAY' );
    }

    toggleLoading( true );

    // const gmtDate = momentz.tz( '2021-06-01 07:00', 'Etc/GMT' ); // GMT at 7:00
    // const closingTimeZone = gmtDate.clone().tz( formData.timeZone ).format( 'HH:mm' );
    // console.info('Timezone: ' +formData.timeZone+ ' ' + closingTimeZone);
    // toggleLoading( false );
    // return;
    // const currentDate = new Date();
    // const uctMin = currentDate.getUTCMinutes();
    // const utcHour = currentDate.getUTCHours();
    // const closingTimeZone = `${utcHour}:${uctMin}`;

    if ( formData.closingType === 'CLOSING_TIME' ) {
      const adjustedHour = moment
        .tz( '2013-08-26 04:00:00', formData.timeZone )
        .utc()
        .format( 'HH:mm' );
      dataToSend.endTime = adjustedHour; // closingTimeZone;
      dataToSend.manualClosing = false;
    }

    if ( formData.closingType === 'MANUAL_CLOSING' ) {
      dataToSend.manualClosing = true;
    }

    if ( this.activeStrategy ) {
      dataToSend.weekReportDeliveryDay = formData.weekReportDeliveryDay;
      dataToSend.weekReportDeliveryTime = moment(
        Number( formData.weekReportDeliveryTime ),
      ).format( 'HH:mm' );
      dataToSend.initialReportDeliveryDate = `${moment(
        Number( formData.startDate ),
      ).format( 'YYYY-MM-DD' )} ${moment()
        .add( 2, 'minutes' )
        .format( 'HH:mm' )}`;
      dataToSend.finalReportDeliveryDate = `${moment( Number( formData.endDate ) )
        .subtract( 1, 'days' )
        .format( 'YYYY-MM-DD' )} ${moment()
        .add( 15, 'minutes' )
        .format( 'HH:mm' )}`;
    }

    dataToSend.exclude = exclude;
    dataToSend.withCertificate = this.activeCertif;
    if ( this.forumAvailability ) {
      dataToSend.withDiscussionForum = this.activeForum;
      if ( this.activeForum ) {
        dataToSend.professorPassword = this.password;
      }
    }
    if ( this.activeTestRound ) {
      dataToSend.withTestRound = this.activeTestRound;
      // eslint-disable-next-line max-len
      dataToSend.openningDateTestRound = moment(
        Number( formData.openningDateTestRound ),
      ).format( 'YYYY-MM-DD' );
      dataToSend.closingDateTestRound = moment(
        Number( formData.closingDateTestRound ),
      ).format( 'YYYY-MM-DD' );
      /* dataToSend.numberOfTestRound = ( Number( this.numberOfTestRound )
      < totalRound * roundsClosePerDay && Number( this.numberOfTestRound )
      < 10 ) ? 1 + Number( this.numberOfTestRound ) : this.numberOfTestRound;
 */
    }
    dataToSend.withStrategies = this.activeStrategy;
    dataToSend.endDate = moment( Number( formData.endDate ) ).format( 'YYYY-MM-DD' );
    dataToSend.startDate = moment( Number( formData.startDate ) ).format(
      'YYYY-MM-DD',
    );
    dataToSend.workingDays = this.workingDays;
    dataToSend.entity = this.entityId;
    dataToSend.totalStudents = formData.totalStudents;
    dataToSend.timeZone = formData.timeZone;
    dataToSend.distributionType = formData.distributionType;
    dataToSend.name = formData.name;
    // eslint-disable-next-line max-len
    dataToSend.totalRounds = parseInt( this.totalRounds, 10 ) * parseInt( formData.roundsClosePerDay, 10 );
    dataToSend.marketingBudget = formData.marketingBudget;

    const currentDate = new Date();
    const uctMin = currentDate.getUTCMinutes() < 10
      ? `0${currentDate.getUTCMinutes()}`
      : currentDate.getUTCMinutes();
    const utcHour = currentDate.getUTCHours() < 10
      ? `0${currentDate.getUTCHours()}`
      : currentDate.getUTCHours();
    const startTimeZone = `${utcHour}:${uctMin}`;

    dataToSend.startTime = startTimeZone; // moment().format( 'HH:mm' );
    dataToSend.roundsClosePerDay = formData.roundsClosePerDay;
    dataToSend.simulationSpeed = formData.simulationSpeed;
    dataToSend.marketMaturity = formData.marketMaturity;
    dataToSend.supervisor = formData.supervisor;
    let newSessionCode = '';
    const promise = SessionService.saveSessionV2ByStep( dataToSend );
    await promise.then( ( response ) => {
      // toggleLoading( false );
      if ( !response.ok ) {
        toggleLoading( false );
        try {
          if ( !!response.data.form && response.data.form.errors ) {
            return toggleAlert( response.data.form.errors.name );
          }
          if ( !!response.data.errors && response.data.errors.totalRounds ) {
            return toggleAlert(
              <IntlMessages id="RoundsCannotBeGreaterThan60" />,
            );
          }
          return toggleAlert( response.errors );
        } catch ( err ) {
          return toggleAlert( 'Please Contact the Admin ( Code.500 )' );
        }
      }
      newSessionCode = response.data.id;
      addSessionCreating( response.data.id );
      // this.setState( { registrationCode: response.data.session.registrationCode } );
      // toggleAlert( 'CREATING_SESSION', 'info' );
      this.checkSessionStatus( newSessionCode );
    } );
  };

  checkSessionStatus = async ( sessionCode ) => {
    const {
      toggleLoading,
      user,
      changeActiveSession,
      updateLicenses,
    } = this.props;
    let dataStatus = '';
    await SessionService.getSession2( sessionCode ).then( ( response ) => {
      if ( !response.errors ) {
        dataStatus = response.data;
      }
    } );
    //  if ( dataStatus.creationStatus === 'READY' ) {
    // clearTimeout( this.varTimeOut );
    // toggleAlert( dataStatus.creationStatus, 'info' );
    this.setState( { registrationCode: dataStatus.registrationCode } );
    // toggleAlert( 'SETTING_SESSION', 'info' );

    await UserService.getCurrentUser( user ).then( ( response ) => {
      if ( !response.errors ) {
        const data = { ...user, ...response.data };
        try {
          updateLicenses( data.licenses );
          changeActiveSession( data.licenses[0].session );
        } catch ( err ) {
          // eslint-disable-next-line no-console
          console.log( err );
        }
      }
    } );
    // toggleAlert( 'FINISHED', 'info' );
    toggleLoading( false );
    // resolve();
    // } else {
    //   if ( this.messageStatusSession !== dataStatus.creationStatus ) {
    //     this.messageStatusSession = dataStatus.creationStatus;
    //   } else {
    //     this.messageStatusSession = 'SETTING_WAIT';
    //   }

    // toggleAlert( this.messageStatusSession, 'info' );
    // this.varTimeOut = setTimeout(
    //   () => this.checkSessionStatus( sessionCode ),
    //   5000,
    // ); //
    // }
  };

  handleClickOpen = () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  };

  handleClose = () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  };

  toggle = () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  };
  toggleOpenConfirmationTestRoundModal = () => {
    // eslint-disable-next-line max-len
    this.setState( previousState => ( { openConfirmationTestRoundModal: !previousState.openConfirmationTestRoundModal } ) );
  };

  calculateTotalRound = () => {
    const { toggleLoading, toggleAlert } = this.props;

    const exclude = [];

    if ( this.monday ) {
      exclude.push( 'MONDAY' );
    }

    if ( this.tuesday ) {
      exclude.push( 'TUESDAY' );
    }
    if ( this.wednesday ) {
      exclude.push( 'WEDNESDAY' );
    }

    if ( this.thursday ) {
      exclude.push( 'THURSDAY' );
    }
    if ( this.friday ) {
      exclude.push( 'FRIDAY' );
    }
    if ( this.saturday ) {
      exclude.push( 'SATURDAY' );
    }

    if ( this.sunday ) {
      exclude.push( 'SUNDAY' );
    }
    const dataToSend = {
      startDate: this.startDate,
      endDate: this.endDate,
      exclude,
      workingDays: this.workingDays,
    };

    const currentDate = new Date().setHours( 0, 0, 0, 0 );

    if (
      this.startDate === undefined
      || this.startDate === ''
      || this.endDate === undefined
      || this.endDate === ''
      || currentDate > moment( this.startDate ).toDate()
      || moment( this.endDate ).toDate() <= moment( this.startDate ).toDate()
    ) {
      return;
    }

    toggleLoading( true );

    const promise = SessionService.calculateRounds( dataToSend );

    promise.then( ( response ) => {
      toggleLoading( false );
      if ( response.ok ) {
        this.maxRoundsExceed = false;
        this.totalRounds = response.data.possibleRounds;
        this.setState( { totalRound: response.data.possibleRounds } );
      } else {
        this.maxRoundsExceed = true;
        this.setState( { totalRound: 0 } );
        toggleAlert( response.errors );
      }
    } );
  };

  handleStartDateChange = ( date ) => {
    if ( date === '' ) {
      this.startDate = '';
    } else {
      this.startDate = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.calculateTotalRound();
    this.setState( { startDate: date } );
  };

  handleEndDateChange = ( date ) => {
    if ( date === '' ) {
      this.endDate = '';
    } else {
      this.endDate = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.calculateTotalRound();
    this.setState( { endDate: date } );
  };
  handleOpenningDateTestRoundChange = ( date ) => {
    if ( date === '' ) {
      this.startDate = '';
    } else {
      this.openningDateTestRound = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }

    this.setState( { openningDateTestRound: date } );
  };

  handleClosingDateTestRoundChange = ( date ) => {
    if ( date === '' ) {
      this.closingDateTestRound = '';
    } else {
      this.closingDateTestRound = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.setState( { closingDateTestRound: date } );
  };

  handleMondayChange = ( value_ ) => {
    this.monday = value_;
    this.calculateTotalRound();
    this.setState( { monday: value_ } );
  };
  handleTuesdayChange = ( value_ ) => {
    this.tuesday = value_;
    this.calculateTotalRound();
    this.setState( { tuesday: value_ } );
  };
  handleWednesdayChange = ( value_ ) => {
    this.wednesday = value_;
    this.calculateTotalRound();
    this.setState( { wednesday: value_ } );
  };
  handleThursdayChange = ( value_ ) => {
    this.thursday = value_;
    this.calculateTotalRound();
    this.setState( { thursday: value_ } );
  };
  handleFridayChange = ( value_ ) => {
    this.friday = value_;
    this.calculateTotalRound();
    this.setState( { friday: value_ } );
  };
  handleSaturdayChange = ( value_ ) => {
    this.saturday = value_;
    this.calculateTotalRound();
    this.setState( { saturday: value_ } );
  };

  handleSundayChange = ( value_ ) => {
    this.sunday = value_;
    this.calculateTotalRound();
    this.setState( { sunday: value_ } );
  };

  handleCertifChange = ( value_ ) => {
    this.setState( { activeCertif: value_ } );
    this.activeCertif = value_;
  };

  handleForum = ( value_ ) => {
    this.setState( { activeForum: value_ } );
    this.activeForum = value_;
  };

  handleTestRound = () => ( value_ ) => {
    /*    if ( value_ )changeValue( 'closingType', 'MANUAL_CLOSING' ); */
    this.setState( { activeTestRound: value_ } );
    this.activeTestRound = value_;
  };

  handleNumberRound = ( value ) => {
    this.setState( { numberOfTestRound: value } );
    this.numberOfTestRound = value;
  };

  handleStrategyChange = ( value_ ) => {
    this.setState( { activeStrategy: value_ } );
    this.activeStrategy = value_;
  };
  handleDeliveryDate = ( date ) => {
    if ( date === '' ) {
      this.initialReportDeliveryDate = '';
    } else {
      this.initialReportDeliveryDate = moment( Number( date ) ).format(
        'YYYY-MM-DD',
      );
    }
    this.setState( { initialReportDeliveryDate: date } );
  };

  handleFinalDeliveryDate = ( date ) => {
    if ( date === '' ) {
      this.finalReportDeliveryDate = '';
    } else {
      this.finalReportDeliveryDate = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.setState( { finalReportDeliveryDate: date } );
  };

  handleTotalStudentsChange = ( value ) => {
    const { maxGroups } = this.state;
    this.setState( {
      studentsPerGroup: Math.ceil( value / maxGroups ),
      totalStudents: value,
    } );
  };

  handleMarketingBudget = ( value ) => {
    const newVal = _.replace( value, new RegExp( ',', 'g' ), '' );
    this.setState( { marketingBudget: newVal } );
    this.marketingBudget = newVal;
  };

  handlePassword = ( value ) => {
    this.setState( { password: value } );
    this.password = value;
  };

  handlePasswordConfirmation = ( value ) => {
    this.setState( { passwordConfirmation: value } );
    this.passwordConfirmation = value;
  };

  handleClone = ( value ) => {
    this.setState( { cloneSessionId: value } );
  };
  handleCloneSession = () => {
    this.handleToggle();
    const { cloneSessionId, sessionsData } = this.state;
    const obj = sessionsData.find( o => o.id === cloneSessionId );
    this.endDate = moment( Number( obj.endDate ) ).format( 'YYYY-MM-DD' );
    this.startDate = moment( Number( obj.startDate ) ).format( 'YYYY-MM-DD' );
    this.setState( { data: obj, studentsPerGroup: obj.studentPerGroup } );
    this.calculateTotalRound();
  };

  handleClick = ( event ) => {
    const open = true;
    this.setState( { anchorEl: event.currentTarget } );
    this.setState( { open } );
  };

  handleClose = () => {
    const open = false;
    this.setState( { anchorEl: null } );
    this.setState( { open } );
  };
  getStepButtons = ( form ) => {
    const { activeStep, registrationCode } = this.state;
    const lastStep = activeStep >= 1;

    return (
      <div className="text-center w-100 d-flex align-items-center justify-content-end h-100">
        <Button
          variant="text"
          className="kotler-button kotler-button-danger "
          component={Link}
          to="/"
        >
          {registrationCode ? (
            <IntlMessages id="exit" />
          ) : (
            <IntlMessages id="cancelCreation" />
          )}
        </Button>

        <Button
          variant="outlined"
          disabled={activeStep <= 0 || registrationCode}
          className="kotler-button "
          color="primary"
          type="submit"
          onClick={() => {
            if ( form.getState().valid ) this.handleBack( form.getState().values );
          }}
        >
          <IntlMessages id="back" />
        </Button>
        <Button
          variant={lastStep ? 'contained' : 'contained'}
          color="primary"
          disabled={registrationCode}
          className="kotler-button kotler-button-primary mx-2"
          onClick={() => {
            if ( !form.getState().valid || lastStep ) {
              form.submit();
            } else if (
              form.getState().valid
              || ( activeStep === 0 && !form.getState().errors.name )
              || ( activeStep === 1
                && !form.getState().errors.behavioralSegmentations1
                && !form.getState().errors.demographicSegmentation
                && !form.getState().errors.behavioralSegmentations2
                && !form.getState().errors.iotSegmentation )
            ) {
              this.handleNext( form.getState().values );
            }
          }}
        >
          {lastStep ? <IntlMessages id="save" /> : <IntlMessages id="next" />}
        </Button>
      </div>
    );
  };
  handleToggle() {
    const { previewVisible } = this.state;
    this.setState( { previewVisible: !previewVisible } );
  }

  render() {
    const {
      maxStudents,
      workingDaysModal,
      workingDays,
      timeZones,
      professors,
      supervisor,
      maxGroups,
      studentsPerGroup,
      totalRound,
      data,
      registrationCode,
      openningDateTestRound,
      closingDateTestRound,
      popoverItems,
      previewVisible,
      listOfSession,
      activeCertif,
      activeStrategy,
      marketingBudget,
      activeStep,
      steps,
      name,
      endDate,
      startDate,
      totalStudents,
      timeZone,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      weekReportDeliveryDay,
      weekReportDeliveryTime,
      initialReportDeliveryTime,
      finalReportDeliveryTime,
      initialReportDeliveryDate,
      finalReportDeliveryDate,
      closingTime,
      closingType,
      distributionType,
      roundsClosePerDay,
      simulationSpeed,
      marketMaturity,
      forumAvailability,
      password,
      passwordConfirmation,
      activeForum,
      numberOfTestRound,
      activeTestRound,
    } = this.state;
    const incativeWeekDays = [
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ].filter( i => !!i ).length;
    const { user } = this.props;
    const closeTimeFormat = moment( closingTimeDate ).format( 'h:mm a' );
    const universities = user.entities || [];
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'newSession' },
          ]}
        />
        <KotlerSectionHeader
          title="newSession"
          backTo="/"
          image={HeaderImage}
        />
        <Container className="mt-3" maxWidth="lg">
          <Prompt when={!( user && user.activeSession )} />
          <Form
            initialValues={{
              entityId: this.entityId,
              data,
              marketingBudget,
              name,
              endDate,
              startDate,
              totalStudents,
              timeZone,
              supervisor,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
              sunday,
              weekReportDeliveryDay,
              weekReportDeliveryTime,
              initialReportDeliveryTime,
              finalReportDeliveryTime,
              initialReportDeliveryDate,
              finalReportDeliveryDate,
              closingTime,
              closingType,
              distributionType,
              roundsClosePerDay,
              simulationSpeed,
              marketMaturity,
              password,
              numberOfTestRound,
              passwordConfirmation,
              openningDateTestRound,
              closingDateTestRound,
            }}
            onSubmit={this.submitForm}
            validate={( values ) => {
              const errors = {};

              // if ( ( values.closingType === 'CLOSING_TIME' )
              // && ( !values.closingTime ) ) {
              //   errors.closingTime = required( values.closingTime );
              // }
              if ( values.marketingBudget < 300000000 ) {
                errors.marketingBudget = 'fieldError.marketingBudget';
              }
              if ( values.marketingBudget > 900000000 ) {
                errors.marketingBudget = 'fieldError.marketingBudget';
              }
              if ( values.finalReportDeliveryDate >= values.endDate ) {
                errors.finalReportDeliveryDate = 'fieldError.finalReportDeliveryDateLessThanEndDate';
              }
              return errors;
            }}
            render={( { handleSubmit, form } ) => (
              <form onSubmit={handleSubmit}>
                <Grid container className="kotler-stepper-container ">
                  <Grid item xs={12} sm={5} md={4} lg={3}>
                    <Stepper
                      activeStep={activeStep}
                      className="kotler-stepper bg-transparent"
                    >
                      {steps.map( ( step, index ) => (
                        <Step key={step.id}>
                          <StepLabel
                            className={`${
                              index === activeStep ? 'active' : ''
                            }`}
                            StepIconProps={{ completed: false }}
                          >
                            <IntlMessages id={step.label} />
                          </StepLabel>
                        </Step>
                      ) )}
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8} lg={9}>
                    {this.getStepButtons( form )}
                  </Grid>
                </Grid>

                {steps[activeStep].id === 1 && (
                  <Grid
                    container
                    justify="space-around"
                    style={{ rowGap: '20px' }}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CardBox heading={null} styleName="px-3 h-100">
                        <div className="row">
                          <div className="col-md-12">
                            <TextField
                              field="name"
                              adornRequired
                              label="sessionName"
                              validate={required}
                              id="nameFieldID"
                              onChange={value => this.setState( { name: value } )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <TextField
                              adornRequired
                              field="totalStudents"
                              label="totalStudents"
                              type="number"
                              inputProps={{ maxLength: 3 }}
                              // eslint-disable-next-line max-len
                              validate={composeValidators(
                                required,
                                lessThanValue( maxGroups * maxStudents ),
                              )}
                              translateValues={{ value: maxGroups * maxStudents }}
                              id="studentsFieldID"
                              onChange={this.handleTotalStudentsChange}
                            />
                          </div>
                          <div className="col-md-12 text-center">
                            <h5 className="mb-1">
                              <span
                                id="studentsPerGroupFieldID"
                                className="kotler-text-field-label"
                              >
                                <IntlMessages id="studensPerGroup" />
                              </span>
                            </h5>
                            <Badge
                              className="bg-blue-grey text-white d-block ml-1 mb-3"
                              pill
                            >
                              <h4 className="mb-1">
                                {Util.formatNumberNoDecimals( studentsPerGroup )}
                              </h4>
                            </Badge>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Select
                              adornRequired
                              field="timeZone"
                              label="timeZone"
                              options={timeZones}
                              translateOptions={false}
                              validate={required}
                              id="timezoneFieldID"
                              onChange={value => this.setState( { timeZone: value } )
                              }
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <RadioButtons
                              adornRequired
                              label="distributionType"
                              validate={composeValidators( required )}
                              field="distributionType"
                              valueKey="name"
                              options={[
                                {
                                  id: 'studio',
                                  name: 'LiveMax Studio',
                                },
                                {
                                  id: 'enterprise',
                                  name: 'LiveMax Enterprise',
                                },
                              ]}
                              onChange={( value ) => {
                                this.setState( { distributionType: value } );
                                // if ( value === 'MANUAL_CLOSING' ) {
                                //   form.change( 'closingTime', null );
                                // this.activeClosingTime = false;
                                // }
                                // if ( value === 'CLOSING_TIME' ) {
                                // this.activeClosingTime = true;
                                // }
                              }}
                              displayBlock
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <Select
                              adornRequired
                              field="entityId"
                              label="university"
                              options={universities}
                              translateOptions={false}
                              validate={required}
                              id="universityId"
                              onChange={( value ) => {
                                this.entityId = value;
                                this.getData();
                                this.setState( { supervisor: null } );
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <Select
                              adornRequired
                              field="supervisor"
                              label="sessionSupervisor"
                              options={professors}
                              translateOptions={false}
                              validate={required}
                              id="professorsID"
                              onChange={value => this.setState( {
                                supervisor: value,
                                supervisorDirty: true,
                              } )
                              }
                            />
                          </div>
                        </div>
                      </CardBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CardBox heading={null} styleName="px-3 h-100">
                        <Grid container>
                          <Grid item xs={9} sm={8} lg={9}>
                            <DatePicker
                              adornRequired
                              label="dateFirstRound"
                              field="startDate"
                              fullWidth
                              // eslint-disable-next-line max-len
                              validate={composeValidators(
                                required,
                                greaterOrEqualValue(
                                  new Date().setHours( 0, 0, 0, 0 ),
                                ),
                                activeTestRound
                                  ? greaterThan( 'closingDateTestRound' )
                                  : () => null,
                              )}
                              onChange={this.handleStartDateChange}
                              translateValues={{
                                value: moment().format( 'YYYY-MM-DD' ),
                                label: <IntlMessages id="dateLastRoundTest" />,
                              }}
                            />
                          </Grid>
                          {' '}
                          <Grid
                            item
                            xs={3}
                            sm={4}
                            lg={3}
                            className="justify-content-center d-flex align-items-center "
                          >
                            {closeTimeFormat}
                          </Grid>
                          <Grid item xs={9} sm={8} lg={9}>
                            <DatePicker
                              adornRequired
                              label="dateLastRound"
                              field="endDate"
                              validate={composeValidators(
                                required,
                                greaterThan( 'startDate' ),
                              )}
                              onChange={this.handleEndDateChange}
                              translateValues={{ label: <IntlMessages id="dateFirstRound" /> }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={4}
                            lg={3}
                            className="justify-content-center d-flex align-items-center "
                          >
                            {closeTimeFormat}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <h5 className="mb-1 kotler-text-field-label">
                              <IntlMessages id="totalRound" />
                            </h5>
                          </Grid>
                          <Grid item xs={12}>
                            <Badge
                              className="bg-blue-grey text-white  w-100"
                              pill
                            >
                              <h4 className="mb-1">
                                {Util.formatNumberNoDecimals(
                                  totalRound * roundsClosePerDay,
                                )}
                              </h4>
                            </Badge>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-center"
                          >
                            <h5 className="mb-2 mt-4 kotler-text-field-label">
                              <IntlMessages id="roundsClosePerDay" />
                            </h5>
                          </Grid>
                          <Grid item xs={6}>
                            <RadioButtons
                              validate={composeValidators( required )}
                              field="roundsClosePerDay"
                              valueKey="name"
                              options={[
                                {
                                  id: 1,
                                  name: 'oneRound',
                                },
                                {
                                  id: 3,
                                  name: 'threeRounds',
                                },
                                {
                                  id: 6,
                                  name: 'sixRounds',
                                },
                              ]}
                              onChange={( value ) => {
                                this.setState( { roundsClosePerDay: value } );
                              }}
                              displayBlock
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <RadioButtons
                              validate={composeValidators( required )}
                              field="closingType"
                              valueKey="name"
                              options={[
                                {
                                  id: 'CLOSING_TIME',
                                  name: 'AUTOMATIC_CLOSING',
                                },
                                {
                                  id: 'MANUAL_CLOSING',
                                  name: 'MANUAL_CLOSING',
                                },
                              ]}
                              onChange={( value ) => {
                                this.setState( { closingType: value } );
                                // if ( value === 'MANUAL_CLOSING' ) {
                                //   form.change( 'closingTime', null );
                                // this.activeClosingTime = false;
                                // }
                                // if ( value === 'CLOSING_TIME' ) {
                                // this.activeClosingTime = true;
                                // }
                              }}
                              displayBlock
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-center my-3"
                          >
                            <Switch
                              label="withTestRound"
                              checked={activeTestRound}
                              onChange={this.handleTestRound( form.change )}
                              marginContainer={false}
                            />
                          </Grid>
                          {/*
                      <Grid item xs={12}>
                        <TextField
                          field="numberOfTestRound"
                          label="numberTestRound"

                          // eslint-disable-next-line max-len
                          validate={activeTestRound ? composeValidators
                            ( required, betweenValue( 1, 10 ),
                             lessOrEqualValue( totalRound * roundsClosePerDay ) ) : null}
                          // eslint-disable-next-line max-len
                          translateValues={{ valueMin: 1, valueMax: 10,
                            value: totalRound * roundsClosePerDay }}
                          type="number"
                          inputProps={{ maxLength: 2 }}
                          id="numberOfTestRoundID"
                          onChange={this.handleNumberRound}
                          disabled={!activeTestRound}
                        />
                      </Grid> */}
                          <Grid item xs={9} sm={8} lg={9}>
                            <DatePicker
                              adornRequired
                              disabled={!activeTestRound}
                              label="dateFirstRoundTest"
                              field="openningDateTestRound"
                              fullWidth
                              // eslint-disable-next-line max-len
                              validate={
                                activeTestRound
                                  ? composeValidators(
                                    required,
                                    greaterOrEqualValue(
                                      new Date().setHours( 0, 0, 0, 0 ),
                                    ),
                                  )
                                  : null
                              }
                              onChange={this.handleOpenningDateTestRoundChange}
                              translateValues={{ value: moment().format( 'YYYY-MM-DD' ) }}
                            />
                          </Grid>
                          {' '}
                          <Grid
                            item
                            xs={3}
                            sm={4}
                            lg={3}
                            className="justify-content-center d-flex align-items-center "
                          >
                            {closeTimeFormat}
                          </Grid>
                          <Grid item xs={9} sm={8} lg={9}>
                            <DatePicker
                              disabled={!activeTestRound}
                              adornRequired
                              label="dateLastRoundTest"
                              field="closingDateTestRound"
                              validate={
                                activeTestRound
                                  ? composeValidators(
                                    required,
                                    greaterThan( 'openningDateTestRound' ),
                                    lessThan( 'startDate' ),
                                  )
                                  : null
                              }
                              onChange={this.handleClosingDateTestRoundChange}
                              translateValues={{
                                label: <IntlMessages id="dateFirstRoundTest" />,
                                lessThanLabel: (
                                  <IntlMessages id="dateFirstRound" />
                                ),
                              }}
                            />
                          </Grid>
                          {' '}
                          <Grid
                            item
                            xs={3}
                            sm={4}
                            lg={3}
                            className="justify-content-center d-flex align-items-center "
                          >
                            {closeTimeFormat}
                          </Grid>
                          {
                            // <div className="col-md-3">
                            //  <TimePicker
                            //    label="closingTime"
                            //    field="closingTime"
                            //    disabled={!this.activeClosingTime}
                            //    marginContainer={false}
                            //    onChange={( value ) => {
                            //      this.setState( { closingTime: value } );
                            //    }}
                            //  />
                            // </div>
                          }
                        </Grid>
                      </CardBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <CardBox heading={null} styleName="px-3 h-100">
                        <div className="row">
                          <div className="col-md-12 ">
                            <h5 className="kotler-text-field-label">
                              <IntlMessages id="Exclude" />
                            </h5>
                            <Row>
                              <Col>
                                <div className="row px-3">
                                  <Checkbox
                                    id="MondayFieldID"
                                    containerClass="col-6"
                                    marginContainer={false}
                                    fullWidth={false}
                                    field="monday"
                                    label="monday"
                                    disabled={!monday && incativeWeekDays > 5}
                                    onChange={this.handleMondayChange}
                                  />
                                  <Checkbox
                                    containerClass="col-6"
                                    id="TuesdayFieldID"
                                    marginContainer={false}
                                    fullWidth={false}
                                    field="tuesday"
                                    label="tuesday"
                                    disabled={!tuesday && incativeWeekDays > 5}
                                    onChange={this.handleTuesdayChange}
                                  />
                                  <Checkbox
                                    containerClass="col-6"
                                    id="WednesdayFieldID"
                                    marginContainer={false}
                                    fullWidth={false}
                                    field="wednesday"
                                    label="wednesday"
                                    disabled={
                                      !wednesday && incativeWeekDays > 5
                                    }
                                    onChange={this.handleWednesdayChange}
                                  />
                                  <Checkbox
                                    id="ThursdayFieldID"
                                    marginContainer={false}
                                    containerClass="col-6"
                                    fullWidth={false}
                                    field="thursday"
                                    label="thursday"
                                    disabled={!thursday && incativeWeekDays > 5}
                                    onChange={this.handleThursdayChange}
                                  />
                                  <Checkbox
                                    id="FridayFieldID"
                                    marginContainer={false}
                                    containerClass="col-6"
                                    fullWidth={false}
                                    field="friday"
                                    label="friday"
                                    disabled={!friday && incativeWeekDays > 5}
                                    onChange={this.handleFridayChange}
                                  />
                                  <Checkbox
                                    id="saturdayFieldID"
                                    marginContainer={false}
                                    containerClass="col-6"
                                    fullWidth={false}
                                    field="saturday"
                                    label="Saturday"
                                    disabled={!saturday && incativeWeekDays > 5}
                                    onChange={this.handleSaturdayChange}
                                  />
                                  <Checkbox
                                    id="sundayFieldID"
                                    marginContainer={false}
                                    containerClass="col-6"
                                    fullWidth={false}
                                    field="sunday"
                                    label="Sunday"
                                    disabled={!sunday && incativeWeekDays > 5}
                                    onChange={this.handleSundayChange}
                                  />
                                  {' '}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <h5 className="kotler-text-field-label">
                              <IntlMessages id="workingDaysLabel" />
                            </h5>

                            <WorkingDaysCalendarV2
                              data={workingDays}
                              entityId={this.entityId}
                              open={workingDaysModal}
                              onConfirm={this.toConfirm}
                              onClose={this.toggleWorkingDaysModal}
                            />
                          </div>
                        </div>
                      </CardBox>
                    </Grid>
                  </Grid>
                )}
                {steps[activeStep].id === 2 && (
                  <Grid
                    container
                    justify="space-around"
                    style={{ rowGap: '20px' }}
                  >
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                      <CardBox heading={null} styleName="px-2 h-100">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <TextField
                                  adornRequired
                                  field="marketingBudget"
                                  label="marketingBudget"
                                  type="number"
                                  // eslint-disable-next-line max-len
                                  validate={composeValidators( required )}
                                  onChange={this.handleMarketingBudget}
                                  translateValues={{
                                    value:
                                      this.marketingBudget < 300000000
                                        ? 300000000
                                        : 900000000,
                                  }}
                                  id="marketingBudgetID"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <RadioButtons
                                  adornRequired
                                  label="simulationSpeed"
                                  validate={composeValidators( required )}
                                  field="simulationSpeed"
                                  valueKey="name"
                                  options={[
                                    {
                                      id: 'low',
                                      name: 'low',
                                    },
                                    {
                                      id: 'normal',
                                      name: 'normal',
                                    },
                                    {
                                      id: 'high',
                                      name: 'high',
                                    },
                                  ]}
                                  onChange={( value ) => {
                                    this.setState( { simulationSpeed: value } );
                                  }}
                                  displayBlock
                                />
                              </div>

                              <div className="col-sm-6">
                                <RadioButtons
                                  adornRequired
                                  label="marketMaturity"
                                  validate={composeValidators( required )}
                                  field="marketMaturity"
                                  valueKey="name"
                                  options={[
                                    {
                                      id: 'growing',
                                      name: 'growing',
                                    },
                                    {
                                      id: 'mature',
                                      name: 'mature',
                                    },
                                  ]}
                                  onChange={( value ) => {
                                    this.setState( { marketMaturity: value } );
                                  }}
                                  displayBlock
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBox>
                    </Grid>
                    {false && (
                      <Grid item xs={12} sm={12} md={7} lg={8}>
                        <CardBox heading={null} styleName="px-2 h-100">
                          <div className="row">
                            <div className="col-md-12">
                              <Switch
                                label="withStragegyLabel"
                                checked={activeStrategy}
                                onChange={( value ) => {
                                  this.handleStrategyChange( value );
                                  form.change(
                                    'initialReportDeliveryDate',
                                    null,
                                  );
                                  form.change(
                                    'initialReportDeliveryTime',
                                    null,
                                  );
                                  form.change( 'finalReportDeliveryDate', null );
                                  form.change( 'finalReportDeliveryTime', null );
                                  form.change( 'weekReportDeliveryTime', null );
                                  form.change( 'weekReportDeliveryDay', null );
                                }}
                                marginContainer={false}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-7">
                              <p />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-8">
                              <Select
                                label="weekReportDeliveryDay"
                                validate={
                                  activeStrategy
                                    ? composeValidators( required )
                                    : null
                                }
                                field="weekReportDeliveryDay"
                                translateOptions={false}
                                disabled={!activeStrategy}
                                options={[
                                  {
                                    id: '0',
                                    name: <IntlMessages id="sunday" />,
                                  },
                                  {
                                    id: '1',
                                    name: <IntlMessages id="monday" />,
                                  },
                                  {
                                    id: '2',
                                    name: <IntlMessages id="tuesday" />,
                                  },
                                  {
                                    id: '3',
                                    name: <IntlMessages id="wednesday" />,
                                  },
                                  {
                                    id: '4',
                                    name: <IntlMessages id="thursday" />,
                                  },
                                  {
                                    id: '5',
                                    name: <IntlMessages id="friday" />,
                                  },
                                  {
                                    id: '6',
                                    name: <IntlMessages id="saturday" />,
                                  },
                                ]}
                                onChange={( value ) => {
                                  this.setState( { weekReportDeliveryDay: value } );
                                }}
                                value={weekReportDeliveryDay}
                              />
                            </div>
                            <div className="col-md-4">
                              <TimePicker
                                label="weekReportDeliveryTime"
                                validate={
                                  activeStrategy
                                    ? composeValidators( required )
                                    : null
                                }
                                field="weekReportDeliveryTime"
                                disabled={!activeStrategy}
                                marginContainer={false}
                                onChange={( value ) => {
                                  this.setState( { weekReportDeliveryTime: value } );
                                }}
                                value={weekReportDeliveryTime}
                              />
                            </div>
                          </div>
                        </CardBox>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                      <CardBox heading={null} styleName="px-2 h-100">
                        <div className="row ">
                          <div className="col-md-12">
                            <Switch
                              label="withCertificateLabel"
                              checked={activeCertif}
                              onChange={this.handleCertifChange}
                              marginContainer={false}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          {false && forumAvailability && (
                            <div className="col-md-12 mt-2">
                              <Switch
                                label="withDiscussionForum"
                                checked={activeForum}
                                onChange={this.handleForum}
                                marginContainer={false}
                              />
                              <div className="mt-3" />
                              <TextField
                                field="password"
                                label="password"
                                type="password"
                                // eslint-disable-next-line max-len
                                validate={
                                  activeForum
                                    ? composeValidators(
                                      required,
                                      passwordZulipValidation,
                                    )
                                    : null
                                }
                                onChange={this.handlePassword}
                                disabled={!activeForum}
                              />
                              <TextField
                                field="passwordConfirmation"
                                label="passwordConfirmation"
                                type="password"
                                // eslint-disable-next-line max-len
                                validate={
                                  activeForum
                                    ? composeValidators(
                                      required,
                                      equalTo( 'password' ),
                                    )
                                    : null
                                }
                                translateValues={{ label: <IntlMessages id="password" /> }}
                                disabled={!activeForum}
                                onChange={this.handlePasswordConfirmation}
                              />
                            </div>
                          )}
                        </div>
                        {registrationCode && (
                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{
                                alignSelf: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <span
                                className="mb-1"
                                style={{ fontStyle: 'bold' }}
                              >
                                <IntlMessages id="registrationCode" />
                              </span>
                            </div>
                            <div
                              className="col-md-6"
                              style={{
                                alignSelf: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <span
                                className="mb-1"
                                style={{ fontSize: 32, fontStyle: 'bold' }}
                              >
                                {registrationCode}
                              </span>
                            </div>
                          </div>
                        )}
                      </CardBox>
                    </Grid>
                  </Grid>
                )}
                <Modal
                  isOpen={previewVisible}
                  // eslint-disable-next-line react/jsx-no-bind
                  toggle={this.handleToggle.bind( this )}
                >
                  <ModalHeader
                    // eslint-disable-next-line react/jsx-no-bind
                    toggle={this.handleToggle.bind( this )}
                  >
                    <IntlMessages id="cloneSession" />
                  </ModalHeader>
                  <ModalBody>
                    <Select
                      field="sessionToClone"
                      label="session"
                      options={listOfSession}
                      translateOptions={false}
                      onChange={this.handleClone}
                    />

                    <Button
                      id="ok"
                      variant="contained"
                      className="jr-btn bg-blue-grey text-white d-block ml-2"
                      type="button"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={this.handleCloneSession.bind( this )}
                    >
                      <h5 className="mb-1">
                        <IntlMessages id="toClone" />
                      </h5>
                    </Button>
                  </ModalBody>
                </Modal>

                {/*  <Row>
                <Col md={4} className="m-md-auto">

                  <div className="text-center mt-2">

                    {( steps[activeStep].id === 2 && !registrationCode ) && (
                      <>
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="button"
                          onClick={() => {
                            if ( form.getState().valid ) {
                              this.setState( { activeStep: activeStep - 1 } );
                            } else {
                              form.submit();
                            }
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                          <IntlMessages id="back" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                        >
                          <i className="fa fa-arrow-circle-right" />
                          <IntlMessages id="createSessionAndRound" />
                        </Button>
                      </>
                    )}
                    {( steps[activeStep].id === 1 ) && (
                      <Button
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-lg"
                        type="submit"
                        onClick={() => {
                          if ( form.getState().valid ) {
                            this.handleNext( form.getState().values );
                          }
                        }}
                      >
                        <IntlMessages id="next" />
                        <i className="fa fa-angle-double-right" />
                      </Button>
                    )}
                  </div>
                </Col>
              </Row> */}
              </form>
            )}
          />
          {_.map( popoverItems, ( item, i ) => (
            <div key={`popover-${item.id}-${i}-1`}>
              <UncontrolledPopover
                placement="top-start"
                trigger="hover"
                target={item.id}
              >
                <PopoverHeader>{item.header}</PopoverHeader>
                <PopoverBody>
                  <p>{item.content}</p>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          ) )}
        </Container>
      </>
    );
  }
}
const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  addSessionCreating: UserActions.addSessionCreating,
  updateLicenses: UserActions.updateLicenses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( newSession );
