import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';

import ReactExport from 'react-export-excel';
import IntlMessages from 'utils/IntlMessages';
import { connect } from 'react-redux';
import { formatAllRoundServicesToExcel } from 'modules/Services/Helpers/allRoundServicesToExcellHelpers';
import { formatAllRoundsBundlesToExcel } from 'modules/Bundles/helpers/allRoundBundlesToExcellHelpers';
import { formatTotalCustomersToExcell } from 'modules/Customers/Helpers/totalCustomersToExcellHelpers';
import { formatMessage } from 'helpers/IntlHelpers';
import { formatMarketProductsToExcel } from 'modules/Products/helpers/marketProductsToExcellHelpers';
import { formatlastProductsByCategoryToExcel } from 'modules/Products/helpers/lastRoundProductsToExcellHelpers';
import { formatlastBundlesByCategoryToExcel } from 'modules/Bundles/helpers/lastRoundBundlesToExcellHelpers';
import SettingsActions from 'store/reducers/Settings';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const initialInfo = {
  xSteps: 0,
  ySteps: 1,
  columns: ['No Data Found'],
  data: [],
};

const MarketReportBtn = ( {
  totalCustomers,
  allRoundsServices,
  laptopMarket,
  tabletMarket,
  phoneMarket,
  locale,
  allRoundsBundles,
  lastRoundProducts,
  lastRoundBundles,
  loading,
  error,
  isStudio,
  toggleAlert,
} ) => {
  const [customersData, setCustomersData] = useState( [initialInfo] );
  const [productsData, setProducstData] = useState( [initialInfo] );
  const [productsByGroupData, setProductsByGropupData] = useState( [
    initialInfo,
  ] );
  const [servicesData, setServicesData] = useState( [initialInfo] );
  const [bundlesData, setBundlesData] = useState( [initialInfo] );
  const [bundlesInfoData, setBundlesInfoData] = useState( [initialInfo] );
  const [formating, setFormating] = useState( false );

  useEffect( () => {
    setFormating( true );
    if (
      totalCustomers.data
      && ( isStudio
        || ( laptopMarket.data
          && laptopMarket.data.data
          && tabletMarket.data
          && tabletMarket.data.data
          && allRoundsServices.data
          && allRoundsBundles.data
          && lastRoundBundles.data ) )
      && lastRoundProducts.data
      && phoneMarket.data
      && phoneMarket.data.data
    ) {
      setCustomersData( formatTotalCustomersToExcell( totalCustomers.data ) );

      if ( !isStudio ) {
        // data for enterprise
        setServicesData( formatAllRoundServicesToExcel( allRoundsServices.data ) );
        setBundlesData( formatAllRoundsBundlesToExcel( allRoundsBundles.data ) );
        const pLM = laptopMarket.data.data;
        const pTM = tabletMarket.data.data;
        const pPM = phoneMarket.data.data;
        setProducstData( [
          ...formatMarketProductsToExcel( pLM, 'LAPTOPMARKET' ),
          ...formatMarketProductsToExcel( pTM, 'TABLETMARKET' ),
          ...formatMarketProductsToExcel( pPM, 'CELLPHONEMARKET' ),
        ] );
        setProductsByGropupData(
          formatlastProductsByCategoryToExcel( lastRoundProducts.data ),
        );
        setBundlesInfoData(
          formatlastBundlesByCategoryToExcel( lastRoundBundles.data ),
        );
      } else {
        // data for studio
        setProducstData(
          formatMarketProductsToExcel( phoneMarket.data.data, 'CELLPHONEMARKET' ),
        );
        const productsLastRound = lastRoundProducts.data.filter(
          ( { name } ) => name === 'Cellular',
        );
        setProductsByGropupData(
          formatlastProductsByCategoryToExcel( productsLastRound ),
        );
      }
    }
    setFormating( false );
  }, [
    isStudio,
    laptopMarket.data,
    tabletMarket.data,
    phoneMarket.data,
    totalCustomers.data,
    allRoundsServices.data,
    allRoundsBundles.data,
    lastRoundBundles.data,
    lastRoundProducts.data,
    locale.locale,
  ] );
  useEffect( () => {
    if ( error ) {
      toggleAlert( 'graphError', 'danger', true );
    }
  }, [error, toggleAlert] );

  return !loading && !formating && !error ? (
    isStudio ? (
      <ExcelFile
        element={(
          <Button
            variant="text"
            type="button"
            color="primary"
            style={{ textTransform: 'none' }}
          >
            <FeatherIcon
              icon="download"
              color="primary.main"
              size="18px"
              className="mx-2"
            />
            <IntlMessages id="download" />
          </Button>
        )}
        filename={formatMessage( { id: 'marketReport' } )}
      >
        <ExcelSheet
          dataSet={customersData}
          name={formatMessage( { id: 'customers' } )}
        />
        <ExcelSheet
          dataSet={[...productsData, ...productsByGroupData]}
          name={formatMessage( { id: 'products' } )}
        />
      </ExcelFile>
    ) : (
      <ExcelFile
        element={(
          <Button
            variant="text"
            type="button"
            color="primary"
            style={{ textTransform: 'none' }}
          >
            <FeatherIcon
              icon="download"
              color="primary.main"
              size="18px"
              className="mx-2"
            />
            <IntlMessages id="download" />
          </Button>
        )}
        filename={formatMessage( { id: 'marketReport' } )}
      >
        <ExcelSheet
          dataSet={customersData}
          name={formatMessage( { id: 'customers' } )}
        />
        <ExcelSheet
          dataSet={[...productsData, ...productsByGroupData]}
          name={formatMessage( { id: 'products' } )}
        />
        <ExcelSheet
          dataSet={[...servicesData, ...bundlesData, ...bundlesInfoData]}
          name={formatMessage( { id: 'services' } )}
        />
      </ExcelFile>
    )
  ) : (
    <Button
      variant="text"
      type="button"
      color={!error ? undefined : 'secondary'}
      style={{ textTransform: 'none' }}
      disabled
    >
      <span
        className={` d-flex animate__animated ${
          !error ? ' animate__infinite animate__bounce' : 'animate__shakeY'
        }`}
      >
        <FeatherIcon
          className="mx-2"
          icon={!error ? 'downloadCloud' : 'cloudLightning'}
          color={!error ? 'gray' : 'error.main'}
          size="21px"
        />
      </span>
    </Button>
  );
};

const mapStateToProps = (
  {
    services: { allRoundsServices },
    bundles: { allRoundsBundles, lastRoundBundles },
    products: { marketProducts, lastRoundProducts },
    customers: { totalCustomers },
    settings: { locale },
  },
  { categories = [] },
) => {
  const products = categories.map( ( { id } ) => marketProducts.byCategoryId[id] );
  const { laptopMarket, tabletMarket, phoneMarket } = {
    laptopMarket: products[0] || { loading: false, data: null, error: null },
    tabletMarket: products[1] || { loading: false, data: null, error: null },
    phoneMarket: products[2] || { loading: false, data: null, error: null },
  };
  // const someoneEmpty = !!(
  //   !categories.length
  //   || !laptopMarket.data
  //   || !tabletMarket.data
  //   || !phoneMarket.data
  //   || !lastRoundProducts.data
  //   || !allRoundsBundles.data
  //   || !allRoundsServices.data
  //   || !totalCustomers.data
  //   || !lastRoundBundles.data
  // );
  const loading = !categories.length
    || laptopMarket.loading
    || tabletMarket.loading
    || phoneMarket.loading
    || lastRoundProducts.loading
    || allRoundsBundles.loading
    || allRoundsServices.loading
    || totalCustomers.loading
    || lastRoundBundles.loading;
  const error = ( !categories.length && !loading )
    || laptopMarket.error
    || tabletMarket.error
    || phoneMarket.error
    || lastRoundProducts.error
    || allRoundsBundles.error
    || allRoundsServices.error
    || totalCustomers.error
    || lastRoundBundles.error;
  return {
    laptopMarket,
    tabletMarket,
    phoneMarket,
    totalCustomers,
    locale,
    allRoundsServices,
    allRoundsBundles,
    lastRoundProducts,
    lastRoundBundles,
    loading,
    error,
  };
};
const mapDispatchToProps = { toggleAlert: SettingsActions.toggleAlert };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( MarketReportBtn );
