import React, { Component } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IntlProvider } from 'react-intl';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/vendors/style';
import 'react-table/react-table.css';

import MainApp from 'routes/App';
import Login from 'routes/Login';
import RecoverPassword from 'routes/RecoverPassword';
import SignUp from 'routes/SignUp';
import SignUp2 from 'routes/SignUp2';
import SignUpDemo from 'routes/SignUpDemo';
import Error404 from 'components/theme/Error404';
import RTL from 'components/theme/RTL';
import defaultTheme from 'constants/themes/defaultTheme';
import AppLocale from 'lngProvider';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import PrivacyPolicies from 'routes/PrivacyPolicies';
import * as am4core from '@amcharts/amcharts4/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import { connect } from 'react-redux';
import { am4themesKotlerTheme, animated } from '../config';
import 'moment/locale/pt';
import 'moment/locale/en-in';
import 'moment/locale/es';

am4core.useTheme( am4themesKotlerTheme );
am4core.useTheme( animated );

class App extends Component {
  componentDidMount() {}
  componentDidUpdate( prevProps ) {
    const { alert, toggleAlert } = this.props;

    if ( alert && alert.msg && alert.date !== _.get( prevProps, 'alert.date' ) ) {
      switch ( alert.alertType ) {
      case 'info':
        return toast.error(
          <span className="d-flex align-items-center">
            <FeatherIcon
              className="mr-2"
              icon="checkCircle"
              size="35px"
              color="primary.main"
            />
            <IntlMessages id={alert.msg} />
          </span>,
          {
            icon: false,
            progressStyle: {
              height: '2px',
              backgroundColor: defaultTheme.palette.primary.main,
            },
            bodyStyle: { padding: 0 },
            style: { fontSize: '12px', fontWeight: 500 },
            onClose: () => {
              toggleAlert( null );
            },
            autoClose: 6000,
          },
        );
      case 'tips':
        return toast.info( alert.msg.content, alert.msg.title, 15000, () => {
          toggleAlert( null );
        } );

      default:
        return toast.error(
          <span className="d-flex align-items-center">
            <FeatherIcon
              className="mr-2"
              icon="xCircle"
              size="35px"
              color="error.main"
            />
            {alert.translate ? <IntlMessages id={alert.msg} /> : alert.msg}
          </span>,
          {
            icon: false,
            progressStyle: {
              height: '2px',
              backgroundColor: defaultTheme.palette.error.main,
            },
            bodyStyle: { padding: 0 },
            style: { fontSize: '12px', fontWeight: 500 },
            onClose: () => {
              toggleAlert( null );
            },
            autoClose: 6000,
          },
        );
      }
    }
  }

  render() {
    const {
      user,
      isLoading,
      location,
      locale,
      isDirectionRTL,
      alertNoSessionOpened,
      closeNoSessionAlert,
    } = this.props;
    if (
      ( !user || !user.access_token )
      && ( location.pathname !== '/login'
        && location.pathname !== '/forgot-password'
        && location.pathname !== '/privacy'
        && !_.startsWith( location.pathname, '/recover-password' )
        && !_.startsWith( location.pathname, '/sign-up' ) )
    ) {
      return <Redirect to="/login" />;
    }

    const applyTheme = defaultTheme;

    if ( isDirectionRTL ) {
      applyTheme.direction = 'rtl';
      document.body.classList.add( 'rtl' );
    } else {
      document.body.classList.remove( 'rtl' );
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path="/privacy" component={PrivacyPolicies} />
                  <Route path="/login" component={Login} />
                  <Route
                    path="/recover-password/:token"
                    component={RecoverPassword}
                  />

                  <Route path="/sign-up/:token" component={SignUp} />
                  <Route path="/sign-up2/:token" component={SignUp2} />
                  <Route path="/sign-up-demo/:token" component={SignUpDemo} />
                  <Route path="/" component={MainApp} />
                  <Route component={Error404} />
                </Switch>

                {isLoading && (
                  <div className="app-loading">
                    <CircularProgress size={50} thickness={5} />
                  </div>
                )}

                <ToastContainer />

                <SweetAlert
                  show={!!alertNoSessionOpened}
                  warning
                  confirmBtnText={<IntlMessages id="reload" />}
                  confirmBtnBsStyle="danger"
                  title={<IntlMessages id="noSessionReload" />}
                  onConfirm={() => {
                    closeNoSessionAlert();
                    if ( _.get( user, 'activeSession.id' ) ) {
                      window.location.href = `/sessions/${user.activeSession.id}`;
                    } else {
                      window.location.reload();
                    }
                  }}
                  onCancel={() => {}}
                />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ( { settings, user } ) => {
  const {
    sideNavColor,
    locale,
    isDirectionRTL,
    isLoading,
    alert,
    alertNoSessionOpened,
  } = settings;
  return {
    sideNavColor,
    locale,
    isDirectionRTL,
    isLoading,
    alert,
    user,
    alertNoSessionOpened,
  };
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  closeNoSessionAlert: SettingsActions.closeNoSessionAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( App );
