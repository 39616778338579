import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Error404 from 'components/theme/Error404';
import Sidebar from 'components/custom_v2/SideNav';
import SettingsActions from 'store/reducers/Settings';
import Production from 'routes/App/routes/User/Session/General/Production';
import Target from 'routes/App/routes/User/Session/General/Target';
import Simulation from 'routes/App/routes/User/Session/General/Simulation';
import Bundle from 'routes/App/routes/User/Session/General/Bundle';
import Pop from 'routes/App/routes/User/Session/General/Pop';
import SessionInfo from 'routes/App/routes/User/Session/General/SessionInfo';
import PublicMarket from 'routes/App/routes/User/Session/General/PublicMarket';
import MarketReport from 'routes/App/routes/User/Session/General/MarketReport';
import { Container } from '@material-ui/core';
import NewProduct from './Products/New';
import CopyProduct from './Products/Copy';
import Products from './Products/Dashboard';
import Price from './Price';
import Distribution from './Distribution';
import Service from './Service/Dashboard';
import Cem from './Cem';
import Crm from './Crm';
import OneToOneCampaign from './OneToOneCampaign';
import Strategy from './Strategy';
import NewBundle from './Service/NewBundle';

const General = ( { navCollapsed, toggleCollapsedNav, match, user } ) => {
  let mkmode = {};
  if ( user.activeSession.distributionType === 'studio' ) {
    mkmode = { ...user.activeSession.studioFields };
  } else {
    mkmode = { ...user.activeSession.enterpriseFields };
  }
  let menus = [
    {
      icon: 'view-module',
      name: 'products',
      color: 'primary',
      url: `${match.url}/products`,
    },
    {
      icon: 'view-list-alt',
      name: 'services',
      color: 'warning',
      url: `${match.url}/services`,
    },
    /* {
      icon: 'view-quilt',
      name: 'production',
      color: 'danger',
      url: `${match.url}/production`,
    },
    {
      icon: 'ruler',
      name: 'bundleManagement',
      color: 'purple',
      url: `${match.url}/bundles`,
    }, */
    {
      icon: 'view-day',
      name: 'prices',
      color: 'info',
      url: `${match.url}/price`,
    },
    {
      icon: 'money-box',
      name: 'distribution',
      color: 'dark',
      url: `${match.url}/distribution`,
    },
    {
      icon: 'sort-amount-asc',
      name: 'cem',
      color: 'lime',
      url: `${match.url}/cem`,
    },
    {
      icon: 'widgets',
      name: 'communication',
      color: 'sepia',
      url: `${match.url}/crm`,
    },
    {
      icon: 'accounts-outline',
      name: '1to1CommCrm',
      color: 'danger',
      url: `${match.url}/one-to-one`,
    },
    {
      icon: 'bookmark-outline',
      name: 'strategy',
      color: 'danger',
      url: `${match.url}/strategy`,
    },
  ];

  if ( mkmode && mkmode.decision && !mkmode.decision.services ) {
    menus = _.filter( menus, o => o.name.indexOf( 'services' ) === -1 );
  }

  if ( mkmode && mkmode.decision && !mkmode.decision.cx ) {
    menus = _.filter( menus, o => o.name.indexOf( 'cem' ) === -1 );
  }

  return (
    <>
      <Sidebar
        menus={menus}
        open={navCollapsed}
        onToggle={toggleCollapsedNav}
      />
      <Container className="mt-3" maxWidth="lg">
        <Switch>
          <Route
            path={`${match.url}/products/:id/new`}
            component={NewProduct}
          />
          <Route
            path={`${match.url}/products/copy`}
            component={CopyProduct}
          />
          <Route
            path={`${match.url}/products`}
            component={Products}
          />
          <Route
            path={`${match.url}/price/:id/new`}
            component={NewProduct}
          />
          <Route
            path={`${match.url}/price`}
            component={Price}
          />
          <Route
            path={`${match.url}/distribution/:id/new`}
            component={NewProduct}
          />
          <Route
            path={`${match.url}/distribution`}
            component={Distribution}
          />
          <Route
            path={`${match.url}/services/new-bundle`}
            component={NewBundle}
          />
          <Route
            path={`${match.url}/services`}
            component={Service}
          />
          <Route
            path={`${match.url}/one-to-one`}
            component={OneToOneCampaign}
          />
          <Route
            path={`${match.url}/strategy`}
            component={Strategy}
          />
          <Route
            path={`${match.url}/production`}
            component={Production}
          />
          <Route
            path={`${match.url}/pop`}
            component={Pop}
          />
          <Route
            path={`${match.url}/bundles`}
            component={Bundle}
          />
          <Route
            path={`${match.url}/crm`}
            component={Crm}
          />
          {/*   <Route
            path={`${match.url}/service-param/new`}
            component={ServiceParamNew}
          />
          <Route
            path={`${match.url}/service-param`}
            component={ServiceParam}
          /> */}
          <Route
            path={`${match.url}/cem`}
            component={Cem}
          />
          <Route
            path={`${match.url}/target`}
            component={Target}
          />
          <Route
            path={`${match.url}/simulation`}
            component={Simulation}
          />
          <Route
            path={`${match.url}/session-info`}
            component={SessionInfo}
          />
          <Route
            path={`${match.url}/public-market`}
            component={PublicMarket}
          />
          <Route
            path={`${match.url}/market-report`}
            component={MarketReport}
          />
          <Route
            path={`${match.url}/strategy`}
            component={Strategy}
          />
          <Route
            path={match.url}
            component={Products}
            exact
          />
          <Route component={Error404} />
        </Switch>
      </Container>
    </>
  );
};

const mapStateToProps = ( { settings, user } ) => (
  { navCollapsed: settings.navCollapsed, user }
);

const mapDispatchToProps = {
  toggleCollapsedNav:
  SettingsActions.toggleCollapsedNav,
};

export default connect( mapStateToProps, mapDispatchToProps )( General );
