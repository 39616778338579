import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PieGraphFull from 'components/StatisticalGraphs/containers/PieGraphFull';
import { findIndex } from 'lodash';

const formatData = ( data, key = 'unitSales' ) => {
  if ( !data ) return {};
  const dataFormated = [];
  data.map( ( { workgroupData } ) => workgroupData.map( ( { name: group, services } ) => {
    const exist = findIndex( dataFormated, { group } );

    const value = services.reduce( ( prev, serv ) => prev + serv[key], 0 );
    if ( exist === -1 ) {
      return dataFormated.push( {
        group,
        value,
      } );
    }
    dataFormated[exist].value += value;
    return value;
  } ) );
  return { data: dataFormated };
};

const PieGraphLastRoundServices = ( {
  gId = 1,
  graphHeight = '315px',
  title = 'market.services_information',
  hoverTitle,
  data,
  loading,
  error,
  children,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );

  useEffect( () => {
    const formatedData = formatData( data, 'unitSales' );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatedData ) ) {
      setDataToGraph( formatedData );
    }
  }, [data, dataToGraph] );

  return (
    <PieGraphFull
      {...{
        title,
        loading,
        error,
        hoverTitle,
        children,
      }}
      height={graphHeight}
      htmlId={`pie_chart_lastround_services_${gId}`}
      data={dataToGraph.data}
    />
  );
};

const mapStateToProps = ( { services: { lastRoundServices: { data, loading, error } } } ) => ( {
  data,
  loading,
  error,
} );

export default connect( mapStateToProps )( PieGraphLastRoundServices );
