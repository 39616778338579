import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';
import Badge from '@material-ui/core/Badge';
import { IconButton, Typography } from '@material-ui/core';


const IconCard = ({ icon, title, bgColor, link, onClick, withBadge = false, subtitle,image }) => (
  <div className='d-flex justify-content-center'>
    <IconButton component={Link} to={link} className=" m-1 text-decoration-none square-icon-card-button" onClick={onClick}>

      <div className="ratio ratio-1x1 icon-card  ">
        {withBadge &&
          <Badge badgeContent=" " color="error">
            <div className='container-icon-card' >
              {/*     <FeatherIcon icon={icon} size="20px" color="primary2.light"/> */}
              <i className={`fa fa-${icon} fa-3x ${subtitle ? "icon-card-i-primary" : ""} ${title === 'info.analytics' ? 'text-app' : 'text-app'}`} />
              <Typography variant='h6' className="icon-card-text mt-2" ><IntlMessages id={title} /></Typography>
              {subtitle && <Typography variant='h6' className="icon-card-subtext" ><IntlMessages id={subtitle} /></Typography>}
            </div>
          </Badge>
        }
        {!withBadge &&
          <div className='container-icon-card' >
            {/*     <FeatherIcon icon={icon} size="20px" color="primary2.light"/> */}
            
            {icon && <i className={`fa fa-${icon} fa-3x ${subtitle ? "icon-card-i-primary" : ""} ${title === 'info.analytics' ? 'text-app' : 'text-app'}`} /> }
            {image}
            <Typography variant='h6' className="icon-card-text mt-2" ><IntlMessages id={title} /></Typography>
            {subtitle && <Typography variant='h6' className="icon-card-subtext" ><IntlMessages id={subtitle} /></Typography>}
          </div>
        }
      </div>



    </IconButton>
  </div>
);

export default IconCard;
