export const FETCH_LAST_ROUND_SERVICES_REQUEST = 'FETCH_LAST_ROUND_SERVICES_REQUEST';
export const FETCH_LAST_ROUND_SERVICES_SUCCESS = 'FETCH_LAST_ROUND_SERVICES_SUCCESS';
export const FETCH_LAST_ROUND_SERVICES_FAILURE = 'FETCH_LAST_ROUND_SERVICES_FAILURE';
export const CLEAR_LAST_ROUND_SERVICES = 'CLEAR_LAST_ROUND_SERVICES';

export const fetchLastRoundServicesRequest = (
  sessionId,
  workgroupId,
  params,
) => ( {
  type: FETCH_LAST_ROUND_SERVICES_REQUEST,
  payload: { sessionId, workgroupId, params },
} );

export const fetchLastRoundServicesSuccess = data => ( {
  type: FETCH_LAST_ROUND_SERVICES_SUCCESS,
  payload: data,
} );

export const fetchLastRoundServicesFailure = error => ( {
  type: FETCH_LAST_ROUND_SERVICES_FAILURE,
  payload: error,
} );

export const clearLastRoundServices = () => ( { type: CLEAR_LAST_ROUND_SERVICES } );
