export const FETCH_ALL_ROUNDS_SERVICES_REQUEST = 'FETCH_ALL_ROUNDS_SERVICES_REQUEST';
export const FETCH_ALL_ROUNDS_SERVICES_SUCCESS = 'FETCH_ALL_ROUNDS_SERVICES_SUCCESS';
export const FETCH_ALL_ROUNDS_SERVICES_FAILURE = 'FETCH_ALL_ROUNDS_SERVICES_FAILURE';
export const CLEAR_ALL_ROUNDS_SERVICES = 'CLEAR_ALL_ROUNDS_SERVICES';

export const fetchAllRoundsServicesRequest = (
  sessionId,
  params,
) => ( {
  type: FETCH_ALL_ROUNDS_SERVICES_REQUEST,
  payload: { sessionId, params },
} );

export const fetchAllRoundsServicesSuccess = data => ( {
  type: FETCH_ALL_ROUNDS_SERVICES_SUCCESS,
  payload: data,
} );

export const fetchAllRoundsServicesFailure = error => ( {
  type: FETCH_ALL_ROUNDS_SERVICES_FAILURE,
  payload: error,
} );

export const clearAllRoundsServices = () => ( { type: CLEAR_ALL_ROUNDS_SERVICES } );
