import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import UtilService from 'utils/Util';
import UserActions from 'store/reducers/User';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FeatherIcon from 'components/custom/FeatherIcon';
import IntlMessages from 'utils/IntlMessages';

import './session-select.scss';

const SessionSelect = ( { user } ) => {
  const [open, setOpen] = useState( false );
  const [write, setWrite] = useState( false );
  const [search, setSearch] = useState( '' );
  const { id: userId, activeSession = {} } = user || {};
  const sessions = useMemo(
    () => _.map( UtilService.getUserSessions( user ) ).filter( session => !!session ),

    [user, userId, activeSession.id] //eslint-disable-line
  );
  const sessionsFiltered = useMemo( () => {
    const searchLower = search.trim().toLowerCase();
    return sessions.filter(
      session => session.name.toLowerCase().includes( searchLower )
        || session.entity.name.toLowerCase().includes( searchLower )
        || session.id.toLowerCase() === searchLower,
    );
  }, [sessions, search] );

  if ( !sessions || sessions.length <= 0 ) return null;

  return (
    <Dropdown
      className={`quick-menu app-drop-down-session  ${open ? 'open' : ''}`}
      isOpen={open}
      toggle={() => {
        if ( write && open ) return;
        setOpen( !open );
        setSearch( '' );
      }}
    >
      <DropdownToggle
        className=" session-toggle "
        tag="span"
        data-toggle="dropdown"
      >
        {
          <input
            className="session-input"
            style={{
              width: `${( user.activeSession.name.length * 10 * 0.61 ).toFixed(
                2,
              )}px`,
              minWidth: '70px',
            }}
            defaultValue={user.activeSession.name}
            value={write ? search : user.activeSession.name}
            onChange={e => setSearch( e.target.value )}
            onBlur={() => {
              setWrite( false );
            }}
            onFocus={() => {
              setWrite( true );
              setSearch( '' );
            }}
          />
        }

        <FeatherIcon
          size="16px"
          color="secondary.dark"
          icon="ChevronDown"
          className={`  ${open ? 'rotate' : ''}`}
        />
      </DropdownToggle>

      <DropdownMenu className="drop-down-menu">
        <List className="list-content">
          {sessionsFiltered.length ? (
            _.map(
              sessionsFiltered,
              session => session && (
                <SessionItem
                  key={session.id}
                  session={session}
                  setOpenToggle={( value ) => {
                    setOpen( value );
                  }}
                />
              ),
            )
          ) : (
            <div
              className="text-primary text-center d-flex justify-content-center align-items-center"
              style={{ height: '30px' }}
            >
              <IntlMessages id="NoDataFound" />
            </div>
          )}
        </List>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = { changeActiveSession: UserActions.changeActiveSession };
const SessionItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)( ( { session, user, changeActiveSession, setOpenToggle } ) => (
  <Link
    key={session.id}
    to="/"
    className="text-decoration-none"
    onClick={() => {
      if ( session.id === user.activeSession.id ) return;

      changeActiveSession( session );
      setOpenToggle( false );
      // this.onSessionsSelect();
    }}
  >
    <ListItem
      className={`list-item-button  ${
        session.id === user.activeSession.id ? 'bg-white user-select-none' : ''
      }`}
      button
    >
      <i className="zmdi zmdi-account zmdi-hc-lg text-primary mr-2" />
      <ListItemText
        className={`list-item-text px-1  ${
          session.id === user.activeSession.id ? 'text-primary2' : ''
        }`}
        primary={`${session.entity.name} - ${session.name}`}
      />
    </ListItem>
    <Divider color="secondary.dark" />
  </Link>
) );

export default connect( mapStateToProps )( SessionSelect );
