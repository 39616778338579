import { takeEvery, put, call } from 'redux-saga/effects';
import { getLastRoundServices } from 'modules/Services/ServicesService';
import {
  FETCH_LAST_ROUND_SERVICES_REQUEST,
  fetchLastRoundServicesFailure,
  fetchLastRoundServicesSuccess,
} from '../actions/LastRoundServicesActions';

function* fetchLastRoundServicesSaga( action ) {
  const { sessionId, workgroupId, params = { limit: -1 } } = action.payload;
  try {
    const lastRoundServicesData = yield call(
      getLastRoundServices,
      sessionId,
      workgroupId,
      params,
    );
    yield put( fetchLastRoundServicesSuccess( lastRoundServicesData ) );
  } catch ( error ) {
    yield put( fetchLastRoundServicesFailure( error ) );
  }
}

export default function* lastRoundServicesRootSaga() {
  yield takeEvery(
    FETCH_LAST_ROUND_SERVICES_REQUEST,
    fetchLastRoundServicesSaga,
  );
}
