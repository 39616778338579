import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';

const formatDataToTable = ( data ) => {
  if ( !data ) return {};

  const dataFormated = [];
  const groups = [];
  // eslint-disable-next-line
  data.map(({ workgroupData, name, id: key }, index) => {
    const prices = workgroupData.map( ( { name: group, services } ) => {
      if ( index === 0 ) groups.push( group );

      return services.reduce(
        ( prev, { price, active } ) => ( active ? Util.formatCurrency( price ) : prev ),
        '-',
      );
    } );

    return dataFormated.push( {
      key,
      name,
      prices,
    } );
  } );

  return {
    groups,
    data: dataFormated,
  };
};

const TablesLastRoundService = ( {
  data,
  //   loading,
  //   error,
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  const [groups, setGroups] = useState( [] );
  useEffect( () => {
    const formatData = formatDataToTable( data );
    setDataToTable( formatData.data );
    setGroups( formatData.groups );
  }, [data] );

  return (
    <CardBox
      styleName="px-2 h-100 mt-3"
      header={
        <KotlerCardHeader minHeight="0px" title="market.services_information" />
      }
    >
      <Table className="kotler-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                <IntlMessages id="service" />
              </strong>
            </TableCell>
            {dataToTable
              && map( groups, name => (
                <TableCell key={name} align="center">
                  <strong>{name}</strong>
                </TableCell>
              ) )}
          </TableRow>
        </TableHead>
        <TableBody>
          {map( dataToTable, ( { key, name, prices } ) => (
            <TableRow key={key}>
              <TableCell>{name}</TableCell>
              {prices
                && prices.map( ( price, index ) => (
                  <TableCell align="center" key={`price${index + price}`}>
                    {price}
                  </TableCell>
                ) )}
            </TableRow>
          ) )}
          {( !dataToTable || !dataToTable.length ) && (
            <TableRow>
              <TableCell colSpan={1}>
                <IntlMessages id="NoDataFound" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CardBox>
  );
};

const mapStateToProps = ( { services: { lastRoundServices: { data, loading, error } } } ) => ( {
  data,
  loading,
  error,
} );

export default connect( mapStateToProps )( TablesLastRoundService );
