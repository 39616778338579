import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
// import Button from '@material-ui/core/Button';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TextField } from 'components/custom/FormElements';
import ProductionService from 'services/Production';
import DistributionService from 'services/Distribution';
import {
  composeValidators,
  greaterOrEqualValue,
  lessOrEqualThanValue,
  required,
} from 'config/InputErrors';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/distribution.jpeg';
import SectionInfoText from 'components/custom/SectionInfoText';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import { Prompt } from 'react-router';
import ConfirmCancelButtons from 'components/custom/ConfirmCancelButtons';
import KotlerProductIcon from 'components/custom/KotlerProductIcon';
import { STATUS } from 'modules/sessions';

class Distribution extends React.Component {
  state = {
    categories: [],
    confirmSaveModal: false,
    confirmExitModal: false,
  };
  fieldModified = false;
  canExit = false;
  goTo = '/';
  confirmLessFivePercentService = [];
  confirmLessFivePercentProduct = [];
  confirmLessFivePercentBundle = [];
  boolConfirmLessFivePercentService = false;
  boolConfirmLessFivePercentProduct = false;
  boolConfirmLessFivePercentBundle = false;

  componentDidMount() {
    const { toggleAlert, user } = this.props;

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields, studio: true };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields, studio: false };
    }
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  componentWillUnmount() {
    this.fieldModified = false;
    document.removeEventListener( 'keydown', this.handleKeyPress );
  }

  handleKeyPress = ( event ) => {
    const { user } = this.props;
    const { confirmExitModal } = this.state;
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if (
          user
          && user.activeSession
          && user.activeSession.status !== STATUS.READY
        ) return;

        if ( !confirmExitModal ) {
          this.setState( { confirmSaveModal: true } );
        }
      }
    }
  };

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    async.parallel(
      {
        categories: ( cb ) => {
          CategoryService.getCategories( { 'filters[session]': this.activeSession.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        products: ( cb ) => {
          DistributionService.getProducts( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        services: ( cb ) => {
          DistributionService.getServices( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        bundles: ( cb ) => {
          DistributionService.getBundles( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) toggleAlert( error );
        const { services } = results;
        const { bundles } = results;
        let categories = _.map( results.categories, category => ( {
          ...category,
          products: _.filter( results.products, { category: { id: category.id } } ),
        } ) );
        if ( !this.mkmode.decision.price.laptop ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Lapto' ) === -1,
          );
        }
        if ( !this.mkmode.decision.price.tablet ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Tablet' ) === -1,
          );
        }
        if ( !this.mkmode.decision.price.celular ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Celular' ) === -1,
          );
        }

        this.setState( { categories, services, bundles } );
      },
    );
  };

  submitForm = ( formData, FormOptions ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { dirty, dirtyFields } = FormOptions.getState();
    const distributions = [];
    this.fieldModified = false;
    _.map( formData.categories, ( category ) => {
      _.map( category.products, ( product ) => {
        const data = {
          product: product.id,
          tradeSalePrice: product.tradeSalePrice,
          pop: product.pop,
        };
        distributions.push( data );
      } );
    } );

    const ServiceDistributions = [];

    _.map( formData.services, ( servicex ) => {
      const data = {
        service: servicex.id,
        tradeSalePrice: servicex.tradeSalePrice,
        pop: servicex.pop,
      };
      ServiceDistributions.push( data );
    } );

    const BundleDistributions = [];

    _.map( formData.bundles, ( bundlex ) => {
      const data = {
        bundle: bundlex.id,
        tradeSalePrice: bundlex.monthlyTradeSaleFee,
        pop: bundlex.pop,
        monthlyTradeSaleFee: bundlex.monthlyTradeSaleFee,
      };
      BundleDistributions.push( data );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      distributions,
    };

    const dataServiceToSend = {
      workgroup: this.license.workGroup.id,
      distributions: ServiceDistributions,
    };

    const dataBundleToSend = {
      workgroup: this.license.workGroup.id,
      distributions: BundleDistributions,
    };
    async.series(
      [
        ( cb ) => {
          if ( distributions.length !== 0 ) {
            if ( dirty && dirtyFields.categories ) {
              // eslint-disable-next-line max-len
              ProductionService.updateProductionDistributionV2(
                this.activeSession.id,
                dataToSend,
              ).then( ( response ) => {
                if ( !response.ok ) cb( null, response.errors );
                else cb( null, 'DataSaved' );
              } );
            } else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
        ( cb ) => {
          if ( ServiceDistributions.length !== 0 ) {
            if ( dirty && dirtyFields.services ) {
              // eslint-disable-next-line max-len
              ProductionService.updateServiceDistributionV2(
                this.activeSession.id,
                dataServiceToSend,
              ).then( ( response ) => {
                toggleLoading( false );
                if ( !response.ok ) cb( null, response.errors );
                else cb( null, 'DataSaved' );
              } );
            } else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
        ( cb ) => {
          if ( BundleDistributions.length !== 0 ) {
            if ( dirty && dirtyFields.bundles ) {
              // eslint-disable-next-line max-len
              ProductionService.updateBundleDistributionV2(
                this.activeSession.id,
                dataBundleToSend,
              ).then( ( response ) => {
                if ( !response.ok ) cb( null, response.errors );
                else cb( null, 'DataSaved' );
              } );
            } else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
      ],
      ( error, result ) => {
        toggleLoading( false );
        _.map( result, ( item, index ) => {
          const message = index === 0
            ? `productDistributions${item}`
            : index === 1
              ? `services${item}`
              : `bundles${item}`;
          switch ( item ) {
          case 'DataSaved':
            toggleAlert( message, 'info' );
            break;
          case 'NoChangesForSave':
            toggleAlert( message, 'info' );
            break;
          case 'NoDataToSend':
            break;
          default:
            toggleAlert( message, 'error' );
          }
        } );
        if (
          result[0] === 'DataSaved'
          || result[1] === 'DataSaved'
          || result[2] === 'DataSaved'
        ) {
          this.getData();
          this.fieldModified = false;
        }
      },
    );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo === '' ) {
      this.fieldModified = false;
    } else {
      history.push( this.goTo );
    }
  };

  calcPorcMargin = ( priceProduct, tradeSalePrice, field, fieldType ) => {
    if (
      _.isNaN( priceProduct )
      || _.isNaN( tradeSalePrice )
      || tradeSalePrice === 0
    ) return 0;
    const porcMargin = ( ( priceProduct - tradeSalePrice ) / priceProduct ) * 100;
    if ( fieldType === 'product' ) {
      this.confirmLessFivePercentProduct[field] = porcMargin < 5 ? 1 : 0;
      // eslint-disable-next-line max-len
      this.boolConfirmLessFivePercentProduct = _.sum( _.values( this.confirmLessFivePercentProduct ) ) === 0;
    }
    if ( fieldType === 'service' ) {
      this.confirmLessFivePercentService[field] = porcMargin < 5 ? 1 : 0;
      // eslint-disable-next-line max-len
      this.boolConfirmLessFivePercentService = _.sum( _.values( this.confirmLessFivePercentService ) ) === 0;
    }
    if ( fieldType === 'bundle' ) {
      this.confirmLessFivePercentBundle[field] = porcMargin < 5 ? 1 : 0;
      // eslint-disable-next-line max-len
      this.boolConfirmLessFivePercentBundle = _.sum( _.values( this.confirmLessFivePercentBundle ) ) === 0;
    }

    return porcMargin;
  };
  setChangeStatus = () => {
    this.fieldModified = true;
  };
  render() {
    const {
      categories,
      confirmSaveModal,
      services,
      bundles,
      confirmExitModal,
    } = this.state;
    const { user } = this.props;
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields, studio: true };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields, studio: false };
    }
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-distribution' },
          ]}
        />
        <KotlerSectionHeader
          title="distribution"
          image={HeaderImage}
          titleSubmit="save"
          titleCancel="cancelBtn"
          cancelTo="/"
          onSubmit={() => this.toggleConfirmSaveModal()}
        />
        <SectionInfoText title="DistributionHelpText" />
        <Form
          initialValues={{ categories, services, bundles }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, dirty } ) => (
            <form onSubmit={handleSubmit}>
              <Prompt
                when={!this.canExit}
                message={( location ) => {
                  const { canExit } = this;
                  if ( dirty && !canExit ) {
                    this.goTo = location.pathname;
                    this.toggleConfirmExitModal();
                    return false;
                  }
                  return true;
                }}
              />
              <FieldArray name="categories">
                {data => data.fields.map( ( itemField, itemIndex ) => {
                  const item = data.fields.value[itemIndex];
                  return (
                    <Grid
                      container
                      wrap="nowrap"
                      className="kotler-table-container"
                      key={item.id}
                    >
                      <Grid item>
                        <KotlerProductIcon
                          minHeight="123px"
                          img={item.name === 'Laptops' ? 'Laptop' : item.name}
                        />
                      </Grid>
                      <Grid item className="flex-grow-1">
                        <Grid container key={item.id}>
                          <Grid item xs={12}>
                            <KotlerTableTitle
                              disableIcon
                              titleClassName="ml-3"
                              category={item}
                              title={
                                item.name === 'Laptops' ? 'Laptop' : item.name
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Table size="small" className="kotler-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: '12%' }}>
                                    <IntlMessages id="model" />
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '8%' }}
                                  >
                                    <HoverTitle id="product.unitCostValue.hover">
                                      <span>
                                        <IntlMessages id="unitCost" />
                                        {' ($)'}
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '10%' }}
                                  >
                                    <HoverTitle id="product.priceValue.hover">
                                      <span>
                                        <IntlMessages id="price" />
                                        {' ($)'}
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ width: '11%' }}
                                  >
                                    <HoverTitle id="priceProductToRetailer">
                                      <span>
                                        <IntlMessages id="retailerPrice" />
                                        {' ($)'}
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '11%' }}
                                  >
                                    <HoverTitle id="marginIn$RetailerSaleProduct">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="marginToRetailerS" />
                                        {' ($)'}
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '11%' }}
                                  >
                                    <HoverTitle id="marginIn%RetailerSaleProduct">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="marginToRetailerPercent" />
                                        {' (%)'}
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '12%' }}
                                  >
                                    <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="directSales" />
                                        <br />

                                        <IntlMessages id="lastMonth" />
                                        {' (%)'}
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '14%' }}
                                  >
                                    <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="channelSales" />
                                        <br />

                                        <IntlMessages id="lastMonth" />
                                        {' (%)'}
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  {!this.mkmode.studio && (
                                    <TableCell
                                      align="center"
                                      style={{ width: '11%' }}
                                    >
                                      <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                        <div className="d-inline-block ">
                                          <IntlMessages id="pontPurchase" />
                                          {' '}
                                          <IntlMessages id="investment" />
                                        </div>
                                      </HoverTitle>
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray name={`${itemField}.products`}>
                                  {/* eslint-disable-next-line max-len */}
                                  {productsData => productsData.fields.map(
                                    ( productField, productIndex ) => {
                                      const product = categories[itemIndex].products[
                                        productIndex
                                      ];
                                      if ( !product ) return null;
                                      return [
                                        <TableRow key={product.id}>
                                          <TableCell>
                                            {product.name}
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="product.unitCostValue.hover">
                                              <span>
                                                {Util.formatCurrency(
                                                  product.unitCost,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="product.priceValue.hover">
                                              <span>
                                                {Util.formatCurrency(
                                                  product.price,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className="py-1"
                                          >
                                            <HoverTitle id="priceProductToRetailer">
                                              <TextField
                                                field={`${productField}.tradeSalePrice`}
                                                marginContainer={false}
                                                type="number"
                                                allowZero
                                                disabled={
                                                  user
                                                      && user.activeSession
                                                      && user.activeSession
                                                        .status !== STATUS.READY
                                                }
                                                inputProps={{ className: 'text-right' }}
                                                validate={composeValidators(
                                                  required,
                                                  greaterOrEqualValue(
                                                    product.price * 0.5,
                                                  ),
                                                )}
                                                translateValues={{ value: product.price * 0.5 }}
                                                onChange={( value ) => {
                                                  const previousValue = _.get(
                                                    productsData.fields,
                                                    `value[${productIndex}].tradeSalePrice`,
                                                  );
                                                  if (
                                                    previousValue !== value
                                                  ) {
                                                    this.setChangeStatus();
                                                  }
                                                }}
                                              />
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn$RetailerSaleProduct">
                                              <span>
                                                {Util.formatCurrency(
                                                  product.price
                                                        - _.get(
                                                          productsData.fields,
                                                          `value[${productIndex}].tradeSalePrice`,
                                                          0,
                                                        ),
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn%RetailerSaleProduct">
                                              <span>
                                                {Util.formatNumber(
                                                  this.calcPorcMargin(
                                                    product.price,
                                                    _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].tradeSalePrice`,
                                                    ),
                                                    product.name,
                                                    'product',
                                                  ),
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                              <span>
                                                {/* eslint-disable-next-line max-len */}
                                                {Util.formatNumberNoDecimals(
                                                  product.percentDirectSales,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                              <span>
                                                {/* eslint-disable-next-line max-len */}
                                                {Util.formatNumberNoDecimals(
                                                  product.percentChannelSales,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          {!this.mkmode.studio && (
                                            <TableCell align="center">
                                              <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                                <TextField
                                                  field={`${productField}.pop`}
                                                  marginContainer={false}
                                                  type="number"
                                                  allowZero
                                                  disabled={
                                                    user
                                                        && user.activeSession
                                                        && user.activeSession
                                                          .status
                                                          !== STATUS.READY
                                                  }
                                                  inputProps={{ className: 'text-right' }}
                                                  validate={composeValidators(
                                                    required,
                                                    greaterOrEqualValue( 0 ),
                                                    lessOrEqualThanValue(
                                                      5000000,
                                                    ),
                                                  )}
                                                  translateValues={{
                                                    value: 0,
                                                    value2: 5000000,
                                                  }}
                                                  onChange={( value ) => {
                                                    const previousValue = _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].pop`,
                                                    );
                                                    if (
                                                      previousValue
                                                          !== value
                                                    ) {
                                                      this.setChangeStatus();
                                                    }
                                                  }}
                                                />
                                              </HoverTitle>
                                            </TableCell>
                                          )}
                                        </TableRow>,
                                      ];
                                    },
                                  )
                                  }
                                </FieldArray>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } )
                }
              </FieldArray>
              {!this.mkmode.studio && (
                <Fragment>
                  <Grid
                    container
                    wrap="nowrap"
                    className="kotler-table-container"
                  >
                    <Grid item>
                      <KotlerProductIcon minHeight="123px" img="Service" />
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12}>
                          <KotlerTableTitle
                            disableIcon
                            titleClassName="ml-3"
                            title="services"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Table className="kotler-table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '12%' }}>
                                  <IntlMessages id="service" />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '8%' }}
                                >
                                  <HoverTitle id="product.unitCostValue.hover">
                                    <span>
                                      <IntlMessages id="unitCost" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '10%' }}
                                >
                                  <HoverTitle id="product.priceValue.hover">
                                    <span>
                                      <IntlMessages id="price" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: '11%' }}
                                >
                                  <HoverTitle id="priceProductToRetailer">
                                    <span>
                                      <IntlMessages id="retailerPrice" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '11%' }}
                                >
                                  <HoverTitle id="marginIn$RetailerSaleProduct">
                                    <div className="d-inline-block ">
                                      <IntlMessages id="marginToRetailerS" />
                                      {' ($)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '11%' }}
                                >
                                  <HoverTitle id="marginIn%RetailerSaleProduct">
                                    <div className="d-inline-block ">
                                      <IntlMessages id="marginToRetailerPercent" />
                                      {' (%)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '12%' }}
                                >
                                  <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                    <div className="d-inline-block">
                                      <IntlMessages id="directSales" />
                                      <br />
                                      <IntlMessages id="lastMonth" />
                                      {' (%)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '14%' }}
                                >
                                  <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                    <div className="d-inline-block ">
                                      <IntlMessages id="channelSales" />
                                      <br />
                                      <IntlMessages id="lastMonth" />
                                      {' (%)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                {!this.mkmode.studio && (
                                  <TableCell
                                    align="center"
                                    style={{ width: '11%' }}
                                  >
                                    <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="pontPurchase" />
                                        <br />
                                        <IntlMessages id="investment" />
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray name="services">
                                {( { fields } ) => fields.map( ( serviceField, productIndex ) => {
                                  const servicex = services[productIndex];
                                  if ( !servicex ) return null;
                                  return [
                                    <TableRow key={servicex.id}>
                                      <TableCell>
                                        <IntlMessages
                                          id={servicex.name.split( ' - ' )[1]}
                                          values={{
                                            code: servicex.name.split(
                                              ' - ',
                                            )[0],
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="product.unitCostValue.hover">
                                          <span>
                                            {Util.formatCurrency(
                                              servicex.unitCost,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="product.priceValue.hover">
                                          <span>
                                            {Util.formatCurrency(
                                              servicex.price,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="py-1"
                                      >
                                        <HoverTitle id="priceProductToRetailer">
                                          <TextField
                                            field={`${serviceField}.tradeSalePrice`}
                                            marginContainer={false}
                                            type="number"
                                            allowZero
                                            disabled={
                                              user
                                                && user.activeSession
                                                && user.activeSession.status
                                                  !== STATUS.READY
                                            }
                                            inputProps={{ className: 'text-right' }}
                                            validate={composeValidators(
                                              required,
                                              greaterOrEqualValue(
                                                servicex.price * 0.5,
                                              ),
                                            )}
                                            translateValues={{ value: servicex.price * 0.5 }}
                                            onChange={( value ) => {
                                              const previousValue = _.get(
                                                fields.fields,
                                                `value[${productIndex}].tradeSalePrice`,
                                              );
                                              if ( previousValue !== value ) {
                                                this.setChangeStatus();
                                              }
                                            }}
                                          />
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="marginIn$RetailerSaleProduct">
                                          <span>
                                            {Util.formatCurrency(
                                              servicex.price
                                                  - _.get(
                                                    fields,
                                                    `value[${productIndex}].tradeSalePrice`,
                                                    0,
                                                  ),
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="marginIn%RetailerSaleProduct">
                                          <span>
                                            {Util.formatNumber(
                                              this.calcPorcMargin(
                                                servicex.price,
                                                _.get(
                                                  fields,
                                                  `value[${productIndex}].tradeSalePrice`,
                                                ),
                                                servicex.name,
                                                'service',
                                              ),
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                          <span>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals(
                                              servicex.percentDirectSales,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                          <span>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals(
                                              servicex.percentChannelSales,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      {!this.mkmode.studio && (
                                        <TableCell align="center">
                                          <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                            <TextField
                                              field={`${serviceField}.pop`}
                                              marginContainer={false}
                                              type="number"
                                              allowZero
                                              disabled={
                                                user
                                                  && user.activeSession
                                                  && user.activeSession.status
                                                    !== STATUS.READY
                                              }
                                              inputProps={{ className: 'text-right' }}
                                              validate={composeValidators(
                                                required,
                                                greaterOrEqualValue( 0 ),
                                                lessOrEqualThanValue( 5000000 ),
                                              )}
                                              translateValues={{
                                                value: 0,
                                                value2: 5000000,
                                              }}
                                              onChange={( value ) => {
                                                const previousValue = _.get(
                                                  fields.fields,
                                                  `value[${productIndex}].pop`,
                                                );
                                                if ( previousValue !== value ) {
                                                  this.setChangeStatus();
                                                }
                                              }}
                                            />
                                          </HoverTitle>
                                        </TableCell>
                                      )}
                                    </TableRow>,
                                  ];
                                } )
                                }
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    wrap="nowrap"
                    className="mb-5 kotler-table-container"
                  >
                    <Grid item>
                      <KotlerProductIcon minHeight="123px" img="Bundle" />
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12}>
                          <KotlerTableTitle
                            disableIcon
                            titleClassName="ml-3"
                            title="bundles"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Table className="kotler-table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '10%' }}>
                                  <IntlMessages id="bundle" />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '10%' }}
                                >
                                  <HoverTitle id="totalCostBundleCompany">
                                    <span>
                                      <IntlMessages id="unitCost" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '9%' }}
                                >
                                  <HoverTitle id="monthlyBundleCostBuyers">
                                    <span>
                                      <IntlMessages id="monthlyFee" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: '9%' }}
                                >
                                  <HoverTitle id="monthlyBundleCostRetailer">
                                    <span>
                                      <IntlMessages id="monthlyTradeSaleFee" />
                                      {' ($)'}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '12%' }}
                                >
                                  <HoverTitle id="marginIn$RetailerSaleProduct">
                                    <span>
                                      <div className="d-inline-block ">
                                        <IntlMessages id="marginToRetailerS" />
                                        {' ($)'}
                                      </div>
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '12%' }}
                                >
                                  <HoverTitle id="marginIn%RetailerSaleProduct">
                                    <span>
                                      <div className="d-inline-block ">
                                        <IntlMessages id="marginToRetailerPercent" />
                                        {' (%)'}
                                      </div>
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '12%' }}
                                >
                                  <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                    <div className="d-inline-block">
                                      <IntlMessages id="directSales" />
                                      <br />
                                      <IntlMessages id="lastMonth" />
                                      {' '}
                                      {' (%)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: '12%' }}
                                >
                                  <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                    <div className="d-inline-block ">
                                      <IntlMessages id="channelSales" />
                                      <br />
                                      <IntlMessages id="lastMonth" />
                                      {' (%)'}
                                    </div>
                                  </HoverTitle>
                                </TableCell>
                                {!this.mkmode.studio && (
                                  <TableCell
                                    align="center"
                                    style={{ width: '10%' }}
                                  >
                                    <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="pontPurchase" />
                                        <br />
                                        <IntlMessages id="investment" />
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray name="bundles">
                                {( { fields } ) => fields.map( ( bundleField, productIndex ) => {
                                  const bundlex = bundles[productIndex];
                                  if ( !bundlex ) return null;
                                  return [
                                    <TableRow key={bundlex.id}>
                                      <TableCell>{bundlex.name}</TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="totalCostBundleCompany">
                                          <span>
                                            {Util.formatCurrency(
                                              bundlex.unitCost,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>

                                      <TableCell align="right">
                                        <HoverTitle id="monthlyBundleCostBuyers">
                                          <span>
                                            {Util.formatCurrency(
                                              bundlex.monthlyFee,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="py-1"
                                      >
                                        <HoverTitle id="monthlyBundleCostRetailer">
                                          <TextField
                                            field={`${bundleField}.monthlyTradeSaleFee`}
                                            marginContainer={false}
                                            type="number"
                                            allowZero
                                            disabled={
                                              user
                                                && user.activeSession
                                                && user.activeSession.status
                                                  !== STATUS.READY
                                            }
                                            inputProps={{ className: 'text-right' }}
                                            validate={composeValidators(
                                              required,
                                              // greaterOrEqualValue( 0 ),
                                              lessOrEqualThanValue(
                                                bundlex.monthlyFee,
                                              ),
                                              greaterOrEqualValue(
                                                bundlex.monthlyFee * 0.5,
                                              ),
                                            )}
                                            translateValues={{
                                              value: bundlex.monthlyFee * 0.5,
                                              value2: bundlex.monthlyFee,
                                            }}
                                            onChange={( value ) => {
                                              const previousValue = _.get(
                                                fields.fields,
                                                `value[${productIndex}].monthlyTradeSaleFee`,
                                              );
                                              if ( previousValue !== value ) {
                                                this.setChangeStatus();
                                              }
                                            }}
                                          />
                                        </HoverTitle>
                                      </TableCell>

                                      <TableCell align="right">
                                        <HoverTitle id="marginIn$RetailerSaleProduct">
                                          <span>
                                            {Util.formatCurrency(
                                              bundlex.price
                                                  - _.get(
                                                    fields,
                                                    `value[${productIndex}].monthlyTradeSaleFee`,
                                                    0,
                                                  ),
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="marginIn%RetailerSaleProduct">
                                          <span>
                                            {Util.formatNumber(
                                              this.calcPorcMargin(
                                                bundlex.price,
                                                _.get(
                                                  fields,
                                                  `value[${productIndex}].monthlyTradeSaleFee`,
                                                ),
                                                bundlex.name,
                                                'bundle',
                                              ),
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="percentageProductSalesMadeDirectlyConsumerLastMonth">
                                          <span>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals(
                                              bundlex.percentDirectSales,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      <TableCell align="right">
                                        <HoverTitle id="percentageProductSalesMadeTrhoughRetailChannelLastMonth">
                                          <span>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals(
                                              bundlex.percentChannelSales,
                                            )}
                                          </span>
                                        </HoverTitle>
                                      </TableCell>
                                      {!this.mkmode.studio && (
                                        <TableCell align="center">
                                          <HoverTitle id="monthlyInvestmentPointSaleAdvertisingProduct">
                                            <TextField
                                              field={`${bundleField}.pop`}
                                              marginContainer={false}
                                              type="number"
                                              allowZero
                                              disabled={
                                                user
                                                  && user.activeSession
                                                  && user.activeSession.status
                                                    !== STATUS.READY
                                              }
                                              inputProps={{ className: 'text-right' }}
                                              validate={composeValidators(
                                                required,
                                                greaterOrEqualValue( 0 ),
                                                lessOrEqualThanValue( 5000000 ),
                                              )}
                                              translateValues={{
                                                value: 0,
                                                value2: 5000000,
                                              }}
                                              onChange={( value ) => {
                                                const previousValue = _.get(
                                                  fields.fields,
                                                  `value[${productIndex}].pop`,
                                                );
                                                if ( previousValue !== value ) {
                                                  this.setChangeStatus();
                                                }
                                              }}
                                            />
                                          </HoverTitle>
                                        </TableCell>
                                      )}
                                    </TableRow>,
                                  ];
                                } )
                                }
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              <ConfirmCancelButtons
                containerClass="mt-3"
                titleSubmit="save"
                titleCancel="cancelBtn"
                cancelTo="/"
                onSubmit={() => this.toggleConfirmSaveModal()}
              />
              <ConfirmationDialog
                open={confirmSaveModal}
                title="confirmSaveProductProduction"
                subTitle={
                  this.boolConfirmLessFivePercentProduct
                    ? null
                    : 'confirmSaveProductAlertWithLessFivePercent'
                }
                confirmBtnText="ok"
                showIcon
                type={
                  !this.boolConfirmLessFivePercentProduct ? 'warning' : 'save'
                }
                closeBtnText="cancelBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          )}
        />

        <ConfirmationDialog
          open={confirmExitModal}
          title="confirmExitWithOutSaving"
          subTitle="subtitleExitWithoutSaving"
          confirmBtnText="yesModalBtn"
          closeBtnText="cancelBtn"
          onSubmit={() => {
            this.toggleConfirmExitModal();
            this.setConfirmExitModal();
          }}
          onClose={this.toggleConfirmExitModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( Distribution ) );
