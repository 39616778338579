import { getHttpRequest } from 'utils/ApiPetitionhandles';

// const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

// get services in array of workgroups  and each one has a historical of operations
export async function getAllRoundServices( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/market/services-all-round?`;
  return getHttpRequest( url, params );
}
// get services of last round of all workgroups inside data
export async function getLastRoundServices( sessionId, workgroupId, params ) {
  const url = `${endpoint2}/${sessionId}/market/services-last-round?workgroup=${workgroupId}`;
  return getHttpRequest( url, params );
}
export default {
  getAllRoundServices,
  getLastRoundServices,
};
