import React from 'react';

import CardBox from 'components/custom_v2/CardBox';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DashboardStudentService from 'services/DashboardStudent';
import ChartModal from 'routes/App/components/User/Chart';
import SessionService from 'services/Session';
import Util from 'utils/Util';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-company.jpeg';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import CompPAndL from './CompPAndL';
import CompCashAndDebt from './CompCashAndDebt';
import CompMarketingBudget from './CompMarketingBudget';
import SettingsActions from '../../../../../../../../store/reducers/Settings';
import TabCompBalance from './TabCompBalance';

class CompanyChart extends React.Component {
  state = {
    CompModalB: false,
    chart1Modal: false,
    chart2Modal: false,
    chart3Modal: false,
  };
  mkmode = {};
  seriesTitle1 = [];
  seriesTitleEarnings = [];
  seriesTitleRevenues = [];
  exportFileName = 'P&L';
  componentDidMount() {
    const { user } = this.props;
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields };
    }
    this.loadComponent();
  }

  componentDidUpdate( nextProps ) {
    const { locale } = this.props;
    if ( nextProps.locale !== locale ) {
      am4core.disposeAllCharts();
      this.loadComponent();
    }
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  toggleCompModal = () => {
    this.setState( previousState => ( { CompModalB: !previousState.CompModalB } ) );
  };

  toggleChart1Modal = () => {
    this.setState( previousState => ( { chart1Modal: !previousState.chart1Modal } ) );
  };

  toggleChart2Modal = () => {
    this.setState( previousState => ( { chart2Modal: !previousState.chart2Modal } ) );
  };

  toggleChart3Modal = () => {
    this.setState( previousState => ( { chart3Modal: !previousState.chart3Modal } ) );
  };

  async loadComponent() {
    const { user, intl, toggleLoading } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    /**
     * Call to endpointstock-value/all-workgroup
     */
    toggleLoading( true );
    await DashboardStudentService.getStockValueAllWorkgroup(
      user.activeSession.id,
    ).then( ( response ) => {
      if ( response.ok ) {
        const roundsX = [];
        const dataWG = [];

        /**
         * Fill dataWG variable WorkGroup for cash and dept component
         */
        _.map( response.data, ( item ) => {
          const dataWGToPush = {
            cash: item.cash,
            name: item.name,
            outstandingDebt: item.outstandingDebt,
            marketingBudget: item.marketingBudget,
          };
          dataWG.push( dataWGToPush );

          /**
           * Fill StockPrices Var
           */
          _.map( item.historical, ( round ) => {
            const exist = _.findIndex( roundsX, { id: round.round.id } );
            if ( exist === -1 ) {
              const shortName = round.round.shortName.split( ' ' );
              const dataToPush = {
                attribute: intl.formatMessage(
                  { id: shortName[0] },
                  { value: shortName[1] },
                ),
                id: round.round.id,
              };
              dataToPush[item.id] = round.stockValue;
              roundsX.push( dataToPush );
            } else {
              roundsX[exist][item.id] = round.stockValue;
            }
          } );
          this.seriesTitle1.push( item.name );
        } );

        this.config3 = {
          data: roundsX,
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'Price' } ) },
            },
          ],
          series: _.map( response.data, itemX => ( {
            type: 'LineSeries',
            dataFields: {
              valueY: itemX.id,
              categoryX: 'attribute',
            },
            name: itemX.name,
            strokeWidth: 2,
          } ) ),
        };
        this.chart3 = am4core.createFromConfig(
          _.cloneDeep( this.config3 ),
          'chartStockdiv',
        );
        this.setState( { dataWG } );
      }
    } );

    // eslint-disable-next-line max-len
    await DashboardStudentService.getPLComparison( user.activeSession.id, { limit: -1 } ).then( ( responsePL ) => {
      if ( responsePL.ok ) {
        this.setState( { data: responsePL.data } );
        const shortName = user.activeSession.currentRound.shortName.split( ' ' );
        const monthRond = intl.formatMessage(
          { id: shortName[0] },
          { value: shortName[1] },
        );
        this.exportFileName = `P&L ${monthRond}`;
      }
    } );
    // eslint-disable-next-line max-len
    await DashboardStudentService.getPLComparison( user.activeSession.id, { limit: -1 } ).then( ( response ) => {
      if ( response.ok ) {
        /**
         * Fill Revenues Variables for config Graph
         */
        const roundsR = [];
        _.map( response.data, ( item ) => {
          _.map( item.historical, ( round ) => {
            const exist = _.findIndex( roundsR, { id: round.round.id } );
            if ( exist === -1 ) {
              const shortName = round.round.shortName.split( ' ' );
              const dataToPush = {
                attribute: intl.formatMessage(
                  { id: shortName[0] },
                  { value: shortName[1] },
                ),
                id: round.round.id,
              };
              dataToPush[item.workgroup.id] = round.revenues;
              roundsR.push( dataToPush );
            } else {
              roundsR[exist][item.workgroup.id] = round.revenues;
            }
          } );
          this.seriesTitleRevenues.push( item.workgroup.name );
        } );

        this.config = {
          data: roundsR,
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'Revenue' } ) },
            },
          ],
          series: _.map( response.data, itemX => ( {
            type: 'LineSeries',
            dataFields: {
              valueY: itemX.workgroup.id,
              categoryX: 'attribute',
            },
            name: itemX.workgroup.name,
            strokeWidth: 2,
          } ) ),
        };

        /**
         * Fill Earnings Variables for Graph
         */
        const roundsE = [];
        _.map( response.data, ( item ) => {
          _.map( item.historical, ( round ) => {
            const exist = _.findIndex( roundsE, { id: round.round.id } );
            if ( exist === -1 ) {
              const shortName = round.round.shortName.split( ' ' );
              const dataToPush = {
                attribute: intl.formatMessage(
                  { id: shortName[0] },
                  { value: shortName[1] },
                ),
                id: round.round.id,
              };
              dataToPush[item.workgroup.id] = round.earnings;
              roundsE.push( dataToPush );
            } else {
              roundsE[exist][item.workgroup.id] = round.earnings;
            }
          } );
          this.seriesTitleEarnings.push( item.workgroup.name );
        } );
        this.chart = am4core.createFromConfig(
          _.cloneDeep( this.config ),
          'chartdiv',
        );

        this.config2 = {
          data: roundsE,
          type: am4charts.XYChart,
          xAxes: [
            {
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
              title: { text: intl.formatMessage( { id: 'Rounds' } ) },
            },
          ],
          yAxes: [
            {
              type: 'ValueAxis',
              title: { text: intl.formatMessage( { id: 'earningsAfterTaxes' } ) },
            },
          ],
          series: _.map( response.data, itemX => ( {
            type: 'LineSeries',
            dataFields: {
              valueY: itemX.workgroup.id,
              categoryX: 'attribute',
            },
            name: itemX.workgroup.name,
            strokeWidth: 2,
          } ) ),
        };
        this.chart2 = am4core.createFromConfig(
          _.cloneDeep( this.config2 ),
          'chartEarningsdiv',
        );
      }
    } );

    await SessionService.getSession( user.activeSession.id ).then( ( response ) => {
      if ( response.ok ) {
        if ( response.data.workGroups ) {
          const stock = _.map( response.data.workGroups, item => ( {
            id: item.id,
            name: item.name,
            points: item.points,
            percentagePoints: item.percentagePoints,
            newStockValue: item.newStockValue,
            position: item.position,
          } ) );

          stock.sort( ( a, b ) => a.position - b.position );

          this.setState( { stock } );
        }
      }
    } );
    toggleLoading( false );
  }

  render() {
    const preProdUrl = 'http'; // Show only when url is preprod
    const {
      chart1Modal,
      chart2Modal,
      chart3Modal,
      stock,
      data,
      dataWG,
    } = this.state;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-company' },
          ]}
        />
        <KotlerSectionHeader title="company" image={HeaderImage} />

        <Grid container style={{ rowGap: '20px' }}>
          <Grid item xs={12} md={8} lg={9}>
            <CardBox
              styleName="px-2 h-100"
              header={
                <KotlerCardHeader title="StockPricesTrend" minHeight="0px" />
              }
              onClick={this.toggleChart3Modal}
            >
              <div
                className="jr-link"
                id="chartStockdiv"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <CardBox
              styleName="px-2 h-100"
              header={(
                <KotlerCardHeader
                  hoverTitleId="stockPrices.hoverHeader"
                  title="StockPrices"
                  minHeight="0px"
                />
              )}
            >
              <Table className="kotler-table-info-details kotler-table-info-md ">
                <TableBody>
                  {_.map( stock, item => (
                    <TableRow key={item.id}>
                      <TableCell
                        className={
                          this.license
                            ? this.license.id === item.id
                              ? 'font-weight-bold text-primary text-nowrap'
                              : 'text-nowrap'
                            : 'text-nowrap'
                        }
                      >
                        {item.name}
                      </TableCell>

                      {window.location.href.indexOf( preProdUrl ) > -1 && (
                        <TableCell
                          className={
                            this.license
                              ? this.license.id === item.id
                                ? 'font-weight-bold text-primary text-nowrap'
                                : 'text-nowrap'
                              : 'text-nowrap'
                          }
                        >
                          {Util.formatCurrency( item.newStockValue )}
                        </TableCell>
                      )}
                      <TableCell
                        className={
                          this.license
                            ? this.license.id === item.id
                              ? 'font-weight-bold text-primary text-nowrap'
                              : 'text-nowrap'
                            : 'text-nowrap'
                        }
                      >
                        {`${item.percentagePoints}%`}
                      </TableCell>
                      {item && item.percentagePoints < 0 && (
                        <TableCell align="right">
                          <i className="fa fa-arrow-down text-danger mx-2" />
                        </TableCell>
                      )}
                      {item && item.percentagePoints > 0 && (
                        <TableCell align="right">
                          <i className="fa fa-arrow-up text-primary mx-2" />
                        </TableCell>
                      )}
                      {item && item.percentagePoints === 0 && (
                        <TableCell align="right">
                          <i className="fa fa-arrow-right text-dark mx-2" />
                        </TableCell>
                      )}
                    </TableRow>
                  ) )}
                </TableBody>
              </Table>
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              styleName="px-2 h-100"
              header={<KotlerCardHeader title="Revenues" />}
              onClick={this.toggleChart1Modal}
            >
              <div
                className="jr-link"
                id="chartdiv"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              styleName="px-2 h-100"
              header={<KotlerCardHeader title="Company.Earnings" />}
              onClick={this.toggleChart2Modal}
            >
              <div
                className="jr-link"
                id="chartEarningsdiv"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <CompCashAndDebt data={dataWG} />

          <CompMarketingBudget data={dataWG} />
          <CompPAndL
            data={data}
            exportFileName={this.exportFileName}
            mkmode={this.mkmode}
          />
          {data && (
            <Grid item xs={12}>
              <TabCompBalance data={data} />
            </Grid>
          )}
        </Grid>

        <ChartModal
          open={chart1Modal}
          title="Revenues"
          onClose={this.toggleChart1Modal}
          seriesTitle={this.seriesTitleRevenues}
          config={this.config}
        />
        <ChartModal
          open={chart2Modal}
          title="Earnings"
          onClose={this.toggleChart2Modal}
          seriesTitle={this.seriesTitleEarnings}
          config={this.config2}
        />
        <ChartModal
          open={chart3Modal}
          title="StockPricesTrend"
          onClose={this.toggleChart3Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config3}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( CompanyChart ) );
