import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getSessions( entityId, params ) {
    return api.get( `${endpoint}/${entityId}/entity`, params );
  },

  getSessionCurrentWorkgroupRound( entityId, params ) {
    return api.get( `${endpoint}/${entityId}/current-workgroupround`, params );
  },

  getMarketingBudgetExecution( workGroupId, roundId, params ) {
    return api.get( `v2/marketing-budget-workgroup/${workGroupId}/round/${roundId}`, params );
  },

  getSession( id ) {
    return api.get( `${endpoint}/${id}` );
  },

  getSession2( id ) {
    return api.get( `${endpoint2}/${id}` );
  },

  getSessionRegistration( id ) {
    return api.get( `${endpoint2}/registration/${id}` );
  },

  saveSession( data ) {
    return api.post( endpoint, data );
  },

  saveSessionV2( data ) {
    return api.post( endpoint2, data );
  },

  saveSessionV2ByStep( data ) {
    return api.post( `${endpoint2}/by-step`, data );
  },

  calculateRounds( data ) {
    return api.post( `${endpoint2}/calculate/rounds`, data );
  },

  cloneList( ) {
    return api.get( `${endpoint2}/clone/list` );
  },

  updateSession( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },

  closeSession( id ) {
    return api.put( `${endpoint2}/${id}/close-session` );
  },

  updateSimulation( id, data ) {
    return api.put( `${endpoint2}/${id}/simulation`, data );
  },

  getPopulationSample( entityId, params ) {
    return api.get( `${endpoint2}/${entityId}/population-sample`, params );
  },

  checkForumAvailability( ) {
    return api.get( 'v2/discussion-forum/verify' );
  },

};
