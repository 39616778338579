// IntlHelpers.js

import { store } from 'MainApp';
import AppLocale from 'lngProvider';
import { IntlProvider } from 'react-intl';

/**
 * Formatea un mensaje internacionalizado utilizando react-intl.
 *
 * @param {Object} attrs - Los atributos necesarios para formatear el mensaje.
 * @param {Object} values - Los valores a insertar en el mensaje en lugar de marcadores de posición.
 * @returns {string} El mensaje internacionalizado formateado.
 */
export const formatMessage = ( attrs, values ) => {
  // Obtiene el estado actual de la tienda de la aplicación.
  const state = store.getState();

  // Obtiene la configuración de idioma actual de AppLocale.
  const currentAppLocale = AppLocale[state.settings.locale.locale];

  // Crea una instancia de IntlProvider con el idioma y mensajes actuales.
  const { intl } = new IntlProvider(
    {
      locale: currentAppLocale.locale,
      messages: currentAppLocale.messages,
    },
    {},
  ).getChildContext();

  // Formatea el mensaje internacionalizado.
  return intl.formatMessage( attrs, values );
};

/**
 * Formatea el nombre de una ronda.
 *
 * @param {string} roundName - El nombre de la ronda a formatear.
 * @returns {string} El nombre de la ronda formateado.
 */
export const formatRoundName = ( roundName ) => {
  // Verifica si el nombre de la ronda o la función formatMessage no están definidos.
  if ( !roundName || !formatMessage ) return '';

  // Divide el nombre de la ronda en palabras.
  const nameArray = roundName.split( ' ' );

  // Si solo hay una palabra, devuelve el nombre original.
  if ( nameArray.length <= 1 ) return roundName;

  // Formatea el nombre de la ronda utilizando formatMessage.
  return formatMessage( { id: nameArray[0] }, { value: nameArray[1] } );
};

// Exporta las funciones formatMessage y formatRoundName.
export default { formatMessage, formatRoundName };
