/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
// import { toast } from 'react-toastify';

import withWidth from '@material-ui/core/withWidth';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import SessionService from 'services/Session';
/* import workingImg from 'assets/images/working.gif'; */
import videoWelcome from 'assets/media/welcome.mp4';
import { setTimeout } from 'core-js';
import PrincipalCarrousel from 'components/custom_v2/PrincipalCarrousel';

// import Footer from 'components/theme/Footer/index';
import { Card, CardHeader, Grid, Hidden } from '@material-ui/core';
import KotlerNotification from 'components/custom/kotlerNotification';
import StudentDashboardOptions from 'components/layouts/StudentDashboardOptions';
import LiveMaxVersion from 'components/layouts/LiveMaxVersion';
import HeadConfig from 'components/custom/HeadConfig';
import { InfoOutlined } from '@material-ui/icons';
import LiveNewsTablet from 'modules/LiveNews/components/LiveNewsTablet';
import WorkgroupStockTicker from 'modules/Workgroups/components/WorkgroupStockTicker';
import ThrophiesModal from 'modules/Notifications/components/ThrophiesModal';
import { STATUS } from 'modules/sessions';
import AdPoisitionerContainer from './components/AdPoisitionerContainer';

const Dashboard = ( {
  user,
  changeActiveSession,
  toggleAlert,
  toggleLoading,
  toggleVideo,
  /*   toggleTip,
  isTip, */
  isVideo,
  locale,
  width,
} ) => {
  const [mkmode, setMkmode] = useState( {} );
  const [workgroup, setWorkgroup] = useState( {} );
  const [data, setData] = useState( {} );
  const [statusRound, setStatusRound] = useState( true );
  const [activeStrategy, setActiveStrategy] = useState( false );
  const [showVideo, setShowVideo] = useState( true );
  const [opa, setOpa] = useState( 100 );
  const [opa2, setOpa2] = useState( 0 );
  const [videoHeight, setvideoHeight] = useState( 600 );
  const adActive = useSelector(
    ( { ads } ) => !!( ads.getLiveAd.ad && ads.getLiveAd.ad.active ),
  );
  let Members = '';
  const CarrouselData = [];
  if ( workgroup.members ) {
    Members = workgroup.members.map( ( item, idx ) => (
      <span key={item.id}>
        {idx > 0 ? ', ' : ''}
        {item.name}
        {'  '}
        {item.lastname}
      </span>
    ) );
  }

  /*
  if ( data.workGroups ) {
    Stock = data.workGroups.map( item => (
      <span key={item.id}>
        {item.name}
        {'  '}
        {Util.formatCurrency( item.points )}
        {'  '}
        {`${item.percentagePoints}%`}
        {'  '}
        {item.percentagePoints && item.percentagePoints < 0 && (
          <span className="text-right"><i className="fa fa-arrow-down text-danger mx-2" /></span>
        )}
        {item.percentagePoints && item.percentagePoints > 0 && (
          <span className="text-right"><i className="fa fa-arrow-up text-primary mx-2" /></span>
        )}
        {item.percentagePoints && item.percentagePoints === 0 && (
          <span className="text-right"><i className="fa fa-arrow-right text-light mx-2" /></span>
        )}
      </span>
    ) );
  }
   */

  useEffect( () => {
    if ( isVideo ) {
      setTimeout( () => {
        setShowVideo( false );
        setOpa( '0' );
        setvideoHeight( '0' );
        setOpa2( 100 );
        toggleVideo( false );
      }, 7000 );
    } else {
      setShowVideo( false );
      setOpa( '0' );
      setvideoHeight( '0' );
      setOpa2( 100 );
      toggleVideo( false );
      toggleLoading( true );
    }

    setStatusRound( true );
    if ( isVideo ) {
      const video = document.getElementsByTagName( 'video' )[0];
      video.muted = false;
    }
    SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id ).then(
      ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      },
    );

    SessionService.getSession( user.activeSession.id ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      changeActiveSession( response.data );
      setData( response.data );
      if ( response.data.distributionType === 'studio' ) {
        setMkmode( { ...response.data.studioFields } );
      } else {
        setMkmode( { ...response.data.enterpriseFields } );
      }
      if ( !user.activeSession ) {
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }
      if ( !user.activeSession.currentRound ) {
        setStatusRound( false );
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      if ( response.data.withStrategies === true ) {
        setActiveStrategy( true );
      } else {
        setActiveStrategy( false );
      }

      const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
      // console.log('license', license );
      if ( !user.activeSession.returnToken && ( !license || !license.workGroup ) ) {
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }

      if ( response.data.status === 'READY_NO_ROUND' ) {
        setStatusRound( false );
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      if ( !response.data.status ) {
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }

      /*  if ( isTip && response.data && response.data.tip ) {
        // eslint-disable-next-line no-shadow
        const { data } = response;
        const title = locale.locale === 'es'
          ? data && data.tip && data.tip.titleEs
          : data && data.tip && data.tip.title;
        const content = locale.locale === 'es'
          ? data && data.tip && data.tip.contentEs
          : data && data.tip && data.tip.content;
        setTimeout( () => {
          toast.success(
            <div>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ color: '#04A89E' }}>
                  <IntlMessages id="didYouKnow" />
                </h1>
              </div>
              <div style={{ marginTop: '30px' }}>
                <h3 style={{ marginTop: '20px' }}>{title}</h3>
                <p>{content}</p>
              </div>
            </div>,
            {
              position: 'top-left',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => toggleTip( false ),
            },
          );
        }, 5000 );
      } */
    } );
  }, [user.activeSession.id]); // eslint-disable-line
  if ( user.activeSession && user.activeSession.tip ) {
    const { activeSession } = user;
    const title = locale.locale === 'es'
      ? activeSession && activeSession.tip && activeSession.tip.titleEs
      : activeSession && activeSession.tip && activeSession.tip.title;
    const content = locale.locale === 'es'
      ? activeSession && activeSession.tip && activeSession.tip.contentEs
      : activeSession && activeSession.tip && activeSession.tip.content;
    CarrouselData.push( { title, content } );
  }

  let logros = [];
  if ( user && user.notifications && user.notifications.data ) {
    logros = user.notifications.data.filter(
      ( { isAchievement } ) => isAchievement,
    );
    user.notifications.data
      .filter( ( { body } ) => body !== 'Logro alcanzado' )
      .filter( ( item, index ) => index < 15 )
      .map( ( item ) => {
        const title = item.text;
        const content = item.body;
        const translate = true;
        return CarrouselData.push( { title, content, translate } );
      } );
  }
  let heightHeaderFill = 50;
  switch ( width ) {
  case 'xs':
    heightHeaderFill = 120;
    break;
  case 'md':
    heightHeaderFill = 70;
    break;
  default:
    break;
  }
  return (
    <>
      <HeadConfig
        breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
        ]}
      />
      <div className="w-100  justify-content-lg-end  d-flex position-absolute  mt-lg-5 pr-lg-5">
        {user
          && user.activeSession
          && user.activeSession.status === STATUS.SESSION_EXPIRED
          && !!logros.length && <ThrophiesModal />}
      </div>
      <div className="w-100 h-100 d-flex flex-column ">
        <div style={{ width: '100%', height: heightHeaderFill }} />

        <div
          className=" w-100 flex-grow-1 d-flex flex-column  "
          style={
            showVideo
              ? {
                overflow: 'hidden',
                width: '100%',
                position: 'absolute',
                height: '100%',
                zIndex: 998,
                backgroundColor: 'white',
                marginRight: '0px',
                marginLeft: '0px',
              }
              : {}
          }
        >
          {( showVideo || !showVideo ) && (
            <div
              style={{ width: '100%', zIndex: 998, backgroundColor: 'white' }}
            >
              <video
                onClick={() => {
                  const video = document.getElementsByTagName( 'video' )[0];
                  video.muted = false;
                }}
                preload="true"
                autoPlay
                muted
                volume="0"
                height="600"
                width="100%"
                className=""
                style={{
                  padding: '0px',
                  border: '0px',
                  position: 'absolute',
                  zIndex: 999,
                  height: videoHeight,
                  borderRadius: '0px',
                  opacity: opa,
                  width: `${opa}%`,
                  transitionDuration: '1s',
                }}
              >
                <source src={videoWelcome} />
              </video>
            </div>
          )}
          {( showVideo || !showVideo ) && statusRound === true && (
            <div
              className="app-dashboard-container app-user-dashboard-container d-flex h-100 flex-grow-1 flex-column position-relative "
              style={{
                opacity: opa2,
                transitionDuration: '3s',
                margin: '5px 0',
                scrollSnapType: 'y mandatory',
              }}
            >
              {user && user.activeSession && (
                <div
                  className="w-100  d-flex justify-content-center"
                  style={{ minHeight: '87vh', scrollSnapAlign: 'start' }}
                >
                  <div className="container-dashboard-options  align-items-center">
                    {/* STUDIO AND ENTERPRISE OPTION COMPONENT  DONT CANT FUNCTION WITHOT GRID */}
                    <StudentDashboardOptions
                      activeStrategy={activeStrategy}
                      mkmode={mkmode}
                    />
                    {CarrouselData.length > 0 && (
                      <Hidden smDown>
                        <div
                          className="dashboard-phone-container"
                          style={{ display: showVideo ? 'none' : 'flex' }}
                        >
                          <KotlerNotification>
                            <PrincipalCarrousel data={CarrouselData} />
                          </KotlerNotification>
                        </div>
                      </Hidden>
                    )}
                  </div>
                </div>
              )}
              {!!(
                user
                && user.activeSession
                && user.activeSession.distributionType === 'enterprise'
                && !user.activeSession.withTestRound
              ) && (
                <Hidden implementation="js" mdDown>
                  <Grid
                    container
                    style={{
                      scrollSnapAlign: 'start',
                      display: showVideo ? 'none' : 'flex',
                    }}
                    justify="center"
                  >
                    <Grid
                      item
                      xs={false}
                      lg={adActive ? 3 : 1}
                      xl={adActive ? 3 : 3}
                    >
                      <AdPoisitionerContainer />
                    </Grid>

                    <Grid item xs={false} lg={7}>
                      <LiveNewsTablet />
                    </Grid>
                    <Grid item xs={false} xl={1} />
                    <Grid item xs={false} xl={1} />
                  </Grid>
                </Hidden>
              )}
            </div>
          )}

          {!showVideo && statusRound === false && (
            <Grid
              className="flex-grow-1"
              container
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={3}
                style={{ maxWidth: width === 'lg' ? '300px' : null }}
              >
                {!showVideo && (
                  <KotlerNotification disableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Card
                        elevation={3}
                        style={{
                          alignItems: 'baseline',
                          background: 'rgba(255, 255, 255, 0.87)',
                          display: 'flex',
                        }}
                        className="border  container-fluid p-2 m-1"
                      >
                        <CardHeader
                          style={{ padding: 0, margin: 0 }}
                          title={(
                            <span
                              style={{
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                textAlign: 'left',
                                fontSize: 12,
                                marginTop: 4,
                                marginBottom: '6px',
                                lineHeight: '18px',
                                display: 'flex',
                              }}
                            >
                              <div>
                                <InfoOutlined />
                              </div>
                              <div style={{ marginTop: 3, marginLeft: '3px' }}>
                                {' '}
                                <IntlMessages id="menAtWorkRound" />
                              </div>
                            </span>
                          )}
                        />
                      </Card>
                    </div>
                  </KotlerNotification>
                )}
                {/*  <div className="">
                    <h2
                      className="mb-3 text-center "
                      style={{ color: '#00665F' }}
                    >
                      <IntlMessages id="menAtWorkRound" />
                    </h2>
                    <img src={workingImg} alt="MK" title="MK" />
                  </div> */}
              </Grid>
            </Grid>
          )}
          {!showVideo && false && (
            <>
              <strong style={{ display: 'none' }}>
                <IntlMessages id="Team" />
:
              </strong>
              {' '}
              {Members}
            </>
          )}
        </div>
        {!showVideo && statusRound === true && (
          <>
            <div className="w-100" style={{ zIndex: 1 }}>
              <LiveMaxVersion />
            </div>
            <WorkgroupStockTicker workgroups={data && data.workGroups} />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ( { user, settings } ) => ( {
  user,
  isVideo: settings.isVideo,
  isLoading: settings.isLoading,
  locale: settings.locale,
  isTip: settings.isTip,
} );
// const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  toggleVideo: SettingsActions.toggleVideo,
  toggleTip: SettingsActions.toggleTip,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( withWidth()( Dashboard ) );
