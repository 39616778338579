import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import SettingsActions from 'store/reducers/Settings';
import { Select } from 'components/custom/FormElements';
// import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

import HeaderImage from 'assets/images/kotler/sectionHeaders/positioning-research.jpeg';
import ProductService from 'services/Product';
import ReportService from 'services/Report';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import FeatherIcon from 'components/custom/FeatherIcon';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';
import ResearchTableValues from 'modules/Research/components/ResearchTableValues';

class Positioning extends React.Component {
  state = {
    category: '',
    segment: 'Executive',
    categories: [],
    segments: [
      { id: 'Executive', name: 'Executive' },
      { id: 'Professional', name: 'Professional' },
      { id: 'Employee', name: 'Employee' },
      { id: 'Student', name: 'Student' },
      { id: 'Home', name: 'Home' },
    ],
    attributes: [],
    positioning: {},
    prices: {},
    chartData: [],
    attributesChart: [],
    reportLink: null,
  };
  backLabel = 'research';
  prices = {};
  mkmode = {};
  componentDidMount() {
    const { toggleAlert, user, history, match } = this.props;
    this.entityId = match.params.id;
    this.groupId = match.params.groupid;

    if ( history.location.pathname.includes( 'price' ) ) {
      this.backLabel = 'price';
    } else if ( history.location.pathname.includes( 'distribution' ) ) {
      this.backLabel = 'distribution';
    }

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields, studio: true };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields, studio: false };
    }
    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentDidUpdate( prevProps ) {
    this.refreshGraph( prevProps );
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading, intl } = this.props;

    toggleLoading( true );
    async.parallel(
      {
        products: ( cb ) => {
          ProductService.getProducts( this.activeSession.id, {
            workgroup: this.groupId,
            'filters[inStatus]': ['READY', 'RD', 'CANCELED'],
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        positioning: ( cb ) => {
          ReportService.getMarketPositioningReport( this.activeSession.id, {
            workgroup: this.groupId,
            purchasedReport: this.entityId,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );
        this.setState( { products: results.products } );
        const reportLink = results.positioning.reportLink !== 'ONLINE'
          && results.positioning.reportLink;
        // eslint-disable-next-line no-param-reassign
        delete results.positioning.reportLink;
        let categories = _.map( results.positioning, ( item, key ) => ( {
          id: key,
          name: key,
        } ) );
        if ( !this.mkmode.information.research.mk003.laptop ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'LAPTO' ) === -1,
          );
        }
        if ( !this.mkmode.information.research.mk003.tablet ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'TABLET' ) === -1,
          );
        }
        if ( !this.mkmode.information.research.mk003.celular ) {
          categories = _.filter( categories, o => o.name.indexOf( 'CEL' ) === -1 );
        }

        this.setState( {
          categories,
          positioning: results.positioning,
          reportLink,
        } );

        this.attributesChart = [];
        _.map( results.products[0].attributes, ( attribute ) => {
          const item = {
            attribute: intl.formatMessage( { id: attribute.label } ),
            id: attribute.id,
          };
          item[results.products[0].id] = 0;
          item.NEW = 0;
          this.attributesChart.push( item );
        } );

        this.chartData = [];
        // this.chartData.push( { id: 'NEW', name: 'NEW' } );
        this.attributesChart = _.orderBy(
          this.attributesChart,
          ['attribute'],
          ['asc'],
        );
        const temp = this.attributesChart.filter( item => item.id === 'price' );
        this.attributesChart.push( {
          attribute: intl.formatMessage( { id: 'price' } ),
          id: 'price',
        } );
        if ( temp[0] ) {
          const newattribute = this.attributesChart.filter(
            item => item.id !== 'price',
          );
          newattribute.push( temp[0] );
          this.attributesChart = newattribute;
        }

        this.renderGraph( this.attributesChart, this.chartData );
        this.setState( {
          chartData: this.chartData,
          attributesChart: this.attributesChart,
        } );
        if ( this.mkmode.studio ) {
          this.handleChangeCategory( 'CELL PHONES' );
        }
      },
    );
  };

  renderGraph = ( attributes, chartData ) => {
    const { intl } = this.props;
    let formatted = attributes.map( ( item ) => {
      const first = `${intl.formatMessage( { id: chartData.length ? item.id : item.attribute } )}`;
      const second = item.attribute.split( ' ' ).length > 1
        ? item.attribute.split( ' ' )[1]
        : '';
      return { ...item, attribute: first + second };
    } );
    formatted = _.orderBy( formatted, ['attribute'], ['asc'] );
    if ( this.chart ) {
      this.chart.dispose();
    }
    const config = {
      data: formatted,
      type: am4charts.RadarChart,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
        },
      ],
      cursor: { type: 'RadarCursor' },
      legend: { type: 'Legend', position: 'right' },
      yAxes: [{ type: 'ValueAxis' }],
      series: _.map( chartData, product => ( {
        type: 'RadarSeries',
        dataFields: {
          valueY: product.id,
          categoryX: 'attribute',
        },
        strokeWidth: 3,
        tooltipText: `${product.name} - {categoryX}: {valueY.value}`,
        name: product.name,
        // fill: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        // stroke: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        bullets: [{ type: 'CircleBullet' }],
      } ) ),
    };

    this.setState( { prices: this.prices } );
    this.prices = {};
    this.chart = am4core.createFromConfig( config, 'chartdiv' );
  };

  handleChangeProduct = ( value ) => {
    const { products } = this.state;
    const { intl } = this.props;
    const product = _.find( products, { id: value } );

    this.attributesChart = [];
    _.map( product.attributes, ( attribute ) => {
      const item = {
        attribute: intl.formatMessage( { id: attribute.label } ),
        id: attribute.id,
      };
      item[product.id] = attribute.value;
      item.NEW = 0;
      this.attributesChart.push( item );
    } );
    this.chartData = [];
    this.chartData.push( { id: product.id, name: 'NEW' } );
    this.attributesChart = _.orderBy(
      this.attributesChart,
      ['attribute'],
      ['asc'],
    );
    const temp = this.attributesChart.filter( item => item.id === 'price' );
    if ( temp[0] ) {
      const newattribute = this.attributesChart.filter(
        item => item.id !== 'price',
      );
      newattribute.push( temp[0] );
      this.attributesChart = newattribute;
    }
    this.renderGraph( this.attributesChart, this.chartData );
    const formData = { name: product.name, product: product.id };
    this.setState( { formData } );
  };

  capitalize = ( s ) => {
    if ( typeof s !== 'string' ) return '';
    return s.charAt( 0 ).toUpperCase() + s.slice( 1 );
  };

  getAttributeLabelStart = ( attribute, segment ) => {
    const { intl } = this.props;
    const starTable = {
      Design: {
        Executive: '***',
        Professional: '**',
        Employee: '**',
        Student: '**',
        Home: '*',
      },
      Speed: {
        Executive: '***',
        Professional: '***',
        Employee: '***',
        Student: '**',
        Home: '*',
      },
      Size: {
        Executive: '***',
        Professional: '***',
        Employee: '*',
        Student: '**',
        Home: '**',
      },
      Reliability: {
        Executive: '***',
        Professional: '***',
        Employee: '**',
        Student: '**',
        Home: '*',
      },
      Weight: {
        Executive: '***',
        Professional: '**',
        Employee: '*',
        Student: '*',
        Home: '*',
      },
      Price: {
        Executive: '*',
        Professional: '**',
        Employee: '**',
        Student: '***',
        Home: '***',
      },
    };

    return `${intl.formatMessage( { id: this.capitalize( attribute ) } )} ${
      starTable[this.capitalize( attribute )][this.capitalize( segment )]
    }`;
  };

  handleChangeCategory = ( value ) => {
    const { positioning, segment } = this.state;
    const atributos = _.filter( positioning[value].data, { yLabel: 'price' } );
    const { intl } = this.props;
    this.attributesChart = [];
    this.chartData = [];
    let flag = false;
    _.map( atributos, ( attribute ) => {
      const item = {
        attribute: this.getAttributeLabelStart( attribute.xLabel, segment ),
        id: attribute.xLabel,
      };
      const itemPrice = {
        attribute: intl.formatMessage( { id: this.getAttributeLabelStart( 'Price', segment ) } ),
        id: 'price',
      };
      const segmentr = _.find( attribute.idealPositioning, { name: segment } );
      item[segment] = segmentr.x;
      itemPrice[segment] = segmentr.y;
      this.prices[segment] = segmentr.y;
      const existsSegment = _.find( this.chartData, { id: segment } );
      if ( !existsSegment ) {
        this.chartData.push( {
          id: segment,
          name: 'Ideal Positioning',
          config: { fill: '#ffffff' },
        } );
      }
      // const maxPrice = itemPrice[segment];

      _.map( attribute.products, ( product ) => {
        item[product.productName + product.workgroupName] = product.x;
        itemPrice[product.productName + product.workgroupName] = product.y;
        this.prices[product.productName] = product.y;
        const x = _.find( this.chartData, { id: product.productName + product.workgroupName } );
        if ( !x ) {
          this.chartData.push( {
            id: product.productName + product.workgroupName,
            name: `${product.productName}`,
          } );
        }
      } );

      // itemPrice[segment] = 5;

      this.attributesChart.push( item );
      if ( !flag ) {
        let maxPrice = 0;

        // const tmpPrices = [];
        _.forIn( itemPrice, ( value2, key ) => {
          if ( key !== 'attribute' && key !== 'id' ) {
            if ( value2 > maxPrice ) maxPrice = value2;
            // tmpPrices.push( value2 );
          }
        } );

        // const minPrice = _.min( tmpPrices );

        _.forIn( itemPrice, ( value2, key ) => {
          if ( key !== 'attribute' && key !== 'id' ) {
            itemPrice[key] = Number( ( value2 / maxPrice ) * 5 ).toFixed( 1 );
          }
        } );

        this.attributesChart.push( itemPrice );
        flag = true;
      }
    } );
    this.attributesChart = _.orderBy(
      this.attributesChart,
      ['attribute'],
      ['asc'],
    );
    const temp = this.attributesChart.filter( item => item.id === 'price' );
    if ( temp[0] ) {
      const newattribute = this.attributesChart.filter(
        item => item.id !== 'price',
      );
      newattribute.push( temp[0] );
      this.attributesChart = newattribute;
    }
    // this.chartData.push( { id: 'a', name: 'NEW' } );
    this.renderGraph( this.attributesChart, this.chartData );
    const formData = { name: value, category: value, segment };
    this.setState( {
      formData,
      category: value,
      attributesChart: this.attributesChart,
      chartData: this.chartData,
    } );
  };

  handleChangeSegments = ( value ) => {
    const { positioning, category } = this.state;
    const atributos = _.filter( positioning[category].data, { yLabel: 'price' } );
    this.attributesChart = [];
    this.chartData = [];
    let flag = false;

    _.map( atributos, ( attribute ) => {
      const item = {
        attribute: this.getAttributeLabelStart( attribute.xLabel, value ),
        id: attribute.xLabel,
      };
      const itemPrice = {
        attribute: this.getAttributeLabelStart( 'Price', value ),
        id: 'price',
      };

      const segment = _.find( attribute.idealPositioning, { name: value } );
      item[value] = segment.x;
      itemPrice[value] = segment.y;
      this.prices[value] = segment.y;

      const existsSegment = _.find( this.chartData, { id: value } );
      if ( !existsSegment ) {
        this.chartData.push( {
          id: value,
          name: 'Ideal Positioning',
          config: { fill: '#ffffff' },
        } );
      }
      // const maxPrice = itemPrice[value];
      _.map( attribute.products, ( product ) => {
        item[product.productName + product.workgroupName] = product.x;
        itemPrice[product.productName + product.workgroupName] = product.y;
        this.prices[product.productName] = product.y;

        const x = _.find( this.chartData, { id: product.productName + product.workgroupName } );
        if ( !x ) {
          this.chartData.push( {
            id: product.productName + product.workgroupName,
            name: `${product.productName}`,
          } );
        }
      } );

      // itemPrice[value] = 5;

      this.attributesChart.push( item );

      if ( !flag ) {
        let maxPrice = 0;
        const tmpPrices = [];
        _.forIn( itemPrice, ( value2, key ) => {
          if ( key !== 'attribute' && key !== 'id' ) {
            if ( value2 > maxPrice ) maxPrice = value2;
            tmpPrices.push( value2 );
          }
        } );

        // const minPrice = _.min( tmpPrices );

        _.forIn( itemPrice, ( value2, key ) => {
          if ( key !== 'attribute' && key !== 'id' ) {
            itemPrice[key] = Number( ( value2 / maxPrice ) * 5 ).toFixed( 1 );
          }
        } );
        this.attributesChart.push( itemPrice );
        flag = true;
      }
    } );

    // this.chartData.push( { id: 'a', name: 'NEW' } );
    this.attributesChart = _.orderBy(
      this.attributesChart,
      ['attribute'],
      ['asc'],
    );
    const temp = this.attributesChart.filter( item => item.id === 'price' );
    if ( temp[0] ) {
      const newattribute = this.attributesChart.filter(
        item => item.id !== 'price',
      );
      newattribute.push( temp[0] );
      this.attributesChart = newattribute;
    }
    this.renderGraph( this.attributesChart, this.chartData );
    const formData = { name: value, segment: value, category };
    this.setState( { formData } );
  };

  submitForm = ( data ) => {
    const { toggleAlert, toggleLoading, history } = this.props;

    // if ( _.find( data.variables, item => !item.value ) ) {
    //  return toggleAlert( 'fillAttributesValue', 'danger', true );
    // }
    toggleLoading( true );
    const dataToSend = {
      product: data.formData.product,
      name: data.formData.name,
      description: data.formData.description,
    };

    ProductService.duplicateProduct( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        history.push( `/sessions/general/${this.backLabel}` );
      },
    );
  };

  savePdf = () => {
    const printArea1 = document.getElementById( 'printWrapper1' );
    const printArea2 = document.getElementById( 'printWrapper2' );
    html2canvas( printArea1 ).then( ( canvas ) => {
      const dataURL = canvas.toDataURL();
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF( { orientation: 'landscape' } );

      pdf.addImage( dataURL, 'JPEG', 5, 5 );
      html2canvas( printArea2 ).then( ( canvas2 ) => {
        const dataURL2 = canvas2.toDataURL();
        const milimetros = Math.floor( canvas.width * 0.264583 );

        // eslint-disable-next-line new-cap
        if ( milimetros > 110 ) {
          pdf.addPage();
          pdf.addImage( dataURL2, 'JPEG', 5, 5 );
        } else {
          pdf.addImage( dataURL2, 'JPEG', milimetros + 5, 5 );
        }
        pdf.save( 'Report.pdf' );
      } );
    } );
  };

  refreshGraph( prevProps ) {
    const { intl } = this.props;
    const locale = { intl };
    if ( locale.intl.locale !== prevProps.intl.locale ) {
      const { attributesChart, chartData } = this.state;
      this.renderGraph( attributesChart, chartData );
    }
  }

  render() {
    const {
      attributes,
      formData,
      categories,
      segments,
      prices,
      reportLink,
    } = this.state;
    const { user } = this.props;
    return (
      <>
        <Form
          initialValues={{
            formData,
            attributes,
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, values } ) => (
            <form onSubmit={handleSubmit}>
              <HeadConfig
                breadcrumbsLinks={[
                  {
                    url: '/',
                    name: 'HomeLink',
                  },
                  {
                    url: `/sessions/${user.activeSession.id}/info/${this.backLabel}`,
                    name: `InformationDashboard-${this.backLabel}`,
                  },
                  { name: 'positioningReport' },
                ]}
              />
              <KotlerSectionHeader
                title="positioningReport"
                image={HeaderImage}
              />

              <Grid container id="printWrapper">
                <Grid item xs={12} md={4} id="printWrapper1">
                  <div className="d-flex flex-column justify-content-between h-100">
                    <CardBox
                      styleName="px-2 h-100"
                      childrenStyle=""
                      header={(
                        <KotlerCardHeader
                          title="positioningFilters"
                          minHeight="0px"
                        />
                      )}
                    >
                      <Select
                        field="formData.category"
                        label="category"
                        options={categories}
                        onChange={this.handleChangeCategory}
                        translateOptions
                      />

                      <Select
                        field="formData.segment"
                        label="segment"
                        options={segments}
                        containerClass={
                          values.formData && values.formData.category
                            ? ''
                            : 'd-none'
                        }
                        onChange={this.handleChangeSegments}
                        translateOptions
                      />
                    </CardBox>
                    {!_.isEmpty( prices ) && (
                      <CardBox
                        styleName="px-2"
                        header={
                          <KotlerCardHeader minHeight="0px" title="prices" />
                        }
                      >
                        <Table className="kotler-table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <IntlMessages id="description" />
                              </TableCell>
                              <TableCell>
                                <IntlMessages id="price" />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.map( prices, ( item, key ) => (
                              <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell className="text-right">
                                  {Util.formatCurrency( item )}
                                </TableCell>
                              </TableRow>
                            ) )}
                          </TableBody>
                        </Table>
                      </CardBox>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={8}
                  id="printWrapper2"
                  style={{ overflowX: 'auto' }}
                >
                  <CardBox styleName="px-2 h-100" disableHeader>
                    <Grid container>
                      <Grid item xs={8}>
                        <KotlerCardHeader
                          title="positioningGraph"
                          minHeight="0px"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {reportLink && (
                          <Button
                            variant="text"
                            type="button"
                            color="primary"
                            className="kotler-btn"
                            href={reportLink}
                          >
                            <FeatherIcon
                              icon="download"
                              className="mr-2"
                              size="14px"
                              color="primary.main"
                            />
                            <IntlMessages id="download" />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    {' '}
                    <p />
                    <p />
                    {' '}
                    <div
                      id="chartdiv"
                      style={{
                        width: '100%',
                        height: '500px',
                      }}
                    />
                    <IntlHtmlMessages id="positioning.importance.legend" />
                  </CardBox>
                </Grid>
              </Grid>
              <Grid container justify="center" className="my-3">
                <Grid item xs={12}>
                  <ResearchTableValues />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( Positioning ) );
