import React from 'react';
import { connect } from 'react-redux';
import {
  FormHelperText,
  Button,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  Box,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import _, { find } from 'lodash';
import async from 'async';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import './style.css';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import { TextField, ButtonsNP } from 'components/custom/FormElements';

import { required } from 'config/InputErrors';
import CategoryService from 'services/Category';
import AttributeService from 'services/Attribute';
import AttributeCostService from 'services/AttributeCost';
import Util from 'utils/Util';
import ProductService from 'services/Product';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import HoverTitle from 'components/custom_v2/HoverTitle';
import PropertyIcon from 'components/custom_v2/PropertyIcon';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/products.jpeg';
import HeadConfig from 'components/custom/HeadConfig';
import SectionInfoText from 'components/custom/SectionInfoText';
import { UserActions } from 'store/reducers/User';

/* const attributesDetail = [
  {
    category: 'CATEGORY_1',
    label: 'Design',
    values: [
      { key: 1, text: 'Poor', val: '$50' },
      { key: 2, text: 'Basic', val: '$100' },
      {
        key: 3,
        text: 'Medium',
        val: '$150',
      },
      { key: 4, text: 'Advanced', val: '$200' },
      { key: 5, text: 'Premium', val: '$250' },
    ],
  },
  {
    category: 'CATEGORY_1',
    label: 'Size',
    values: [
      { key: 2, text: 'Slim', val: '$50' },
      { key: 1, text: 'Regular', val: '$0' },
      {
        key: 3,
        text: 'Large',
        val: '$50',
      },
      { key: 4, text: 'X-Large', val: '$70' },
    ],
  },
  {
    category: 'CATEGORY_1',
    label: 'Reliability',
    values: [
      { key: 1, text: 'Poor', val: '$30' },
      { key: 2, text: 'Basic', val: '$60' },
      {
        key: 3,
        text: 'Normal',
        val: '$90',
      },
      { key: 4, text: 'Very Good', val: '$120' },
      { key: 5, text: 'Excellent', val: '$150' },
    ],
  },
  {
    category: 'CATEGORY_1',
    label: 'Sustainability',
    values: [
      { key: 1, text: 'Not Sustainable', val: '$20' },
      { key: 2, text: 'Regular', val: '$40' },
      {
        key: 3,
        text: 'Good',
        val: '$60',
      },
      { key: 4, text: 'Very Good', val: '$80' },
      { key: 5, text: 'Sustainable', val: '$60' },
    ],
  },
  {
    category: 'CATEGORY_1',
    label: 'Speed',
    values: [
      { key: 1, text: 'Browsing', val: '$35' },
      { key: 2, text: 'Browsing + Video', val: '$110' },
      {
        key: 3,
        text: 'Standard',
        val: '$320',
      },
      { key: 4, text: 'High performance', val: '$410' },
    ],
  },
  {
    category: 'CATEGORY_2',
    label: 'Design',
    values: [
      { key: 1, text: 'Poor', val: '$70' },
      { key: 2, text: 'Basic', val: '$90' },
      {
        key: 3,
        text: 'Medium',
        val: '$100',
      },
    ],
  },
  {
    category: 'CATEGORY_2',
    label: 'Size',
    values: [
      { key: 1, text: 'Regular', val: '$0' },
      { key: 2, text: 'Large', val: '$30' },
      {
        key: 3,
        text: 'X-Large',
        val: '$50',
      },
    ],
  },
  {
    category: 'CATEGORY_2',
    label: 'Reliability',
    values: [
      { key: 1, text: 'Poor', val: '$15' },
      { key: 2, text: 'Normal', val: '$30' },
      {
        key: 3,
        text: 'Very Good',
        val: '$45',
      },
      { key: 4, text: 'Excellent', val: '$60' },
    ],
  },
  {
    category: 'CATEGORY_2',
    label: 'Sustainability',
    values: [
      { key: 1, text: 'Regular', val: '$20' },
      { key: 2, text: 'Good', val: '$25' },
      {
        key: 3,
        text: 'Very Good',
        val: '$30',
      },
      { key: 4, text: 'Sustainable', val: '$40' },
    ],
  },
  {
    category: 'CATEGORY_2',
    label: 'Speed',
    values: [
      { key: 1, text: 'Regular', val: '$15' },
      { key: 2, text: 'High performance', val: '$35' },
    ],
  },
  {
    category: 'CATEGORY_3',
    label: 'Design',
    values: [
      { key: 1, text: 'Poor', val: '$10' },
      { key: 2, text: 'Basic', val: '$40' },
      {
        key: 3,
        text: 'Medium',
        val: '$80',
      },
      { key: 4, text: 'Advanced', val: '$150' },
      { key: 5, text: 'Premium', val: '$300' },
    ],
  },
  {
    category: 'CATEGORY_3',
    label: 'Size',
    values: [
      { key: 1, text: 'Regular', val: '$0' },
      { key: 2, text: 'Large', val: '$50' },
    ],
  },
  {
    category: 'CATEGORY_3',
    label: 'Reliability',
    values: [
      { key: 1, text: 'Poor', val: '$15' },
      { key: 2, text: 'Normal', val: '$30' },
      {
        key: 3,
        text: 'Very Good',
        val: '$45',
      },
      { key: 4, text: 'Excellent', val: '$60' },
    ],
  },
  {
    category: 'CATEGORY_3',
    label: 'Sustainability',
    values: [
      { key: 1, text: 'Not Sustainable', val: '$20' },
      { key: 2, text: 'Regular', val: '$25' },
      {
        key: 3,
        text: 'Good',
        val: '$30',
      },
      { key: 4, text: 'Very Good', val: '$40' },
    ],
  },
  {
    category: 'CATEGORY_3',
    label: 'Speed',
    values: [
      { key: 1, text: 'Basic', val: '$15' },
      { key: 2, text: 'Regular', val: '$35' },
      {
        key: 3,
        text: 'High performance',
        val: '$70',
      },
    ],
  },
];
 */
class NewProduct extends React.Component {
  state = {
    category: {},
    chartData: [],
    confirmBudgetModal: false,
  };
  backLabel = 'products';

  componentDidMount() {
    const { toggleAlert, user, history } = this.props;

    if ( history.location.pathname.includes( 'price' ) ) {
      this.backLabel = 'price';
    } else if ( history.location.pathname.includes( 'distribution' ) ) {
      this.backLabel = 'distribution';
    }

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentDidUpdate( prevProps ) {
    this.refreshGraph( prevProps );
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading, match } = this.props;
    toggleLoading( true );
    async.parallel(
      {
        category: ( cb ) => {
          CategoryService.getCategory( match.params.id ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        attributes: ( cb ) => {
          AttributeService.getAttributes( {
            'filters[session]': this.activeSession.id,
            workgroup: this.license.workGroup.id,
            category: match.params.id,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        defaultValues: ( cb ) => {
          AttributeService.getAttributes( {
            'filters[session]': this.activeSession.id,
            workgroup: this.license.workGroup.id,
            category: match.params.id,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.default );
          } );
        },
        chartData: ( cb ) => {
          // eslint-disable-next-line max-len
          CategoryService.getProducts( this.activeSession.id, match.params.id, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );

        this.attributesChart = [];
        if ( results.chartData.length ) {
          _.map( results.chartData[0].attributes, ( attribute ) => {
            const item = { attribute: attribute.label, id: attribute.id };
            _.map( results.chartData, ( product ) => {
              item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
            } );
            item.NEW = 0;
            this.attributesChart.push( item );
          } );
          this.chartData = _.cloneDeep( results.chartData );
          this.chartData.push( { id: 'NEW', name: 'NEW' } );
          this.attributesChart = _.orderBy(
            this.attributesChart,
            ['attribute'],
            ['asc'],
          );
          this.renderGraph( this.attributesChart, this.chartData );
        } else if ( results.attributes.length ) {
          _.map( results.attributes, ( attribute ) => {
            const item = { attribute: attribute.label, id: attribute.id };
            item.NEW = 0;
            this.attributesChart.push( item );
          } );
          this.chartData = [];
          this.chartData.push( { id: 'NEW', name: 'NEW' } );
          this.attributesChart = _.orderBy(
            this.attributesChart,
            ['attribute'],
            ['asc'],
          );
          this.renderGraph( this.attributesChart, this.chartData );
        }

        this.setState( {
          defaultValues: results.defaultValues,
          category: results.category,
          attributes: _.chain( results.attributes )
            .orderBy( 'orderAttribute' )
            .map( ( item ) => {
              const atrrDetailValues = results.category.attributesDetail[item.name];

              return {
                ...item,
                maxValueArray: _.map(
                  new Array( item.maxValue ),
                  ( value, index ) => ( {
                    id: index + 1,
                    ...atrrDetailValues[index + 1],
                  } ),
                ),
              };
            } )
            .value(),
          chartData: this.chartData,
        } );
      },
    );
  };

  renderGraph = ( attributes, chartData ) => {
    const { intl } = this.props;
    let formatted = attributes.map( item => ( {
      ...item,
      attribute: intl.formatMessage( { id: item.attribute } ),
    } ) );
    if ( this.chart ) {
      this.chart.dispose();
    }
    formatted = _.orderBy( formatted, ['attribute'], ['asc'] );
    const styles = {
      logo: { disabled: true },
      /*   paddingTop :0,
      paddingBottom :0,
      paddingLeft :0,
      paddingRight:0, */
      background: {
        fill: '#eeeeee',
        cursor: { type: 'RadarCursor' },
      },
    };
    const config = {
      data: formatted,
      type: am4charts.RadarChart,
      ...styles,
      xAxes: [
        {
          renderer: { gridType: 'polygons' },
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          fontSize: '12px',
          fontWeight: 600,
        },
      ],

      labels: { template: { fill: am4core.color( '#333f48' ) } },
      /* legend: { type: 'Legend' }, */
      yAxes: [
        {
          type: 'ValueAxis',
          renderer: { gridType: 'polygons' },
        },
      ],
      series: _.map( chartData, product => ( {
        type: 'RadarSeries',

        dataFields: {
          valueY: product.id,
          categoryX: 'attribute',
        },
        strokeWidth: 2,
        tooltipText: '{valueY}',
        name: product.name,
        fillOpacity: 1,
        fill:
          product.id === 'NEW'
            ? am4core.color( 'rgba(0, 53, 148, 0.24);' )
            : am4core.color( 'rgba(44, 213, 196, 0.10)' ),
        stroke:
          product.id === 'NEW'
            ? am4core.color( '#003594' )
            : am4core.color( '#009681' ),
        bullets: [
          {
            type: 'CircleBullet',
            fill:
              product.id === 'NEW'
                ? am4core.color( '#003594' )
                : am4core.color( '#009681' ),
          },
        ],
      } ) ),
    };
    this.chart = am4core.createFromConfig( config, 'chartdiv' );
  };

  calcAttribute = ( id, value, previousValue, index, formData, sizeValue ) => {
    const { attributes } = this.state;
    const { toggleLoading } = this.props;

    toggleLoading( true );

    async.waterfall(
      [
        ( cb ) => {
          this.getAttributeCost(
            id,
            value,
            previousValue,
            index,
            formData,
            cb,
            sizeValue,
          );
          const current = _.find( attributes, { id } );
          const findIndex = _.findIndex( this.attributesChart, { attribute: current.label } );
          if ( findIndex !== -1 ) {
            this.attributesChart[findIndex].NEW = value;
            this.attributesChart = _.orderBy(
              this.attributesChart,
              ['attribute'],
              ['asc'],
            );
            this.renderGraph( this.attributesChart, this.chartData );
          }
        },
      ],
      () => {
        toggleLoading( false );
      },
    );
  };

  getAttributeCost = (
    id,
    value,
    previousValue,
    index,
    formData,
    cb,
    sizeValue,
  ) => {
    const { category, attributes, defaultValues } = this.state;

    const params = {
      category: category.id,
      workgroup: this.license.workGroup.id,
      attribute: id,
      value,
    };
    if ( sizeValue > 0 ) {
      params.size = sizeValue;
    }
    AttributeCostService.getAttributeCostV2( this.activeSession.id, params ).then(
      ( response ) => {
        if ( !response.ok ) {
          const newAttributes = [...attributes];
          newAttributes[index].value = previousValue;
          this.setState(
            {
              attributeError: response.errors,
              attributeIdError: id,
              attributes: newAttributes,
              formData,
            },
            () => cb( true ),
          );
        } else {
          const newAttributes = [...attributes];
          newAttributes[index].cost = response.data.cost;
          newAttributes[index].costIPlusD = response.data.costIPlusD;
          newAttributes[index].value = value;
          newAttributes[index].requiredTime = response.data.requiredTime;

          this.setState(
            {
              attributeError: null,
              attributeIdError: null,
              attributes: newAttributes,
              formData: {
                ...formData,
                developCost: Util.formatCurrency(
                  _.sumBy( newAttributes, 'costIPlusD' ) === 0
                    ? defaultValues.fixedCostMoney
                    : _.sumBy( newAttributes, 'costIPlusD' ),
                ),
                unitProductionCost: Util.formatCurrency(
                  _.sumBy( newAttributes, 'cost' ),
                ),
                developTime:
                  _.sumBy( newAttributes, 'requiredTime' ) === 0
                    ? defaultValues.fixedCostTime
                    : _.sumBy( newAttributes, 'requiredTime' ),
              },
            },
            cb,
          );
        }
      },
    );
  };
  // eslint-disable-next-line react/sort-comp
  forceData( activeSession, dataToSend ) {
    const { toggleLoading, toggleAlert, history, intl } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    toggleLoading( true );
    const data = dataToSend;
    data.forceCreation = true;
    ProductService.saveProductV2( this.activeSession.id, data ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        toggleLoading( false );
        if (
          response.errors.toLowerCase().indexOf( 'attr' ) > -1
          || response.errors.toLowerCase().indexOf( 'atr' ) > -1
        ) {
          this.setState( { workGroup: this.activeSession.id } );
          this.setState( { dataToSend } );
          return this.toggleConfirmNewProductModal();
        }
        const messageErr = response.errors
          === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
          ? intl.formatMessage( {
            id:
                  'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
          } )
          : response.errors;
        return toggleAlert( messageErr );
      }
      // if ( !response.ok ) return toggleAlert( response.errors );
      toggleAlert( 'dataSaved', 'info' );
      history.push( `/sessions/general/${this.backLabel}` );
    } );
  }
  toggleConfirmNewProductModal = () => {
    const { confirmBudgetModal } = this.state;
    this.setState( { confirmBudgetModal: !confirmBudgetModal } );
  };
  setConfirmBudgetModal = () => {
    const { workGroup, dataToSend } = this.state;
    this.forceData( workGroup, dataToSend );
  };
  updateProcessingStore = ( updatedArray ) => {
    const dataJSON = JSON.stringify( updatedArray );
    localStorage.setItem( 'product_creating', dataJSON );
  };

  isProcessProduct = ( data, attribute = 'name' ) => {
    const { user } = this.props;
    const { activeSession: { id: sessionId } } = this;
    const product = { ...data };
    delete product.description;
    if ( attribute === 'name' ) {
      delete product.attributeData;
    } else if ( attribute === 'attributeData' ) delete product.name;

    const foundProduct = find( user.productsCreating, {
      product,
      sessionId,
    } );
    if (
      !foundProduct
      || foundProduct.isDone
      || ( foundProduct.time - new Date().getTime() ) / 60000 >= 5
    ) return false;
    return true;
  };
  submitForm = ( data ) => {
    const {
      toggleAlert,
      toggleLoading,
      history,
      intl,
      initProductCreating,
      finishProductCreating,
    } = this.props;
    const { category } = this.state;

    if ( _.find( data.variables, item => !item.value ) ) {
      return toggleAlert( 'fillAttributesValue', 'danger', true );
    }
    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      category: category.id,
      name: data.formData.name
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' ),
      description: data.formData.description
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' ),
      attributeData: _.map( data.attributes, attribute => ( {
        attribute: attribute.id,
        value: attribute.value,
      } ) ),
    };
    const isProcessingByName = this.isProcessProduct( dataToSend, 'name' );
    if ( isProcessingByName ) {
      toggleAlert( 'procesingProduct.name', 'danger', true );
      toggleLoading( false );
      return false;
    }
    const isProcessingByAttributes = this.isProcessProduct(
      dataToSend,
      'attributeData',
    );
    if ( isProcessingByAttributes ) {
      toggleAlert( 'procesingProduct.attributes', 'danger', true );
      toggleLoading( false );
      return false;
    }
    initProductCreating( dataToSend );

    ProductService.saveProductV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        finishProductCreating( dataToSend );
        if ( !response.ok ) {
          toggleLoading( false );
          // eslint.disable-next-line max-len
          /* if ( response.errors.toLowerCase().indexOf( 'attri' ) > -1
          || response.errors.toLowerCase().indexOf( 'atri' ) > -1 ) {
            this.setState( { workGroup: this.activeSession.id } );
            this.setState( { dataToSend } );
            return this.toggleConfirmNewProductModal();
          } */
          const messageErr = response.errors
            === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
            ? intl.formatMessage( {
              id:
                    'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
            } )
            : response.errors;
          return toggleAlert( messageErr );
        }
        // if ( !response.ok ) return toggleAlert( response.errors );
        toggleAlert( 'dataSaved', 'info' );
        history.push( `/sessions/general/${this.backLabel}` );
      },
    );
  };

  refreshGraph( prevProps ) {
    const { intl } = this.props;
    const locale = { intl };
    const attributesChart = [];
    if ( locale.intl.locale !== prevProps.intl.locale ) {
      const { chartData } = this.state;
      if ( chartData.length ) {
        _.map( chartData[0].attributes, ( attribute ) => {
          const item = { attribute: attribute.label, id: attribute.id };
          _.map( chartData, ( product ) => {
            // eslint-disable-next-line max-len
            item[product.id] = _.find( product.attributes, { id: attribute.id } )
              ? _.find( product.attributes, { id: attribute.id } ).value
              : {};
          } );
          item.NEW = 0;
          attributesChart.push( item );
        } );
      }
      this.renderGraph( attributesChart, chartData );
    }
  }

  render() {
    const {
      category,
      attributes,
      attributeIdError,
      attributeError,
      formData,
      confirmBudgetModal,
    } = this.state;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            {
              name: 'DecisionDashboard-products',
              url: '/sessions/general/products',
            },

            { name: 'newProduct' },
          ]}
        />
        <Form
          initialValues={{
            formData,
            attributes,
          }}
          validate={( values ) => {
            const errors = {};
            _.map( values.attributes, ( attribute ) => {
              if ( !errors.attributes ) errors.attributes = {};
              if ( !attribute.value ) errors.attributes[attribute.id] = required( attribute.value );
            } );
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, values, submitFailed, errors } ) => (
            <form onSubmit={handleSubmit}>
              <KotlerSectionHeader
                title={`titleNewProduct${category.name || ''}`}
                titleSubmit="ApproveRDproject"
                titleCancel="cancelBtn"
                image={HeaderImage}
                hoverSubmit={{ id: 'approveProject.hover', placement: 'left' }}
                cancelTo={`/sessions/general/${this.backLabel}`}
                onSubmit={handleSubmit}
              />
              <SectionInfoText title="help.new.product.snackbar" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    className="font-weight-bold mt-2 mb-1"
                  >
                    <IntlMessages id="newProductTitleGeneral" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <TextField
                    adornRequired
                    style={{ backgroundColor: '#EEEFEE' }}
                    field="formData.name"
                    label="productName"
                    validate={required}
                    inputProps={{ maxLength: 250 }}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                  <TextField
                    adornRequired
                    style={{ backgroundColor: '#EEEFEE' }}
                    field="formData.description"
                    label="productDescription"
                    validate={required}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
              </Grid>

              <Divider className="mb-3 mt-2" />
              <Typography
                variant="h6"
                color="textPrimary"
                className="font-weight-bold mt-2 mb-3"
              >
                <IntlMessages id="newProductTitleAttr" />
              </Typography>
              <Grid
                container
                className="flex-column-reverse flex-md-row"
                spacing={2}
              >
                <Grid item xs={12} md={7} lg={7}>
                  <Grid
                    container
                    spacing={1}
                    className="flex-column align-items-center overflow-auto align-items-md-start ml-md-2"
                  >
                    <FieldArray name="attributes">
                      {( { fields } ) => fields.map( ( attributeField, index ) => {
                        const attribute = attributes[index];
                        if (
                          category.label === 'CATEGORY_1'
                            && attribute.label === 'Size'
                        ) {
                          const idxSlim = _.findIndex(
                            attribute.maxValueArray,
                            o => o.id === 2,
                          );
                          const pulled = _.pullAt( attribute.maxValueArray, [
                            idxSlim,
                          ] );

                          attribute.maxValueArray = _.concat(
                            pulled,
                            attribute.maxValueArray,
                          );
                        }
                        return (
                          <Grid item key={attribute.id} className="mb-3">
                            <HoverTitle id="selectionAtributtes.hover">
                              <span
                                className="text-dark "
                                style={{ fontWeight: 600, fontSize: 12 }}
                              >
                                <PropertyIcon
                                  propertyName={attribute.label}
                                />
                                {/* {attribute.label} */}
                                <IntlMessages id={attribute.label} />
                              </span>
                            </HoverTitle>
                            <HoverTitle id="selectionAtributtes.hover">
                              <ButtonGroup
                                className="d-flex flex-wrap mt-2 justify-content-center justify-content-md-start"
                                variant="outlined"
                                style={{
                                  borderColor: '#C2C1CE',
                                  rowGap: '4px',
                                }}
                              >
                                {_.map( attribute.maxValueArray, item => (
                                  <Button
                                    className={classnames(
                                      ' m-0 text-dark bg-white px-2',
                                      {
                                        'bg-product-laptop':
                                            category.label === 'CATEGORY_1'
                                            && attribute.value === item.id,
                                        'bg-product-tablet':
                                            category.label === 'CATEGORY_2'
                                            && attribute.value === item.id,
                                        'bg-product-cell':
                                            category.label === 'CATEGORY_3'
                                            && attribute.value === item.id,
                                      },
                                    )}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: '.75rem',
                                      textTransform: 'none',
                                      minWidth: '107px',
                                    }}
                                    key={item.id}
                                    onClick={() => {
                                      // if ( attribute.label === 'Weight' ) return;
                                      const sizeValue = -1; // fields.value[1].value;
                                      // if ( attribute.label !== 'Weight' ) sizeValue = -1;
                                      this.calcAttribute(
                                        attribute.id,
                                        item.id,
                                        fields.value[index].value,
                                        index,
                                        values.formData,
                                        sizeValue,
                                      );
                                    }}
                                  >
                                    <IntlMessages id={item.text} />
                                    <span>{` (${item.val})`}</span>
                                  </Button>
                                ) )}
                              </ButtonGroup>
                            </HoverTitle>
                            {submitFailed
                              && errors.attributes
                              && errors.attributes[attribute.id]
                              && attributeIdError !== attribute.id ? (
                                <FormHelperText className="text-danger">
                                  <IntlMessages
                                    id={errors.attributes[attribute.id]}
                                  />
                                </FormHelperText>
                              ) : null}
                            {attributeIdError === attribute.id && (
                              <FormHelperText className="text-danger">
                                {attributeError}
                              </FormHelperText>
                            )}
                          </Grid>
                        );
                      } )
                      }
                    </FieldArray>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  lg={5}
                  className="d-flex align-items-center "
                  style={{ backgroundColor: '#eeeeee' }}
                >
                  <Box
                    id="chartdiv"
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: '460px',
                      minHeight: '300px',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                color="textPrimary"
                className="font-weight-bold mt-4 mb-3"
              >
                <IntlMessages id="newProductTitleProductionResults" />
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <HoverTitle id="productionResults.EstimatedTime.hover">
                    <TextField
                      containerClass="kotler-text-field-form-control"
                      className="kotler-text-field-gray"
                      style={{ background: '#EEEFEE' }}
                      allowZero
                      label="estimatedTimeToDevelopMonth"
                      field="formData.developTime"
                      disabled
                      inputProps={{ style: { textAlign: 'right' } }}
                      marginContainer={false}
                    />
                  </HoverTitle>
                </Grid>

                <Grid item xs={12} md={4} sm={6}>
                  <HoverTitle id="productionResults.EstimatedCost.hover">
                    <TextField
                      containerClass="kotler-text-field-form-control"
                      className="kotler-text-field-gray"
                      style={{ background: '#EEEFEE' }}
                      field="formData.developCost"
                      label="estimatedCostToDevelop"
                      disabled
                      inputProps={{ style: { textAlign: 'right' } }}
                      marginContainer={false}
                    />
                  </HoverTitle>
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <HoverTitle id="productionResults.EstimatedUnit.hover">
                    <TextField
                      containerClass="kotler-text-field-form-control"
                      className="kotler-text-field-gray"
                      style={{ background: '#EEEFEE' }}
                      label="estimatedUnitProductionCost"
                      field="formData.unitProductionCost"
                      disabled
                      inputProps={{ style: { textAlign: 'right' } }}
                      marginContainer={false}
                    />
                  </HoverTitle>
                </Grid>
              </Grid>

              <Grid
                container
                className="justify-content-center justify-content-md-end mt-4"
              >
                <Grid item>
                  <ButtonsNP
                    cancelTo={`/sessions/general/${this.backLabel}`}
                    hoverSave={{
                      id: 'approveProject.hover',
                      placement: 'left',
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
        <ConfirmationDialog
          open={confirmBudgetModal}
          title="PRODUCT_WITH_SAME_ATTRIBUTE_QUESTION"
          confirmBtnText="yesModalBtn"
          closeBtnText="btnNo"
          onSubmit={() => {
            this.toggleConfirmNewProductModal();
          }}
          onClose={() => {
            this.toggleConfirmNewProductModal();
            this.setConfirmBudgetModal();
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  initProductCreating: UserActions.initProductCreating,
  finishProductCreating: UserActions.finishProductCreating,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( NewProduct ) );
