import { Typography } from "@material-ui/core";
import IconCard from "components/custom_v2/IconCard";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IntlMessages from "utils/IntlMessages";
import NavigationOptions from "constants/NavigationOptions";

const StudentDashboardOptions = ({ user, activeStrategy, mkmode }) => {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if (user && user.activeSession) {
      setOptions(
        NavigationOptions({
          studio: (user.activeSession.distributionType === "studio"),
          activeStrategy: (mkmode && mkmode.decision && mkmode.decision.strategy) && activeStrategy,
        })
      );


    }
  }, [user, user.activeSession, activeStrategy, mkmode]);

  return options ? (
    <>
     
      
          <div className="information-cont">
            <Typography variant="h6" color="textSecondary" className="text-center icon-card-section-title">
              <IntlMessages id="informationDashboard" />
            </Typography>
          <ul className="list-icon-cards list-information">
          {options.information.map((option) => (
            <li  key={option.title}>
              <IconCard {...option} />
            </li>
          ))}
          </ul>
       
        </div>
      <div className="decision-cont" >
         
            <Typography variant="h6" color="textSecondary" className="text-center  icon-card-section-title" >
              <IntlMessages id="decisionDashboard" />
            </Typography>
       <ul className="list-icon-cards list-decision">
         {options.desicion.map((option) => (
                <li  key={option.title}>
                  <IconCard {...option} />
                </li>
              ))}
       </ul>
             
      </div>
    </>
  ) : null;
};
export default connect(({ user }) => ({
  user,
}))(StudentDashboardOptions);
