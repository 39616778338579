// rootSaga.js

import { all } from 'redux-saga/effects';
import lastRoundServicesRootSaga from './LastRoundServicesSaga';
import allRoundsServicesRootSaga from './AllRoundsServicesSaga';

function* servicesRootSaga() {
  yield all( [
    // Otras sagas de la aplicación
    lastRoundServicesRootSaga(),
    allRoundsServicesRootSaga(),
  ] );
}

export default servicesRootSaga;
