import React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'components/custom_v2/SideNav';
import SettingsActions from 'store/reducers/Settings';
import { Route } from 'react-router';
import _ from 'lodash';
import { Container } from '@material-ui/core';
import Chart from './Chart';
import Company from './Company';
import Products from './Products';
import Customer from './Customer';
import Market from './Market';
import PublicMarket from './PublicMarket';
import Research from './Research';
import Positioning from './Research/Positioning';
import Activity from './Activities';

const General = ( { navCollapsed, toggleCollapsedNav, match, user } ) => {
  let mkmode = {};
  if ( user.activeSession.distributionType === 'studio' ) {
    mkmode = { ...user.activeSession.studioFields };
  } else {
    mkmode = { ...user.activeSession.enterpriseFields };
  }
  let menus = [
    {
      icon: 'widgets',
      name: 'company',
      color: 'sepia',
      url: `${match.url}/company`,
    },
    {
      icon: 'view-module',
      name: 'products',
      color: 'primary',
      url: `${match.url}/products`,
    },
    {
      icon: 'bookmark-outline',
      name: 'market',
      color: 'info',
      url: `${match.url}/market`,
    },
    {
      icon: 'sort-amount-asc',
      name: 'research',
      color: 'lime',
      url: `${match.url}/research`,
    },
    {
      icon: 'bookmark-outline',
      name: 'customers',
      color: 'danger',
      url: `${match.url}/customer`,
    },
    {
      icon: 'sort-amount-asc',
      name: 'activities',
      color: 'danger',
      url: `${match.url}/activity`,
    },
  ];

  if ( mkmode && mkmode.information && !mkmode.information.products ) {
    menus = _.filter( menus, o => o.name.indexOf( 'products' ) === -1 );
  }

  if ( mkmode && mkmode.information && !mkmode.information.products ) {
    menus = _.filter( menus, o => o.name.indexOf( 'activities' ) === -1 );
  }

  return (
    <div>
      <Sidebar
        menus={menus}
        open={navCollapsed}
        onToggle={toggleCollapsedNav}
      />
      <Container className="mt-3" maxWidth="lg">
        <Route path={`${match.url}/chart`} component={Chart} />
        <Route path={`${match.url}/company`} component={Company} />
        <Route path={`${match.url}/products`} component={Products} />
        <Route path={`${match.url}/customer`} component={Customer} />
        <Route path={`${match.url}/market`} component={Market} />
        <Route path={`${match.url}/publicmarket`} component={PublicMarket} />
        <Route path={`${match.url}/positioning/:id`} component={Positioning} />
        <Route path={`${match.url}/research`} component={Research} />
        <Route path={`${match.url}/activity`} component={Activity} />
      </Container>
    </div>
  );
};

const mapStateToProps = ( { settings, user } ) => ( {
  navCollapsed: settings.navCollapsed,
  user,
} );

const mapDispatchToProps = { toggleCollapsedNav: SettingsActions.toggleCollapsedNav };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( General );
