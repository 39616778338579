import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Collapse, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import DashboardIcon from "assets/images/kotler/icons/icon-dashboard.png";
import { Link } from "react-router-dom";
import IntlMessages from "utils/IntlMessages";
import { connect } from "react-redux";
import NavigationOptions from "constants/NavigationOptions";
import FeatherIcon from "components/custom/FeatherIcon";

const NavigationMenuDropdown = ({ user, router }) => {
  const [open, setOpen] = useState(false);
  const [openDesicion, setOpenDesicion] = useState("information");
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (user && user.activeSession && !(user.roles.indexOf("ROLE_ADMIN") >= 0)) {
      setOptions(
        NavigationOptions({
          user,
          studio: user.activeSession.distributionType === "studio",
          activeStrategy: user.activeSession.withStrategies,
          professor: !!(user.roles.indexOf("ROLE_SUPERVISOR") >= 0),
          admin: !!(user.roles.indexOf("ROLE_ADMIN") >= 0)

        })
      );
    }
    else if(user  && !!(user.roles.indexOf("ROLE_ADMIN") >= 0)){
      setOptions(
        NavigationOptions({
          user,
 
          admin: !!(user.roles.indexOf("ROLE_ADMIN") >= 0)

        })
      );
    }
  }, [user, user.activeSession]);
  return (
    <>
      <Dropdown
        id="navigation-menu-dropdown"
        className="kotler-drop-down"
        isOpen={open}
        toggle={() => {
          setOpen(!open);
        }}
      >
        <DropdownToggle tag="span" data-toggle="dropdown">
          <IconButton className="square-icon-buttom icon-btn text-white">
            <img src={DashboardIcon} alt="dashboard" />
          </IconButton>
        </DropdownToggle>
        <DropdownMenu className="kotler-drop-down-menu" right>
          <div>
          {options && options.information && (<>
            <span
              className="jr-link dropdown-item text-muted d-flex justify-content-between"
              onClick={() => setOpenDesicion("information")}
            >
              <div className=" ">
                <IntlMessages id="informationDashboard" />
              </div>
              <FeatherIcon icon="chevronUp" className={`kotler-icon m-0 ${openDesicion === "information" ? "rotate" : ""}`} />
            </span>
            <Collapse isOpen={openDesicion === "information"}>
              {options &&
                options.information.map((props) => (
                  <MenuOption {...props} router={router} key={props.link} onCloseDropdown={() => setOpen(false)} />
                ))}
            </Collapse>
          </>)}
          
            {options && options.activity && (<>         <span
              className="jr-link dropdown-item text-muted d-flex justify-content-between"
              onClick={() => setOpenDesicion("activity")}
            >
              <div className=" ">
                <IntlMessages id="activityDashboard" />
              </div>
              <FeatherIcon icon="chevronUp" className={`kotler-icon m-0 ${openDesicion === "activity" ? "rotate" : ""}`} />
            </span>
              <Collapse isOpen={openDesicion === "activity"}>
                {options &&
                  options.activity.map((props) => (
                    <MenuOption {...props} router={router} key={props.link} onCloseDropdown={() => setOpen(false)} />
                  ))}
              </Collapse>
            </>)}
            {options && options.desicion && (<>
               <span
              className="jr-link dropdown-item text-muted d-flex justify-content-between"
              onClick={() => setOpenDesicion("desicion")}
            >
              <div className=" ">
                <IntlMessages id="decisionDashboard" />
              </div>
              <FeatherIcon icon="chevronUp" className={`kotler-icon m-0 ${openDesicion === "desicion" ? "rotate" : ""}`} />
            </span>
            <Collapse isOpen={openDesicion === "desicion"}>
              {options &&
                options.desicion.map((props) => (
                  <MenuOption {...props} router={router} key={props.link} onCloseDropdown={() => setOpen(false)} />
                ))}
            </Collapse>
            </>)}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const MenuOption = ({ icon, title, subtitle, link, router, onCloseDropdown }) => (
  <Link to={link} key={link} className="text-decoration-none">
    <span
      onClick={onCloseDropdown}
      className={` jr-link dropdown-item text-muted d-flex  justify-content-between  ${router.location.pathname && router.location.pathname.includes(link) ? "active-option" : ""
        }`}
    >
      <IntlMessages id={title} />
      <span className="dropdown-item-subtext ">{subtitle && <IntlMessages id={subtitle} />}</span>
    </span>
  </Link>
);

export default connect(({ user, router }) => ({ user, router }))(NavigationMenuDropdown);
